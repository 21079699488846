import React, { useEffect, useState } from 'react';
import { authHeader } from '../../helpers/auth-header';
import { getApplicationType, getSupplierID } from '../../helpers/general';

const MenuContext = React.createContext({
    navMenu: [],
    setNavMenu: () => { },
});

const MenuProvider = (props) => {
    let { children } = props;

    const [navMenu, setNavMenu] = useState([]);

    useEffect(() => {
        fetch(`./NavMenu/GetNavBar?supplierID=${getSupplierID()}`, {
            method: 'GET',
            headers: authHeader(false, true)
        })
            .then(response => response.json())
            .then(nav => {
                setNavMenu(nav);
            });

        return () => { setNavMenu({}); }
    }, []);

    return (
        <MenuContext.Provider
            value={{
                navMenu,
                setNavMenu,
            }}
        >
            {children}
        </MenuContext.Provider>
    );
};

export { MenuProvider };
export default MenuContext;