import React from 'react';
import { Card, CardBody, Modal, ModalBody, ModalHeader } from 'reactstrap';
import JsonToHtml from '../controls/JsonToHtml';

const $ = require('jquery');

const TermsAndConditions_Modal = props => {
    let { isOpen, toggle, termsURL, setAgreeTC, handleContinueFromTC } = props;

    const handleContinue = () => {
        let chkTC = document.getElementById('agreeTC_chk');
        if (chkTC) {
            setAgreeTC(chkTC.checked);
            if (chkTC.checked) {
                handleContinueFromTC()
                toggle();
            } else {
                let lblTC = document.getElementById('agreeTC_label');
                if (lblTC) {
                    lblTC.style.color = 'red';
                }
            }
        }
    }

    return (
        <Modal
            id='PaymentDetails_Modal'
            isOpen={isOpen}
            toggle={toggle}
            size="xl"
            centered
            backdrop="static"
            unmountOnClose
        >
            <ModalHeader toggle={toggle}></ModalHeader>
            <ModalBody>
                <Card>
                    <CardBody>
                        <JsonToHtml
                            url={termsURL}
                            jsonPropertyPath={'sell2uk'}
                        />
                        <hr />
                        <div className='u-component-spacing--2x' style={{ display: "flex", flexFlow: "column", justifyContent: "center", alignItems: "center" }}>
                            <div className="c-form__input-group" style={{ maxWidth: "100%" }}>
                                <div className="custom-control">
                                    <input
                                        id={`agreeTC_chk`}
                                        name={`agreeTC_chk`}
                                        type="checkbox"
                                        className="custom-control-input"
                                        defaultChecked={false} />

                                    <label id='agreeTC_label' htmlFor='agreeTC_chk' className='c-search-section__label c-checkbox-label'>I acknowledge that I have read, understand, and agree to the Terms and Conditions.</label>
                                </div>
                            </div>
                            <div className='u-component-spacing--2x' >
                                <button className="c-cta" type='button' onClick={() => handleContinue()}>Accept and Continue</button>
                            </div>
                        </div>
                    </CardBody>
                </Card>
            </ModalBody>
        </Modal>
    );
}

export default TermsAndConditions_Modal