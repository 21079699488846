//
//   In-tend Limited 2020 - general.js
//
//   General functions for the application
//

import { history } from "./history";
import { useLocation } from "react-router-dom";
import { authHeader } from "./auth-header";
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import Cookies from 'js-cookie';
import { jwtDecode } from "jwt-decode";

const $ = require('jquery');
const swal = withReactContent(Swal);

export function useQuery() {
    return new URLSearchParams(useLocation().search);
}

export async function getResourceString(string) {
    const response = await fetch(`./Sell2Master/GetResourceString?resourceId=${string}`, {
        method: 'GET',
        headers: authHeader(false, true)
    })

    const data = await response.text();

    try {
        return JSON.parse(data);
    } catch {
        return data;
    }
}

// Create a new cookie
export function setCookie(cookieName, value, expiry = 1) {
    Cookies.set(
        encodeURIComponent(btoa(cookieName)),
        encodeURIComponent(btoa(value)),
        { expires: expiry, secure: true }
    )
}

// Get the cookie details
export function getCookie(cookieName) {
    let cookie = Cookies.get(encodeURIComponent(btoa(cookieName)));
    if (cookie) {
        return atob(decodeURIComponent(cookie));
    }
    return null;
}

// Remove a cookie
export function removeCookie(cookieName) {
    Cookies.remove(encodeURIComponent(btoa(cookieName)));
}

// Get user ID's from cookie
export function getUser() {
    let cookie = getCookie(`token-${getVirtualDirectory()}`);
    if (cookie) {
        return jwtDecode(cookie);
    } else {
        return null;
    }
}

// Get supplier id from cookie
export function getSupplierID() {
    let cookie = getCookie(`token-${getVirtualDirectory()}`);
    if (cookie) {
        const { sid } = jwtDecode(cookie);
        return parseInt(sid);
    } else {
        return 0;
    }
}

// Get contact id from cookie
export function getContactID() {
    let cookie = getCookie(`token-${getVirtualDirectory()}`);
    if (cookie) {
        const { cid } = jwtDecode(cookie);
        return parseInt(cid);
    } else {
        return 0;
    }
}

export async function getAlertCode(code) {
    const response = await fetch(`./Sell2Master/GetAlertCode?code=${code}`, {
        method: 'GET',
        headers: authHeader(false, true)
    })
    const data = await response.json();
    return data;
}

export function isDisabled(action, parameters, options) {
    let disabled = false;

    if (action.disabled) {
        return true;
    }

    if (parameters && options.enabled) {
        // Check if we have ||
        if (options.enabled.includes('||')) {
            let disableParameters = options.enabled.split('||');
            for (let i = 0; i < disableParameters.length; i++) {
                disabled = !IsDisabledCheck(disableParameters[i], parameters) && !disabled;
            }
        }
        else if (options.enabled.includes('&&')) {
            let disableParameters = options.enabled.split('&&');
            for (let i = 0; i < disableParameters.length; i++) {
                if (i === 0) {
                    disabled = IsDisabledCheck(disableParameters[i], parameters)
                } else {
                    disabled = IsDisabledCheck(disableParameters[i], parameters) || disabled;
                }
            }
        }
        else {
            disabled = IsDisabledCheck(options.enabled, parameters)
        }
    } else if (parameters && action.disableIfEmpty) {
        for (let i = 0; i < parameters.length; i++) {
            let value = sessionStorage.getItem(parameters[i].title);
            if (value) {
                disabled = value === -1 || value === "-1";
            } else {
                return true;
            }
        }
    }

    return disabled;
}

function IsDisabledCheck(disabledParameter, parameters) {
    let disabled = false;

    if (disabledParameter.includes('!=')) {
        let field = disabledParameter.split('!=')[0];
        let value = disabledParameter.split('!=')[1];

        if (field && value) {
            for (let i = 0; i < parameters.length; i++) {
                let storedValue = sessionStorage.getItem(parameters[i].title);
                if (storedValue && field === parameters[i].title) {
                    disabled = value === storedValue;
                }
            }
        }
    }
    else if (disabledParameter.includes('=')) {
        let field = disabledParameter.split('=')[0];
        let value = disabledParameter.split('=')[1];

        if (field && value) {
            for (let i = 0; i < parameters.length; i++) {
                let storedValue = sessionStorage.getItem(parameters[i].title);
                if (storedValue && field === parameters[i].title) {
                    disabled = value !== storedValue;
                }
            }
        }
    }
    else if (disabledParameter.includes('<')) {
        let field = disabledParameter.split('<')[0];
        let value = disabledParameter.split('<')[1];

        if (field && value) {
            for (let i = 0; i < parameters.length; i++) {
                let storedValue = sessionStorage.getItem(parameters[i].title);
                if (storedValue && field === parameters[i].title) {
                    disabled = value < storedValue;
                }
            }
        }
    }
    else if (disabledParameter.includes('>')) {
        let field = disabledParameter.split('>')[0];
        let value = disabledParameter.split('>')[1];

        if (field && value) {
            for (let i = 0; i < parameters.length; i++) {
                let storedValue = sessionStorage.getItem(parameters[i].title);
                if (storedValue && field === parameters[i].title) {
                    disabled = value > storedValue;
                }
            }
        }
    }

    return disabled;
}

export function createActionURL(action, parameters) {
    if (!action.popup) {
        let url = action.url;

        for (let i = 0; i < parameters.length; i++) {
            let value = sessionStorage.getItem(parameters[i].title);

            if (value) {
                url = url.replace(`[${parameters[i].title}]`, value);
            }

            sessionStorage.removeItem(parameters[i].title);
        }

        return goTo(url);
    }
}

export function goTo(url) {
    history.push(`.${url}`);
}

export async function getFilters(filters, showAll) {
    if (filters === undefined || filters === null) {
        return [];
    }

    const filterList = await filters.map(async (filter) => ({
        label: await getResourceString(filter.label),
        value: filter.value
    }));

    let results = await Promise.all(filterList);

    if (showAll) {
        results.unshift({ label: "Show All", value: "-1" });
    }

    return results;
}

// fieldFormat must be specified as 'UniqueID=[VALUE]' etc.
// or 'Unique IN (SELECT UserID FROM Groups WHERE UniqueID = [VALUE])'
export async function getTableFilters(table, fields, where, order, supplierID, showAll, fieldFormat) {
    let obFilters = [];

    if (showAll) {
        obFilters.push({ label: "Show All", value: "-1" });
    }

    let fieldList = await getLookupData(table, fields, where, order, supplierID);

    fieldList.map(field => {
        obFilters.push({ label: field.label, value: fieldFormat.replace('[VALUE]', field.value) });
        return true;
    });

    return obFilters;
}

export async function getSearchFilters(filters) {
    let obFilters = [];

    if (filters) {
        await Promise.all(
            filters.map((filter, i) => {
                let str = filter.label;
                if (str[0] === '$') {
                    return getResourceString(str).then(data => {
                        obFilters.push({ label: data, value: filter.value });
                    });
                } else {
                    obFilters.push({ label: str, value: filter.value });
                    return true;
                }
            })
        );
    }

    return obFilters;
}

export function getSearchCommand(searchOption, searchText) {
    let searchCommand = "";

    switch (parseInt(searchOption)) {
        case 1:
            searchCommand = `LIKE N'%${searchText}%'`;
            break;

        case 2:
            searchCommand = `NOT LIKE N'%${searchText}%'`;
            break;

        case 3:
            searchCommand = `LIKE N'${searchText}%'`;
            break;

        case 4:
            searchCommand = `LIKE N'%${searchText}'`;
            break;

        case 5:
            searchCommand = `>= '${searchText}'`;
            break;

        case 6:
            searchCommand = `<= '${searchText}'`;
            break;

        default:
            break;
    }

    return searchCommand;
}

export function getFinalSearchString(where, string) {
    let final = "";
    if (where && string) {
        final = where.replace('$find$', string);
    }
    return final;
}

export function getControl(ID) {
    let table = ID.split('.')[0];
    let field = ID.split('.')[1];

    return fetch(`./Master/GetDBField?table=${table}&field=${field}`, {
        method: 'GET',
        headers: authHeader(false, true)
    })
        .then(response => response.json())
        .then(data => {
            try {
                return JSON.parse(data);
            } catch {
                return data;
            }
        })
        .catch(error => {
            console.log(error);
        })
}

export function isEnabled(restrictions, supplierID) {
    return fetch(`./Sell2Master/IsControlAllowed?restrictions=${restrictions}&supplierID=${supplierID}`, {
        method: 'GET',
        headers: authHeader(false, true)
    })
        .then(response => response.json())
        .then(data => {
            return data;
        })
        .catch(error => {
            console.log(error);
        })
}

export function getControlDefault(ID) {
    let table = ID.split('.')[0];
    let field = ID.split('.')[1];

    return fetch(`./Sell2Master/GetFieldDefault?table=${table}&field=${field}`, {
        method: 'GET',
        headers: authHeader(false, true)
    })
        .then(response => response.json())
        .then(data => {
            try {
                return JSON.parse(data);
            } catch {
                return data;
            }
        })
        .catch(error => {
            console.log(error);
        })
}

export async function getLookupData(table, fields, where, order, supplierID, showEmpty, emptyText) {
    let url = `./Data/GetLookupData?table=${table}&fields=${fields}`;

    if (supplierID) {
        url += `&supplierID=${supplierID}`
    }

    if (where) {
        url += `&where=${where}`;
    }

    if (order) {
        url += `&order=${order}`;
    }

    if (showEmpty) {
        url += `&emptyText=${emptyText}`;
    }

    let response = await fetch(url, {
        method: 'GET',
        headers: authHeader(false, true)
    });

    let data = await response.json();

    return data.map(obj => {
        return {
            label: obj.value,
            value: obj.key
        }
    });
}

export async function getTableLookupData(table, lookup, keyField, primaryKey, fields, supplierID, showEmpty, emptyText) {
    let url = `./Data/GetTableLookupData?table=${table}&lookup=${lookup}&keyfield=${keyField}&primaryKey=${primaryKey}&fields=${fields}`;

    if (supplierID) {
        url += `&supplierID=${supplierID}`
    }

    if (showEmpty) {
        url += `&emptyText=${emptyText}`;
    }

    let response = await fetch(url, {
        method: 'GET',
        headers: authHeader(false, true)
    });

    let data = await response.json();

    return data.map(obj => {
        return {
            label: obj.value,
            value: obj.key
        }
    });
}

export async function getUseAsDefault(table, where) {
    let url = `./Data/GetUseAsDefault?table=${table}`;

    if (where) {
        url += `&where=${where}`;
    }

    let response = await fetch(url, {
        method: 'GET',
        headers: authHeader(false, true)
    });

    let data = await response.json();

    return data;
}

export function handleConfirm(msg, fnSuccess, fnFailure, title = "Are you sure?", width = "32em", confirmButtonText = "Ok", cancelButtonText = "Cancel") {
    if (!fnFailure) fnFailure = function () {
        // TODO: Handle Cancel
    };

    swal.fire({
        title: title,
        width: width,
        html: msg,
        icon: "warning",
        allowOutsideClick: false,
        allowEscapeKey: false,
        showConfirmButton: true,
        showCancelButton: true,
        confirmButtonText: confirmButtonText,
        cancelButtonText: cancelButtonText,
        focusCancel: true,
        buttonsStyling: false,
        customClass: {
            confirmButton: 'btn btn-success m-1',
            cancelButton: 'btn btn-danger m-1',
        }
    })
        .then(result => {
            if (result.isConfirmed) {
                fnSuccess();
            } else {
                fnFailure();
            }
        });
}

export async function showError(code, replace, callback) {
    if (replace === undefined || replace === null) {
        replace = '';
    }

    if (!callback) callback = function () {
        // TODO: Handle Callback
    };

    //if (!isNaN(code)) {
    //    code = 'IOL' + code;
    //}

    getAlertCode(code).then(data => {
        let message = data.message.replace('[REPLACE]', replace);

        swal.fire({
            title: data.severity,
            html: message,
            icon: data.severity.toLowerCase(),
            allowOutsideClick: false,
            allowEscapeKey: false,
            buttonsStyling: false,
            customClass: {
                confirmButton: 'btn btn-primary m-1'
            }
        })
            .then(() => {
                callback();
                return true;
            });
    });
}

export function showCustomError(message, title, severity, width = 500) {
    swal.fire({
        title: title,
        html: message,
        icon: severity.toLowerCase(),
        allowOutsideClick: false,
        allowEscapeKey: false,
        buttonsStyling: false,
        width: width,
        customClass: {
            confirmButton: 'btn btn-primary m-1'
        }
    });
}

export function handleSuccess(title, message, icon, width = "32em") {
    Swal.fire({
        title: title,
        html: message,
        icon: icon,
        width: width
    });
}

export function handleTimedSuccess(severity, title, message, timer = 2000, fnSuccess) {
    if (!fnSuccess) {
        fnSuccess = function () { };
    }

    swal.fire({
        title: title,
        html: message,
        icon: severity.toLowerCase(),
        allowOutsideClick: false,
        allowEscapeKey: false,
        buttonsStyling: false,
        showConfirmButton: false,
        timer: timer
    })
        .then(result => {
            if (result.dismiss === 'timer') {
                fnSuccess();
            }
            return false;
        });
}

export async function handleAlertWithInput(title, inputLabel, errorMessage, prefillText = '') {
    const { value: searchTitle } = await swal.fire({
        title: title,
        input: 'text',
        inputValue: prefillText,
        inputLabel: inputLabel,
        allowOutsideClick: false,
        allowEscapeKey: false,
        buttonsStyling: false,
        showCancelButton: true,
        confirmButtonText: 'Save',
        inputValidator: (value) => {
            if (!value) {
                return errorMessage
            }
        },
        customClass: {
            confirmButton: 'btn btn-success m-2',
            cancelButton: 'btn btn-danger m-2',
        }
    });

    return searchTitle;
}

export async function registerSupplierWithPortal(portalID, supplierID, application, skipDuplicateContacts) {
    const response = await fetch(`./Organisations/RegisterWithPortal?portalid=${portalID}&supplierid=${supplierID}&sell2System=${application}&skipDuplicateContacts=${skipDuplicateContacts}`, {
        method: 'GET',
        headers: authHeader(false, false)
    });

    let data = response.json();
    return data;
}

export async function updateSinglePortalContacts(supplierID, contactID, portalID) {
    const response = await fetch(`./Organisations/UpdateSinglePortal?supplierid=${supplierID}&contactID=${contactID}&organiserID=${portalID}`, {
        method: 'GET',
        headers: authHeader()
    });

    let data = response.json();
    return data;
}

export async function updateContactPortals(supplierID, contactID) {
    const application = await getApplicationType();
    const response = await fetch(`./Organisations/UpdateUserPortals?supplierid=${supplierID}&contactID=${contactID}&sell2System=${application}`, {
        method: 'GET',
        headers: authHeader(false, false)
    });

    let data = response.json();
    return data;
}

export async function updateSupplierSinglePortalDetails(supplierID, portalID) {
    const response = await fetch(`./Organisations/UpdatePortalAccount?supplierid=${supplierID}&organiserID=${portalID}&skipDuplicateContacts=true`, {
        method: 'GET',
        headers: authHeader()
    });

    let data = response.json();
    return data;
}

export async function updateSupplierPortalDetails(supplierID) {
    const application = await getApplicationType();
    const response = await fetch(`./Organisations/UpdatePortalAccounts?supplierid=${supplierID}&sell2System=${application}`, {
        method: 'GET',
        headers: authHeader(false, false)
    });

    let data = response.json();
    return data;
}

export async function updateContactSinglePortalDetails(contactID, portalID) {
    const response = await fetch(`./Organisations/UpdatePortalContact?contactID=${contactID}&organiserID=${portalID}`, {
        method: 'GET',
        headers: authHeader(false, false)
    });

    let data = response.json();
    return data;
}

export async function updateContactPortalDetails(contactID) {
    const application = await getApplicationType();
    const response = await fetch(`./Organisations/UpdatePortalContacts?contactID=${contactID}&sell2System=${application}`, {
        method: 'GET',
        headers: authHeader(false, false)
    });

    let data = response.json();
    return data;
}

export async function checkClassificationCodes(supplierID) {
    let response = await fetch(`./Suppliers/MandatoryBusinessClassificationsCheck?supplierID=${supplierID}`, {
        method: 'GET',
        headers: authHeader()
    })

    let data = await response.json();
    return data;

}

export async function checkCompanyClassifications(supplierID) {
    let response = await fetch(`./Suppliers/MandatoryCompanyClassificationsCheck?supplierID=${supplierID}`, {
        method: 'GET',
        headers: authHeader()
    })

    let data = await response.json();
    return data;

}

export async function deleteSupplierUser(supplierID, contactID, email, currentContactID) {
    let obRemoveData = { supplierID: supplierID, removeContactID: contactID, removeEmail: email, currentContactID: currentContactID };
    const response = await fetch(`./SupplierUsers/DeleteUser`, {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify(obRemoveData)
    });

    let data = response.json();
    return data;
}

export async function registerOnPortal(portalID, portalName, supplierID, userID, application) {
    return checkClassificationCodes(supplierID)
        .then(classificationCodeResponse => {
            if (classificationCodeResponse === true) {
                return checkCompanyClassifications(supplierID)
                    .then(companyClassificationResponse => {
                        if (companyClassificationResponse === true) {
                            swal.fire({
                                title: 'Registering your account',
                                html: 'Please wait while your account is being registered on <strong>' + portalName + '</strong>.',
                                allowOutsideClick: false,
                                didOpen: () => {
                                    Swal.showLoading();
                                    return registerSupplierWithPortal(portalID, supplierID, application, false)
                                        .then(result => {
                                            Swal.close();

                                            if (result.error && result.error.errorCode === 0) {
                                                return new Promise((resolve) => {
                                                    handleTimedSuccess('success', 'Registered', `You've successfully registered on the '${portalName}' portal.`, 3000, () => {
                                                        sessionStorage.setItem('registeredOnPortal', portalID);
                                                        window.dispatchEvent(new Event('registeredOnPortal'));
                                                    });
                                                    resolve(true)
                                                });
                                            }
                                            else if (result.error && result.error.errorResponse && result.error.errorCode > 0 && result.error.errorCode < 6) {
                                                return handleLoginToPortalToLink(
                                                    `A supplier record already exists on ${portalName}`,
                                                    `${result.error.errorResponse}
                                                    <br /><br />
                                                    To sync your Sell2 and Portal supplier record, please enter your <span class="u-no-wrap"><strong>${portalName}</strong></span> portal email address and password to verify the account.<br /><br />This is required for verification, allowing Sell2 to sync and merge your Sell2 and Portal records together.<br /> <br /> <br /><i>If you would like any assistance, please contact Sell2 Support on  <span class="u-no-wrap"><strong>+44 114 407 0056</strong></span> or email <span class="u-no-wrap"><strong>support@in-tend.co.uk</strong></span></i>`, portalID, portalName, supplierID, userID, application)
                                                    .then(result => {
                                                        return new Promise((resolve) => { resolve(result) });
                                                    })
                                            }
                                            else if (result.error && result.error.errorCode === 6) {
                                                handleConfirm(
                                                    `${result.error.errorResponse}<br /><br />Would you like to register with this portal anyway, and skip the duplicate users?`,
                                                    function () {
                                                        // TODO: #### This is a duplicate block of code to the surrounding.  Look at reducing at a later date.
                                                        swal.fire({
                                                            title: 'Registering',
                                                            html: 'Registering on ' + portalName + '.',
                                                            allowOutsideClick: false,
                                                            didOpen: () => {
                                                                Swal.showLoading();
                                                                return registerSupplierWithPortal(portalID, supplierID, application, true)
                                                                    .then(result => {
                                                                        Swal.close();

                                                                        if (result.error && result.error.errorCode === 0) {
                                                                            return new Promise((resolve) => {
                                                                                handleTimedSuccess('success', 'Registered', `You've successfully registered on the '${portalName}' portal.`, 3000,
                                                                                    () => {
                                                                                        sessionStorage.setItem('registeredOnPortal', portalID);
                                                                                        window.dispatchEvent(new Event('registeredOnPortal'));
                                                                                    })
                                                                                resolve(true)
                                                                            });
                                                                        }
                                                                        else if (result.error && result.error.errorResponse && result.error.errorCode > 0 && result.error.errorCode < 6) {
                                                                            return handleLoginToPortalToLink(
                                                                                `An account already exists on ${portalName}`,
                                                                                `${result.error.errorResponse}
                                                                                <br /><br />
                                                                                <p>To sync your Sell2 and Portal supplier record, please enter your</p><strong>${portalName}</strong> portal email address and password to verify the account.<br /><br />This is required for verification, allowing Sell2 to sync and merge your Sell2 and Portal records together.<br /> <br /> <br /><i>If you would like any assistance, please contact Sell2 Support on  +44 114 407 0056 or email support@in-tend.co.uk</i>`,
                                                                                portalID, portalName, supplierID, userID, application)
                                                                                .then(result => {
                                                                                    return new Promise((resolve) => { resolve(result) });
                                                                                })
                                                                        }
                                                                        else if (result.error && result.error.errorResponse && result.error.errorResponse !== '') {
                                                                            showCustomError(`A problem occurred while registering you on ${portalName} portal. <br /> <br /> ${result.error.errorResponse}`, 'Problem Registering', 'error')
                                                                            return new Promise((resolve) => { resolve(false) });
                                                                        }
                                                                    });
                                                            }
                                                        });
                                                        // #### Duplicate ends here
                                                    },
                                                    function () {
                                                        return null;
                                                    },
                                                    'Duplicate contacts',
                                                    '55em'
                                                );
                                            }
                                            else if (result.error && result.error.errorResponse && result.error.errorResponse !== '') {
                                                showCustomError(`A problem occurred while registering you on ${portalName} portal. <br /> <br /> ${result.error.errorResponse}`, 'Problem Registering', 'error')
                                                return new Promise((resolve) => { resolve(false) });
                                            }
                                            else {
                                                showCustomError(`A problem occurred while registering you on ${portalName} portal.  Please try again - if the problem persists, please contact the support team.`, 'Problem Registering', 'error')
                                                return new Promise((resolve) => { resolve(false) });
                                            }
                                        })
                                }
                            })
                        }
                        else {
                            handleNoBusinessClassifications('You have no Company Classifications', 'You are required to select all relevant company classifications to register on the portals.')
                                .then(response => {
                                    if (response === true) {
                                        sessionStorage.setItem('CompanyClassifications', true);
                                        history.push('/my-company');
                                    }
                                })
                        }
                    })
            }
            else {
                fetch(`./BusinessClassifications/GetMandatoryBusinessClassificationTypes`, {
                    method: 'GET',
                    headers: authHeader()
                })
                    .then(response => response.json())
                    .then(result => {
                        let classifications = result.map(x => x.type).join(', ');
                        handleNoBusinessClassifications('You have no Classification Codes', `You are required to select all relevant ${classifications} codes - within your Account page - to register on the portals.`)
                            .then(response => {
                                if (response === true) {
                                    sessionStorage.setItem('Classifications', true);
                                    history.push('/my-company');
                                }
                            })
                    })
            }
        });
}

export async function StartFreePaymentProcess(obData, supplierID, contactID, registrationID, upgradePackageID) {
    return (
        swal.fire({
            title: 'Processing Account',
            html: 'We are setting up your account, please wait.',
            allowOutsideClick: false,
            didOpen: () => {
                Swal.showLoading();
                return MakingFreePayment(obData, supplierID, contactID, registrationID, upgradePackageID)
                    .then(result => {
                        Swal.close();

                        if (result.success === true && result.objData) {
                            console.log(result)
                                history.push({
                                    pathname: '/payment-successful', state: { invoiceNo: result.objData }
                                });
                        } else {
                            history.push({
                                pathname: '/payment-failed', state: { invoiceNo: result.objData, errorMessage: result.objData.errorMessage }
                            });
                        }
                    })
            }
        })
    )
}

export async function StartOgoneProcess(obData, supplierID, contactID, registrationID, upgradePackageID) {
    return (
        swal.fire({
            title: 'Processing Payment',
            html: 'We are processing your payment, please wait.',
            allowOutsideClick: false,
            didOpen: () => {
                Swal.showLoading();
                return MakingOgonePayment(obData, supplierID, contactID, registrationID, upgradePackageID)
                    .then(result => {
                        Swal.close();

                        if (result.success === true && result.objData) {
                            if (result.objData.htmlAnswer.length > 0) {
                                history.push({
                                    pathname: '/merchant-authorisation', state: { paymentForm: result.objData.htmlAnswer }
                                });
                            } else {
                                history.push({
                                    pathname: '/payment-successful', state: { invoiceNo: result.objData.sell2PaymentID }
                                });
                            }
                        } else {
                            history.push({
                                pathname: '/payment-failed', state: { invoiceNo: result.objData.sell2PaymentID, errorMessage: result.objData.errorMessage }
                            });
                        }
                    })
            }
        })
    )
}

export async function MakingFreePayment(obData, supplierID, contactID, registrationID, upgradePackageID) {
    let application = await getApplicationType();
    let response = await fetch(`./Payment/MakeFreePayment?supplierID=${supplierID}&contactID=${contactID}&applicationName=${application}&registrationID=${registrationID}&upgradePackageID=${upgradePackageID}`, {
        method: 'POST',
        headers: authHeader(false, true),
        body: JSON.stringify(obData)
    });

    return response.json();
}

export async function MakingOgonePayment(obData, supplierID, contactID, registrationID, upgradePackageID) {
    let application = await getApplicationType();
    let response = await fetch(`./Payment/MakePayment?supplierID=${supplierID}&contactID=${contactID}&applicationName=${application}&registrationID=${registrationID}&upgradePackageID=${upgradePackageID}`, {
        method: 'POST',
        headers: authHeader(false, true),
        body: JSON.stringify(obData)
    });

    return response.json();
}

async function linkSell2AccountWithPortalAccount(formValues, portalID, supplierID, userID, application, portalName) {

    // Modal while API is finding account
    await Swal.fire({
        title: 'Linking Accounts',
        html: 'Attempting to Link your accounts.',
        allowOutsideClick: false,
        didOpen: () => {
            Swal.showLoading()
            handleLinkExistingAccount(formValues.input1, formValues.input2, portalID, supplierID, application)
                .then(data => {

                    // Error Modal or Linking Modal
                    if (data.error.errorCode !== 6 && (data.error.errorResponse && data.error.errorResponse !== '')) {
                        Swal.close();
                        showCustomError(data.error.errorResponse.replaceAll('[[SystemName]]', portalName), `Problem when registering with ${portalName}`, 'warning', '750')
                        return false;
                    }
                    else {
                        data.sell2SupplierMatrix.sell2SupplierID = supplierID;

                        fetch(`./Organisations/UpdateSell2IntendLink?organiserID=${portalID}`, {
                            method: 'POST',
                            headers: authHeader(false, false),
                            body: JSON.stringify(data.sell2SupplierMatrix)
                        })
                            .then(response => {
                                if (data.error.errorCode === 6) {
                                    Swal.close();
                                    handleSuccess('Account Linked', `<p style='margin-bottom:1em'>Your account has been successfully linked.</p><p>${data.error.errorResponse}</p>`, 'warning', '45em');
                                    sessionStorage.setItem('registeredOnPortal', portalID);
                                    window.dispatchEvent(new Event('registeredOnPortal'));
                                } else {

                                    updateSupplierSinglePortalDetails(supplierID, portalID)
                                        .then(() => {
                                            updateContactSinglePortalDetails(userID, portalID)
                                                .then(() => {
                                                    Swal.close();
                                                    handleTimedSuccess('success', 'Linked', `Your company has been linked with your existing account on ${portalName} portal.`, 3000,
                                                        () => {
                                                            sessionStorage.setItem('registeredOnPortal', portalID);
                                                            window.dispatchEvent(new Event('registeredOnPortal'));
                                                        });
                                                });
                                        });
                                }
                            })
                        return true;
                    }
                })
        }
    });
}

export async function handleLoginToPortalToLink(title, msg, portalID, portalName, supplierID, userID, application) {
    // Portal Login modal
    const { value: formValues } = await Swal.fire({
        title: title,
        html:
        msg +
        '<br />' +
        '<form><div class="c-floating-label u-component-spacing u-component-spacing--2x">' +
        '   <input name="portalEmail" id="portalEmail" type="email" class="c-floating-label__input form-control" placeholder=" " autocomplete="off">' +
        '   <label for="portalEmail" class="c-floating-label__label" style="text-align: left">Portal Email</label>' +
        '</div>' +
        '<div class="c-floating-label u-component-spacing">' +
        '   <input name="portalPassword" id="portalPassword" type="password" class="c-floating-label__input form-control" placeholder=" " autocomplete="off">' +
        '   <label for="portalPassword" class="c-floating-label__label" style="text-align: left">Portal Password</label>' +
        '   <div class="invalid-feedback">' +
        '       <span id="password_LABEL"></span>' +
        '   </div>' +
        '</div></form>',
        width: '60em',
        allowOutsideClick: false,
        focusConfirm: false,
        allowEscapeKey: false,
        buttonsStyling: false,
        showCancelButton: true,
        confirmButtonText: 'Confirm Portal Credentials',
        customClass: {
            confirmButton: 'btn btn-success m-2',
            cancelButton: 'btn btn-danger m-2',
        },
        preConfirm: () => {
            return {
                'input1': document.getElementById('portalEmail').value,
                'input2': document.getElementById('portalPassword').value
            }
        }
    })

    if (formValues) {
        handleConfirm(
            `You are about to sync your Sell2 details with the supplier record on '<strong>${portalName}</strong>'.<br /><br />All supplier details on ${portalName} will be updated to match your Sell2 details.<br /> <br />Do you want to continue?<br /> <br /><i>If you would like any assistance, please contact Sell2 Support on +44 114 407 0056 or email support@in-tend.co.uk</i>`,
            function () {
                return linkSell2AccountWithPortalAccount(formValues, portalID, supplierID, userID, application, portalName);
            },
            function () {
                return false;
            },
            "You are about to merge your details",
            "55em",
            "YES - Merge and update my portal details",
            "NO - Cancel the sync and merge"
        );
    }
}


export async function handleLinkExistingAccount(email, password, portalID, supplierID, application) {
    let details = {
        email: email,
        password: password,
        organiserID: portalID,
        ignoreLockedAccount: true
    }

    const response = await fetch(`./Organisations/LinkExisingAccount?supplierID=${supplierID}&sell2System=${application}`, {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify(details)
    })

    return response.json();
}

export async function handleUpdateSupplierPortalDetails(supplierID, portalName) {
    let data = '';

    await swal.fire({
        title: 'Updating Portals',
        html: 'Updating your linked Portal accounts.',
        allowOutsideClick: false,
        didOpen: () => {
            Swal.showLoading()
            updateSupplierPortalDetails(supplierID)
                .then(obValue => {
                    data = obValue;
                    Swal.close();
                })
        }
    });

    if (data.errorResponse && data.errorResponse !== '') {
        showCustomError(data.errorResponse, `Error Registering with ${portalName}`, 'error')
    }
    else {
        handleTimedSuccess('success', 'Saved', 'Your company details have been saved.')
    }

    return data;
}

export async function handleDeleteSupplierUser(supplierID, contactID, email, currentContactID) {
    let data = '';
    await swal.fire({
        title: 'Removing User',
        html: 'Please Wait - Removing user from all linked portals, this may take a while.',
        allowOutsideClick: false,
        didOpen: () => {
            Swal.showLoading()
            deleteSupplierUser(supplierID, contactID, email, currentContactID)
                .then(obValue => {
                    data = obValue;
                    Swal.close();
                })
        }
    });

    if (data.success) {
        handleTimedSuccess('success', 'Contact Removed', `The contact <b>${email}</b> has been removed from Sell2 and all linked portals.`);
    }
    else {
        showCustomError('There was an error while removing the user.', `Error deleting user`, 'error')
    }

    return data;
}

export async function handleUpdateContactPortalDetails(contactID, portalName) {
    let data = '';

    await swal.fire({
        title: 'Updating Portals',
        html: 'Updating your personal details on all linked portals.',
        allowOutsideClick: false,
        didOpen: () => {
            Swal.showLoading()
            updateContactPortalDetails(contactID)
                .then(obValue => {
                    data = obValue;
                    Swal.close();
                })
        }
    });

    if (data.errorResponse && data.errorResponse !== '') {
        showCustomError(data.errorResponse, `Error Registering with ${portalName}`, 'error')
    }
    else {
        handleTimedSuccess('success', 'Saved', 'Your company details have been saved.')
    }

    return data;
}

export async function handleForgottenPassword(severity, title, message) {
    const { value: searchTitle } = await swal.fire({
        title: 'Reset Password',
        input: 'text',
        inputLabel: 'Email:',
        allowOutsideClick: false,
        allowEscapeKey: false,
        buttonsStyling: false,
        showCancelButton: true,
        confirmButtonText: 'Send password reset email',
        inputValidator: (value) => {
            if (!value) {
                return 'You must enter an email address.'
            }
        },
        customClass: {
            confirmButton: 'btn btn-success m-2',
            cancelButton: 'btn btn-danger m-2',
        }
    });

    return searchTitle;
}

export async function handleNoBusinessClassifications(title, msg) {
    let response = false;

    await Swal.fire({
        title: title,
        text: msg,
        icon: 'warning',
        width: 600,
        allowOutsideClick: false,
        allowEscapeKey: false,
        buttonsStyling: false,
        showCancelButton: true,
        confirmButtonText: 'Go to Company Account',
        reverseButtons: true,
        customClass: {
            confirmButton: 'btn btn-success m-2',
            cancelButton: 'btn btn-danger m-2',
        }
    }).then((result) => {
        response = result.isConfirmed;
    });

    return response;
}



export function getStringList(array) {
    return array.join(", ");
}

export async function storeWizardData(table, vData, supplierID) {
    const response = await fetch(`./Data/StoreWizardData?table=${table}&supplierID=${supplierID}`, {
        method: 'POST',
        headers: authHeader(false, true),
        body: JSON.stringify(vData)
    });

    const data = await response.json();

    return data;
}

export async function overwriteWizardData(table, vData, supplierID) {
    const response = await fetch(`./Data/StoreWizardData?table=${table}&supplierID=${supplierID}&overwrite=true`, {
        method: 'POST',
        headers: authHeader(false, true),
        body: JSON.stringify(vData)
    });

    const data = await response.json();

    return data;
}

export async function editWizardData(table, vData, uniqueId, supplierID) {
    const response = await fetch(`./Data/EditWizardData?table=${table}&uniqueId=${uniqueId}&supplierID=${supplierID}`, {
        method: 'POST',
        headers: authHeader(false, true),
        body: JSON.stringify(vData)
    });

    const data = await response.json();

    return data;
}

export async function editWizardDataValue(table, field, value, uniqueId, supplierID) {
    const response = await fetch(`./Data/EditWizardDataValue?table=${table}&uniqueId=${uniqueId}&supplierID=${supplierID}&field=${field}&value=${value}`, {
        method: 'GET',
        headers: authHeader(false, true)
    });

    const data = await response.json();

    return data;
}

export async function removeWizardData(table, uniqueId, supplierID) {
    const response = await fetch(`./Data/RemoveWizardData?table=${table}&uniqueId=${uniqueId}&supplierID=${supplierID}`, {
        method: 'GET',
        headers: authHeader(false, true)
    });

    const data = await response.json();

    return data;
}

export async function clearWizardData(table, supplierID) {
    const response = await fetch(`./Data/ClearWizardData?table=${table}&supplierID=${supplierID}`, {
        method: 'GET',
        headers: authHeader(false, true)
    });

    const data = await response.json();

    return data;
}

export async function copyWizardData(table1, table2, supplierID) {
    const response = await fetch(`./Data/CopyWizardData?source=${table1}&copy=${table2}&supplierID=${supplierID}`, {
        method: 'GET',
        headers: authHeader(false, true)
    });

    const data = await response.json();

    return data;
}

export async function removeCheckListItem(table, uniqueId, supplierID) {
    const response = await fetch(`./Data/RemoveCheckListItem?table=${table}&uniqueId=${uniqueId}&supplierID=${supplierID}`, {
        method: 'GET',
        headers: authHeader(false, true)
    });

    const data = await response.json();

    return data;
}

export async function moveWizardData(table, uniqueId, supplierID, moveUp) {
    const response = await fetch(`./Data/MoveWizardData?table=${table}&uniqueId=${uniqueId}&supplierID=${supplierID}&moveUp=${moveUp}`, {
        method: 'GET',
        headers: authHeader(false, true)
    });

    const data = await response.json();

    return data;
}

export function calculatePassScorePoints() {
    let dblScore = parseFloat($(document.getElementById("Products.PassScore")).val());
    let dblPoints = parseFloat($(document.getElementById("Products.EvaluationTotalPoints")).val());

    if (dblScore > 0 && dblPoints > 0) {
        $(document.getElementById("Products.PassScore.PostSuffix.TextBox")).val(((dblPoints / 100) * dblScore).toFixed(2));
    } else {
        $(document.getElementById("Products.PassScore.PostSuffix.TextBox")).val('');
    }
}

export function getWebSetting(vArray, vKey) {
    if (vArray.length > 0) {
        let obj = vArray.find(x => x.webSetting1 === vKey);
        if (obj) {
            return obj.settingValue;
        }
    }
    return null;
}

export async function hideLotRows(productId, supplierID) {
    if (productId === null) {
        productId = -1;
    }

    let vDivisionIntoLots = JSON.parse(sessionStorage.getItem('ITFramework_ControlValues')).find(x => x.field === "Projects.DivisionIntoLots").value;
    let vLotsEnabled = parseInt(vDivisionIntoLots) !== 0;

    const response = await fetch(`./ProductLots/HideLots?productId=${productId}&supplierID=${supplierID}&divisionIntoLots=${vLotsEnabled}`, {
        method: 'GET',
        headers: authHeader(false, true)
    })

    const data = await response.json();

    return data;
}

export async function hideEnvelopeRows(productId, supplierID) {
    if (productId === null) {
        productId = -1;
    }

    const response = await fetch(`./ProductEnvelopes/HideEnvelopes?productId=${productId}&supplierID=${supplierID}`, {
        method: 'GET',
        headers: authHeader(false, true)
    })

    const data = await response.json();

    return data;
}

export function downloadFile(path, data) {
    let link = document.createElement('a');
    link.href = window.URL.createObjectURL(data);
    link.download = path.split('\\').pop().split('/').pop();

    document.body.appendChild(link);

    link.click();

    document.body.removeChild(link);
}

export async function hasLicence(vLicence) {
    const response = await fetch(`./Rights/HasLicence?licence=${vLicence}`, {
        method: 'GET',
        headers: authHeader(false, true)
    })

    const data = await response.json();

    return data;
}

export async function hasUserRight(vRight, vsupplierID) {
    const response = await fetch(`./Rights/HasRight?right=${vRight}&supplierID=${vsupplierID}`, {
        method: 'GET',
        headers: authHeader(false, true)
    })

    const data = await response.json();

    return data;
}


export function setSessionVal(vKey, vValue) {
    sessionStorage.setItem(vKey, vValue);
}

export function getSessionVal(vKey) {
    return sessionStorage.getItem(vKey);
}

export function reloadDataTable(id) {
    let table = $(`#${id}Table`).DataTable();

    if (table) {
        table.ajax.reload();
    }
}

export async function getApplicationType() {
    const response = await fetch('./System/GetApplicationType', {
        method: 'GET'
    })
    const data = await response.text();
    return data;
}

export function isObjectEmpty(objectName) {
    return (
        objectName &&
        Object.keys(objectName).length === 0 &&
        objectName.constructor === Object
    );
};

export function handleBackground() {
    document.body.classList.add('brand-overrides', 'u-alt-bg-color');
}


/*
    FULL EMAIL FIELD CHECKER (Mandatory, Valid, Duplicate from Single or Multiple Tables)
*/

export async function handleEmailCheck(fldID, fldValue, mandatoryLabel, emailInvalid, controller, supplierField, validationText, anotherController = '', anotherValidationText = '', id = -1, registrationID = "") {
    if (fldValue === '') {
        setErrorLabel(fldID, mandatoryLabel)
        return false;
    } else {
        const emailValid = await emailValidationCheck(fldValue);

        if (emailValid === false) {
            setErrorLabel(fldID, emailInvalid);
            return false;
        }
        else {
            if (anotherController !== '') {
                const anotherDuplicateCheck = await handleCheckDuplicate(anotherController, supplierField, fldValue, id, registrationID);
                if (anotherDuplicateCheck) {
                    setErrorLabel(fldID, anotherValidationText);
                    return false;
                } else {
                    const duplicateCheck = await handleCheckDuplicate(controller, fldID, fldValue, id, registrationID);
                    if (duplicateCheck) {
                        setErrorLabel(fldID, validationText);
                        return false;
                    } else {
                        document.getElementById(fldID).classList.remove("is-invalid");
                    }
                }
            } else {
                const duplicateCheck = await handleCheckDuplicate(controller, fldID, fldValue, id, registrationID);
                if (duplicateCheck) {
                    setErrorLabel(fldID, validationText);
                    return false;
                } else {
                    document.getElementById(fldID).classList.remove("is-invalid");
                }
            }

        }
    }

    return true;
}

export async function emailValidationCheck(fldValue) {
    const emailValidResponse = await fetch(`./Data/CheckEmailValid?email=${fldValue}`, {
        method: 'GET',
        headers: authHeader(false, true),
    });

    const emailValid = emailValidResponse.json();
    return emailValid;
}

async function handleCheckEmailAddress(fldValue, registrationID) {
    const checkResponse = await fetch(`./Registration/CheckEmailAddress?&value=${fldValue}&registrationID=${registrationID}`, {
        method: 'GET',
        headers: authHeader(false, true)
    })

    const emailCheck = checkResponse.json();
    return emailCheck;
}

export async function handleCheckRegistrationExists(fldID, fldValue, registrationID) {
    if (fldValue === '') {
        setErrorLabel(fldID, 'VALIDATION_MandatoryEmail')
        return false;
    } else {
        const emailValidationCheckData = await emailValidationCheck(fldValue)

        if (emailValidationCheckData === false) {
            setErrorLabel(fldID, 'VALIDATION_EmailComplexity')
            return emailValidationCheckData;
        } else {
            // THE emailCheck.uniqueID IS AN ENUM FOUND IN CTempRegistereSupplier -> RegistrationEmailStatus
            const emailCheck = await handleCheckEmailAddress(fldValue, registrationID);
            if (emailCheck.uniqueID === 0 || emailCheck.uniqueID === 3) {
                // If email does not exist as a registration
                document.getElementById(fldID).classList.remove("is-invalid");

                return emailCheck;

            } else if (emailCheck.uniqueID === 2) {
                // If email exists and registration ID matches
                document.getElementById(fldID).classList.remove("is-invalid");

                return handleRetrieveRegistration(fldValue);

            } else if (emailCheck.uniqueID === 4 || emailCheck.uniqueID === 5) {

                // Duplicate Email
                document.getElementById(fldID).classList.add("is-invalid");

                // emailCheck.uniqueID === 4 :: DuplicateRegistrationEmail
                // emailCheck.uniqueID === 5 :: DuplicateRegisteredEmail
                getResourceString(emailCheck.uniqueID === 4 ? 'REG_VALIDATION_PersonalEmailExists' : 'VALIDATION_PersonalEmailExists').then(text => {
                    document.getElementById('contactEmailAddress_LABEL').innerText = text;
                })

                return emailCheck;
            }
        }
    }
}

async function retrieveRegistration(email, password) {// Modal while API is finding account
    let returnData = '';
    await Swal.fire({
        title: 'Retrieving Registration',
        html: 'Attempting to retrieve your registration details.',
        allowOutsideClick: false,
        didOpen: () => {
            Swal.showLoading()

            let reg = getRegistrationDetails(email, password);
            reg.then(regData => {
                Swal.close();
                if (regData) {
                    if (regData.success) {
                        returnData = regData;
                        $("input[required]").each(function (i, obj) { $(this).removeClass('is-invalid') });
                        handleTimedSuccess('success', 'Registration Retrieved', 'Your registration has been retrieved.')
                    } else {
                        showCustomError(regData.objData, `Password Incorrect`, 'error')
                    }
                } else {
                    showCustomError('An error occurred, please check your details and try again', 'An error occurred', 'error')
                }
            })
        }
    });

    return returnData;
}

// Stops the Enter key from being pressed
const handleStopEnter = (e) => {
    return e.key !== 'Enter'
}

export async function handleRetrieveRegistration(email) {
    // Portal Login modal
    const { value: formValues } = await Swal.fire({
        title: 'Existing Registration',
        html:
            `The email address <strong>${email}</strong> has an unfinished registration.  You can retrieve your registration details by providing the password used in the registration` +
            '<br />' +
            '<div class="c-floating-label u-component-spacing u-component-spacing--2x">' +
            '   <input name="registrationPassword" id="registrationPassword" type="password" class="c-floating-label__input form-control" placeholder=" " autocomplete="off" />' +
            '   <label for="registrationPassword" class="c-floating-label__label" style="text-align: left">Registration Password</label>' +
            '   <div class="invalid-feedback">' +
            '       <span id="password_LABEL"></span>' +
            '   </div>' +
            '</div>',
        width: 600,
        allowOutsideClick: false,
        focusConfirm: false,
        allowEnterKey: false,
        allowEscapeKey: true,
        buttonsStyling: false,
        showCancelButton: true,
        confirmButtonText: 'Retrieve Registration',
        reverseButtons: true,
        customClass: {
            confirmButton: 'btn btn-success m-2',
            cancelButton: 'btn btn-danger m-2',
        },
        didOpen: () => {
            let passwordInput = document.getElementById('registrationPassword');
            if (passwordInput) {
                passwordInput.focus();
                passwordInput.onkeyup = (event) => { handleStopEnter(event) }
            }

        },
        preConfirm: () => {
            return {
                'input1': email,
                'input2': document.getElementById('registrationPassword').value
            }
        }
    });

    let returnData = {};

    if (formValues) {
        await retrieveRegistration(formValues.input1, formValues.input2)
            .then(data => {
                if (data) {
                    returnData = data;
                }
            });
    }

    return returnData;
}


export async function getRegistrationDetails(email, password) {
    if (email && password) {
        let details = {
            email: email,
            password: password
        }

        const response = await fetch(`./Registration/RetrieveRegistration`, {
            method: 'POST',
            headers: authHeader(false, true),
            body: JSON.stringify(details)
        });

        const data = await response.json();

        return data;
    }

    return null;
}


async function handleCheckDuplicate(controller, fldID, fldValue, id = -1, registrationID = '') {
    let url = `./${controller}/CheckValueExists?field=${fldID}&value=${fldValue}`;

    if (id !== -1) {
        url += `&id=${id}`;
    } else if (registrationID !== '') {
        url += `&registrationID=${registrationID}`;
    }

    const response = await fetch(url, {
        method: 'GET',
        headers: authHeader(false, true)
    });

    const data = response.json();
    return data;
}

export function setErrorLabel(control, text) {
    document.getElementById(control).classList.add("is-invalid");

    getResourceString(text).then(data => {
        document.getElementById(`${control}_LABEL`).innerText = data;
    })
}

/*
    END OF THE FULL EMAIL CHECKER FUNCTIONALITY
 */


export async function duplicateCheck(fld, value, controller, id = -1, additionalParams = '') {
    if (value !== '') {
        let params = '';
        if (id !== null) {
            params = `&id=${id}`;
        }
        params += additionalParams;

        const response = await fetch(`./${controller}/CheckValueExists?field=${fld}&value=${value}${params}`, {
            method: 'GET',
            headers: authHeader(false, true),
        });

        const data = response.json();
        return data;
    }
    return false;
}

/*
 This duplicate check function checks a table for the same existing data
 */
export async function handleDuplicateCheck(e, controller, validationText = '', id = -1, additionalParams = '') {
    const response = await duplicateCheck(e.target.id, e.target.value, controller, id, additionalParams);

    if (response === true) {
        document.getElementById(e.target.id).classList.add("is-invalid");

        getResourceString(validationText).then(text => {
            document.getElementById(`${e.target.id}_LABEL`).innerText = text;
        })
        return false;
    } else {
        document.getElementById(e.target.id).classList.remove("is-invalid");
        return true;
    }
}

export async function handleDuplicateCheckAlt(fld, value, controller, validationText = '', id = -1, additionalParams = '') {
    const response = await duplicateCheck(fld, value, controller, id, additionalParams);

    if (response === true) {
        document.getElementById(fld).classList.add("is-invalid");

        getResourceString(validationText).then(text => {
            document.getElementById(`${fld}_LABEL`).innerText = text;
        })
        return false;
    } else {
        document.getElementById(fld).classList.remove("is-invalid");
        return true;
    }
}

/*
 This duplicate check function checks 2 different tables for the same data (ie Registration -> Company Name:  Check the name exists in the Tenderers table - THEN checks the Registration table)
 */

// For Registration
export async function handleMultipleDuplicateCheck(e, controller, supplierField, anotherController, validationText, registrationID) {
    const response = handleMultipleDuplicateCheckAlt(e.target.id, e.target.value, controller, supplierField, anotherController, validationText, registrationID);
    return response;
}

// For Registration
export async function handleMultipleDuplicateCheckAlt(fld, value, controller, supplierField, anotherController, validationText, registrationID) {
    let additionalParams = registrationID !== '' ? `&registrationID=${registrationID}` : '';
    let firstCheckResponse = false;
    let secondCheckResponse = false;
    let vText = '';

    if (value !== '') {
        firstCheckResponse = await duplicateCheck(fld, value, controller, null, additionalParams);

        if (firstCheckResponse === true) {
            vText = `REG_${validationText}`;
        } else {
            secondCheckResponse = await duplicateCheck(supplierField, value, anotherController);

            if (secondCheckResponse === true) {
                vText = `${validationText}`;
            }
        }

        if (firstCheckResponse || secondCheckResponse) {
            document.getElementById(fld).classList.add("is-invalid");

            getResourceString(vText).then(data => {
                document.getElementById(`${fld}_LABEL`).innerText = data;
            })
            return false;
        }
        else {
            document.getElementById(fld).classList.remove("is-invalid");
            return true;
        }
    }
}

export function handleCheckCardMonthValidation(e) {
    if (e.target.value !== '' && e.target.value > 12) {
        document.getElementById(`${e.target.id}`).value = 12;
        return false;
    } else if (e.target.value !== '' && e.target.value < 1) {
        document.getElementById(`${e.target.id}`).value = 1;
        return false;
    }
    else {
        return true;
    }
}


export function handleRequiredCheck(e, validationText) {
    return handleRequiredCheckAlt(e.target.id, e.target.value, validationText)
}

export function handleRequiredCheckAlt(fieldID, value, validationText) {
    if (value === '') {
        document.getElementById(fieldID).classList.add("is-invalid");
        getResourceString(validationText).then(data => {
            document.getElementById(`${fieldID}_LABEL`).innerText = data;
        })
        return false;
    } else {
        document.getElementById(fieldID).classList.remove("is-invalid");
        return true;
    }
}


export async function checkEmailIsValid(fieldname, email, validationText) {
    const emailValid = await emailValidationCheck(email);

    if (emailValid === false) {
        document.getElementById(fieldname).classList.add("is-invalid");

        getResourceString(validationText).then(data => {
            document.getElementById(`${fieldname}_LABEL`).innerText = data;
        })

        return false;
    } else {
        if (document.getElementById(fieldname) !== null) {
            document.getElementById(fieldname).classList.remove("is-invalid");
            document.getElementById(fieldname).classList.add("is-valid");         // Add green check
        }

        return true;
    }
}


export async function getRegexValidationRules() {
    const response = await fetch(`./Settings/GetRegexRules`, {
        method: 'GET',
        headers: authHeader(false, true)
    })

    const data = await response.json();
    return data.map(obj => {
        return {
            title: obj.Title,
            regex: obj.Regex
        }
    });
}

export function getRegex(vArray, vKey) {
    if (vArray.length > 0) {
        let obj = vArray.find(x => x.title === vKey);
        if (obj) {
            return obj.regex.toString();
        }
    }
}

export function handlePatternValidation(e, validationText) {
    if (e.target.validationMessage !== '') {
        document.getElementById(e.target.id).classList.remove("is-valid");  // Remove green check
        document.getElementById(e.target.id).classList.add("is-invalid");   // Add red X

        getResourceString(validationText).then(data => {
            document.getElementById(`${e.target.name}_LABEL`).innerText = data;
        })
        return false;
    } else {
        document.getElementById(e.target.id).classList.remove("is-invalid");    // Remove red X
        document.getElementById(e.target.id).classList.add("is-valid");         // Add greed check
        return true;
    }
}

export function isValidUUID(str) {
    // Regular expression to check if string is a valid UUID
    const regexExp = /^[0-9a-fA-F]{8}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{12}$/gi;
    return regexExp.test(str);
}

export function handleTenderStatus(e, action, supplierID, tenderID) {
    if (action === 2) {
        handleFocusedTenderButtonState(e, e.target.dataset.actionEnabled !== 'true' ? 'Stop Watching' : "Watch");

        if (e.target.dataset.actionEnabled === 'true') {
            setTenderButtonState(document.getElementById(`hide_${tenderID}`), 'Hide');
        }
    } else if (action === 3) {
        document.getElementById(`watch_${tenderID}`).style.display = 'none';
        document.getElementById(`hide_${tenderID}`).style.display = 'none';

        handleFocusedTenderButtonState(e, e.target.innerHTML);

    } else if (action === 4) {
        handleFocusedTenderButtonState(e, e.target.dataset.actionEnabled !== 'true' ? 'Unhide' : "Hide");

        if (e.target.dataset.actionEnabled === 'true') {
            setTenderButtonState(document.getElementById(`watch_${tenderID}`), 'Watch', false);
        }
    }

    updateTenderStatus(action, supplierID, tenderID, e.target.dataset.actionEnabled);
}

export function updateTenderStatus(action, supplierID, tenderID, actionEnabled) {
    fetch(`./Suppliers/ChangeSupplierTenderStatus?supplierID=${supplierID}&tenderID=${tenderID}&feedStatus=${action}&status=${actionEnabled}`, {
        method: 'POST',
        headers: authHeader()
    })
        .then(response => response.json())
        .catch(error => {
            console.log('Error: ', error);
        });

}


export function handleFocusedTenderButtonState(e, text) {
    e.target.dataset.actionEnabled = e.target.dataset.actionEnabled !== 'true';
    e.target.innerHTML = text;
}

export function setTenderButtonState(otherButton, text, state) {
    otherButton.dataset.actionEnabled = state;
    otherButton.innerHTML = text;
}

export async function handleLogIntoPortal(isRegistered, url, supplierID, contactID, tenderID, organisationID, portalName) {
    await swal.fire({
        title: 'Logging in',
        html: 'Logging you in to ' + portalName + ' - Please Wait.',
        allowOutsideClick: false,
        didOpen: () => {
            Swal.showLoading();
            if (isRegistered) {
                // Update the View status
                if (tenderID > 0) {
                    updateTenderStatus(1, supplierID, tenderID, false);
                }

                // Continue to portal
                getApplicationType().then(application => {
                    fetch(`./Organisations/AuthenticateSupplierPortalLogin?contactID=${contactID}&tenderID=${tenderID}&organisationID=${organisationID}&application=${application}`, {
                        method: 'POST',
                        headers: authHeader()
                    })
                        .then(response => response.json())
                        .then(data => {
                            if (data.success === true) {
                                if (url !== '' && data.objData) {
                                    Swal.close();
                                    window.open(`${url}/Login?auth=${data.objData}`, '_blank');
                                }
                                else if (url === '') {
                                    fetch(`./Organisations/GetPortalURL?organisationID=${organisationID}`, {
                                        method: 'GET',
                                        headers: authHeader()
                                    })
                                        .then(response => response.json())
                                        .then(obURL => {
                                            if (obURL !== "") {
                                                Swal.close();
                                                window.open(`${obURL}/Login?auth=${data.objData}`, '_blank');
                                            } else {
                                                return false;
                                            }
                                        })
                                } else {
                                    Swal.close();
                                    showCustomError(`There has been a problem logging you into the ${portalName}.<br /><br />Please contact Sell2 Support.`, `Unable to Log In to ${portalName}`, 'error');
                                    window.open(`${url}`, '_blank');
                                }
                                return true;
                            } else if (data.message !== "") {
                                showCustomError(`You are unable to access this portal for the following reason:<br /><br />${data.message}<br /><br />Please contact Sell2 Support.`, `Unable to Access ${portalName}`, 'error', '45em');
                                return false;
                            } else {
                                showCustomError('Problem occurred while logging you in to the portal.  Please contact Sell2 Support.', 'Oops', 'error');
                                return false;
                            }
                        })
                        .catch(error => {
                            console.log('Error: ', error);
                            return false;
                        });
                });
            } else {
                fetch(`./Tenders/GetIntendTenderID?iTenderID=${tenderID}`, {
                    method: 'GET',
                    headers: authHeader()
                })
                    .then(response => response.json())
                    .then(portalTenderID => {
                        if (portalTenderID > 0) {
                            fetch(`./Organisations/GetPortalURL?organisationID=${organisationID}`, {
                                method: 'GET',
                                headers: authHeader()
                            })
                                .then(response => response.json())
                                .then(obURL => {
                                    if (obURL !== "") {
                                        window.open(`${obURL}/ProjectManage/${portalTenderID}`);
                                    }
                                })

                        }
                    })
            }

        }
    }).then(() => {
        Swal.close();
    });
}

export async function logIntoPortal(isRegistered, url, supplierID, contactID, tenderID, organisationID, portalName) {
    if (!isRegistered) {
        if (url === '') {
            fetch(`./Organisations/GetPortalURL?organisationID=${organisationID}`, {
                method: 'GET',
                headers: authHeader()
            })
                .then(response => response.json())
                .then(obURL => {
                    if (obURL !== "") {
                        window.open(obURL, '_blank');
                    } else {
                        return false;
                    }
                })
        } else {
            window.open(url, '_blank');
        }
    } else {
        fetch(`./Organisations/GetContactPortalAccountStatus?contactID=${contactID}&organisationID=${organisationID}`, {
            method: 'GET',
            headers: authHeader()
        })
            .then(response => response.json())
            .then(data => {
                if (data === -1) {
                    handleLogIntoPortal(isRegistered, url, supplierID, contactID, tenderID, organisationID, portalName)
                } else {
                    var message = '';
                    var width = 700;
                    if (data === 1) {
                        message = `<p>Your user account on '<b>${portalName}</b>' is currently locked.</p><p>Please contact the organisation and request for your supplier account on the <b>${portalName}</b> portal to be unlocked, and try again.</p>`;
                    } else if (data === 6) {
                        message = `<p>Your supplier account on '<b>${portalName}</b>' has been disabled.</p><p>Please contact <b>${portalName}</b> for further information.</p>`;
                    } else if (data === 8) {
                        message = `<p>Your supplier account on '<b>${portalName}</b>' is awaiting verification.</p><p>Please contact <b>${portalName}</b> for further information.</p>`;
                    } else if (data === -2) {
                        message = `<p>Your user account has not been linked with '<b>${portalName}</b>'.</p><p>Please contact Sell2 Support for more information.</p>`;
                    } else {
                        message = `There is a problem with your supplier account on the <b>${portalName}</b> portal.  Please contact the organisation for more information.<br />
                        <p style="line-height:3rem">You can also visit the portal directly, and try and log in to verify your account is active by following the steps below:</p>

                        <ol style="text-align: left; margin:1rem auto 1rem 20rem">
                            <li style="margin-bottom: 0.5rem;">Vist the portal directly - click <b><u><a href=${url} target="_blank">here</a></u></b></li>
                            <li style="margin-bottom: 0.5rem;">Click <b>Forgotten Details</b></li>
                            <li style="margin-bottom: 0.5rem;">Complete the password recovery</li>
                            <li style="margin-bottom: 0.5rem;">Log in to the portal directly</li>
                        </ol>

                        <br />
                        <div style="text-align: left">If there is a problem with logging in to the account, please contact the <b>${portalName}</b> to assist you with your supplier account on the portal.</div>
                        <div style="text-align: left; margin-top: 1rem">If you are able to log in to the portal, please contact Sell2 Support for further assistance with your Sell2 supplier account linking with the portal.</div>`;
                        width = 950;
                    }

                    showCustomError(message, 'Problem access your account', 'Warning', width);
                }
            });
    }

    return false;
}

export function handleSQRequiredCheck(e, validationText, mandatoryType, validationFields) {
    return handleSQRequiredCheckAlt(e.target, e.target.value, e.target.type, validationText, mandatoryType, validationFields)
}

export function handleSQRequiredCheckAlt(field, value, type, validationText, mandatoryType, validationFields) {
    var id = field.id ? field.id : field.attr('id');

    //#region Conditional Mandatory fields Check
    for (const [key, value] of validationFields.entries()) {
        var fieldstoCheck = value.replace("Fields[", '');
        let invalidCheck = false;
        fieldstoCheck = fieldstoCheck.replace(']', '');
        fieldstoCheck = fieldstoCheck.split(',');

        fieldstoCheck.forEach(check => {
            if (check.match(/S\d+Q\d+SQ\d+/)) {
                if (document.getElementById(key).value=== '' && document.getElementById(`rdo${check}`).checked) {
                    invalidCheck = true;
                }
            }
        })

        if (invalidCheck) {
            document.getElementById(key).classList.add("is-invalid");
            document.getElementById(key).setAttribute("required", '');
            getResourceString(validationText).then(data => {
                document.getElementById(`${key}_LABEL`).innerText = data;
            })
        }
        else {
            document.getElementById(key).classList.remove("is-invalid");
            document.getElementById(key).removeAttribute("required");
        }
    }
    //#endregion

    if (mandatoryType.toLowerCase() === "optional") { return true; }
    else if (mandatoryType.toLowerCase() === "mandatory") {
        if (value === '') {
            document.getElementById(id).classList.add("is-invalid");
            if (type === "radio") {
                getResourceString(validationText).then(data => {
                    document.getElementById(`${field.name}_LABEL`).innerText = data;
                })
            }
            else {
                getResourceString(validationText).then(data => {
                    document.getElementById(`${id}_LABEL`).innerText = data;
                })
            }
            return false;
        }
        else {
            if (type === "radio") {
                document.getElementById(id.match(/S\d+Q\d+/)[0]).classList.remove("is-invalid");
            }
            else {
                document.getElementById(id).classList.remove("is-invalid");
            }
            return true;
        }
    }
    else {
        if (value === '') {
            var fields = mandatoryType.replace("Fields[", '');
            var invalid = false;
            fields = fields.replace(']', '');
            fields = fields.split(',');
            fields.forEach((check, i) => {
                if (check.match(/S\d+Q\d+SQ\d+/)) {
                    if (document.getElementById(`rdo${check}`).checked) { invalid = true; }
                } else {
                    // do nothing?
                }
            })
            if (invalid) {
                document.getElementById(id).classList.add("is-invalid");
                document.getElementById(id).setAttribute("required", '');
                getResourceString(validationText).then(data => {
                    document.getElementById(`${id}_LABEL`).innerText = data;
                })
                return false;
            }
            else {
                document.getElementById(id).classList.remove("is-invalid");
                document.getElementById(id).removeAttribute("required");
                return true;
            }
        } else {
            document.getElementById(id).classList.remove("is-invalid");
            return true;
        }
    }
}

// Get the Package Service Allowance for the suppliers subscription
export async function getSupplierAllowance(supplierID, serviceType) {
    const response = await fetch(`./Suppliers/GetSupplierAllowance?supplierID=${supplierID}&packageService=${serviceType}`, {
        method: 'GET',
        headers: authHeader(false, false)
    });

    const data = await response.json();
    return data;
}

// Get all supplier details by ID
export async function getSupplierDetails(supplierID) {
    const response = await fetch(`./Suppliers/GetSupplierDetails?supplierID=${supplierID}`, {
        method: 'GET',
        headers: authHeader()
    });

    const data = await response.json();
    return data;
}

// Get all supplier details by ID
export async function getSupplierName(supplierID) {
    const response = await fetch(`./Suppliers/GetSupplierName?supplierID=${supplierID}`, {
        method: 'GET',
        headers: authHeader()
    });

    const data = await response.json();
    return data;
}

// Get all supplier details by ID
export async function getContactName(contactID) {
    const response = await fetch(`./SupplierUsers/GetContactName?contactID=${contactID}`, {
        method: 'GET',
        headers: authHeader()
    });

    const data = await response.json();
    return data;
}

export async function getSupportDetails() {
    const response = await fetch(`./Settings/GetSupportDetails`, {
        method: 'GET',
        headers: authHeader(false, true)
    });

    const data = await response.json();
    return data;
}

export function selectAllInputText(e) {
    const input = document.getElementById(e.target.id);
    input.focus();
    input.select();
}

export async function sendForgottenPassword(email) {
    let application = await getApplicationType();

    let response = await fetch(`./SupplierUsers/ForgotPassword?strEmail=${email}&application=${application}`, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json'
        }
    });

    const data = await response.json();
    if (data) {
        return true;
    }
    return false;
}

export async function sendNewUserEmail(email) {
    let application = await getApplicationType();

    let response = await fetch(`./SupplierUsers/NewUserEmail?strEmail=${email}&application=${application}`, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json'
        }
    });

    const data = await response.json();
    if (data) {
        return true;
    }
    return false;
}

export async function handleRegistrationValidationCheck(bProceedRegistering, contact, company, registrationID) {
    if (contact) {
        let contactEmail = document.getElementById('contactEmailAddress')
        if (contactEmail && contactEmail.value && bProceedRegistering) {
            const emailCheck = await handleEmailCheck(contactEmail.id, contactEmail.value, 'VALIDATION_MandatoryEmail', 'VALIDATION_EmailComplexity', 'Registration', 'Email', 'REG_VALIDATION_PersonalEmailExists', 'SupplierUsers', 'VALIDATION_PersonalEmailExists', -1, registrationID)
            bProceedRegistering = emailCheck
        }
    }

    if (company) {
        let companyName = document.getElementById('companyName')
        if (companyName && companyName.value && bProceedRegistering) {
            const companyNameCheck = await handleMultipleDuplicateCheckAlt(companyName.id, companyName.value, 'Registration', 'Name', 'Suppliers', 'VALIDATION_CompanyNameExists', registrationID)
            bProceedRegistering = companyNameCheck === true;
        }

        let companyRegNo = document.getElementById('companyRegNo')
        if (companyRegNo && companyRegNo.value && bProceedRegistering) {
            const companyRegNoCheck = await handleMultipleDuplicateCheckAlt(companyRegNo.id, companyRegNo.value, 'Registration', 'CompanyRegNo', 'Suppliers', 'VALIDATION_CompanyRegExists', registrationID)
            bProceedRegistering = companyRegNoCheck === true;
        }

        let vatRegNo = document.getElementById('vatRegNo')
        if (vatRegNo && vatRegNo.value && bProceedRegistering) {
            const vatRegNoCheck = await handleMultipleDuplicateCheckAlt(vatRegNo.id, vatRegNo.value, 'Registration', 'CompanyRegNo', 'Suppliers', 'VALIDATION_CompanyRegExists', registrationID)
            bProceedRegistering = vatRegNoCheck === true;
        }

        let companyEmail = document.getElementById('companyEmail')
        if (companyEmail && companyEmail.value && bProceedRegistering) {
            const emailCheck = await handleMultipleDuplicateCheckAlt(companyEmail.id, companyEmail.value, 'Registration', 'VatRegNo', 'Suppliers', 'VALIDATION_VATNoExists', registrationID)
            bProceedRegistering = emailCheck === true;
        }
    }

    return bProceedRegistering;
}

// Checks to see if the page is enabled in the NavMenu table, otherwise return to a default page
export async function pageEnabled(pageTitle, returnURL) {
    let checkRespose = await fetch(`./NavMenu/CheckPageEnabled?pageTitle=${pageTitle}&supplierID=${getSupplierID()}`, {
        method: 'GET',
        headers: authHeader(false, true)
    });

    let checkData = await checkRespose.json();
    if (checkData === false) {
        history.push(returnURL);
        return false;
    } else {
        return true;
    }
}

// Gets the virtual directory
export function getVirtualDirectory() {
    let parts = window.location.pathname.split('/');

    if (process.env.NODE_ENV === 'development') {
        return 'Sell2BLPD';
    } else if (parts.length > 0) {
        return parts[1];
    } else {
        return '';
    }
}

export async function getNavMenu() {
    let response = await fetch(`./NavMenu/GetNavBar?supplierID=${getSupplierID()}`, {
        method: 'GET',
        headers: authHeader(false, true)
    });

    let data = await response.json();
    return data;
}


export async function validateUser(details) {
    let response = await fetch('./Login/ValidateUser', {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json'
        },
        body: JSON.stringify(details)
    });

    let data = await response.json();
    return data;
}


export async function loginUser(details, checkBusinessClassifications = true) {
    let data = await validateUser(details);

    if (data.error !== "" || data.token === "") {
        document.getElementById('password').classList.add("is-invalid");
        document.getElementById('email').classList.add("is-invalid");

        getResourceString('INVALID_CREDENTIALS').then(data => {
            document.getElementById('password_LABEL').innerText = data;
        })

    } else {
        // set the cookie
        const token = JSON.stringify(data.token);

        setCookie(`token-${getVirtualDirectory()}`, token, 8 / 24);
        updateContactPortals(data.user.supplierID, data.user.contactID);
        setSessionVal('TendersPageNumber', 1); // Reset the page number for Tenders when you log in (to reset if the user viewed public tenders)

    }
    return data;
}