import 'datatables.net-bs4/css/dataTables.bootstrap4.min.css';
import React, { useContext, useState } from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { Card, Col, FormFeedback, FormGroup, Label, Row } from "reactstrap";
import { authHeader } from '../../helpers/auth-header';
import { getApplicationType, getSupplierID, loginUser, showCustomError } from '../../helpers/general';
import { history } from '../../helpers/history';
import MenuContext from '../layout/MenuContext';

require('datatables.net-bs4');

const Login = () => {
    const { setNavMenu } = useContext(MenuContext);
    const [value, setValue] = useState({});
    const { login } = useSelector(state => state);
    const [showPassword, setShowPassword] = useState(false);
    const togglePassword = () => setShowPassword(!showPassword);

    function handleChange(e) {
        e.persist();
        setValue(value => ({
            ...value,
            [e.target.name]: e.target.value
        }));
    }

    const handleEnterKey = (e) => {
        if (e.key === 'Enter') {
            handleLogin();
        }
    }

    const handleLogin = () => {
        if (value && value.password.length > 0 && value.password.length > 0) {
            let userDetails = { email: value.email, password: value.password };

            loginUser(userDetails, true)
                .then(response => {
                    if (response.error === "") {
                        fetch(`./Suppliers/BusinessClassificationsCount?supplierID=${getSupplierID()}`, {
                            method: 'GET',
                            headers: authHeader()
                        })
                            .then(response => response.json())
                            .then(businessCategoryData => {
                                if (businessCategoryData === 0) {
                                    sessionStorage.setItem('ShowBusinessClassificationWarning', true);
                                }

                                fetch(`./NavMenu/GetNavBar?supplierID=${getSupplierID()}`, {
                                    method: 'GET',
                                    headers: authHeader(false, true)
                                })
                                    .then(response => response.json())
                                    .then(nav => {
                                        setNavMenu(nav);
                                        history.push('/dashboard');
                                    });
                            })
                            .catch(err => { console.log(err); return false; })
                    }
                    else {
                        showCustomError(response.error, 'Cannot Log In', 'warning')
                    }
                })
        } else {
            showCustomError('You must provide an email address and password', 'Missing Credentials', 'error')
        }
    }

    return (
        <>
            <Card body className=" c-login-forms u-box-shadow u-component-spacing--2x">
                <div className="u-flex-center">
                    <h3 className="c-login-forms__heading u-component-spacing--2x c-broken-underline">
                        Log in to your account
                    </h3>
                </div>
                <div className="c-login-forms__form u-component-spacing">
                    <div className="c-login-forms__form u-component-spacing">
                        <div className="c-floating-label u-component-spacing">
                            <FormGroup>
                                <input
                                    name="email"
                                    type="email"
                                    className="c-floating-label__input form-control"
                                    onChange={(e) => handleChange(e)}
                                    id="email"
                                    placeholder=" "
                                    data-error-message="Please enter a valid email address"
                                    required />
                                <Label className="c-floating-label__label" htmlFor="email">Email</Label>
                            </FormGroup>
                        </div>
                        <div className="c-floating-label u-component-spacing">
                            <FormGroup>
                                <input
                                    name="password"
                                    type={showPassword ? 'text' : 'password'}
                                    className="c-floating-label__input form-control"
                                    onChange={(e) => handleChange(e)}
                                    onKeyUp={(e) => handleEnterKey(e)}
                                    id="password"
                                    placeholder=" "
                                    required />
                                <Label className="c-floating-label__label" htmlFor="password">Password</Label>
                                <button className="c-floating-label__show-password" type="button" onClick={togglePassword}>{showPassword ? 'Hide' : 'Show'}</button>
                                <FormFeedback>
                                    <span id="password_LABEL"></span>
                                </FormFeedback>
                            </FormGroup>
                        </div>
                        <input type="submit" className="c-cta u-component-spacing" value="Login" onClick={handleLogin} />
                    </div>
                </div>

                <Link to="/forgotten-password" className="c-login-forms__forgot-password u-component-spacing--2x">I've forgotten my password</Link>

                <Row className="mt-2">
                    <Col xs={12} className="text-center">
                        <span>{login && login.error}</span>
                    </Col>
                </Row>
            </Card>
        </>
    )
}
export default Login;