import React, { useEffect } from 'react';
import { useLocation, useHistory } from 'react-router-dom';
import { authHeader } from '../../helpers/auth-header';
import { getSupplierID } from '../../helpers/general';

const RouteChangeListener = ({ children }) => {
    const history = useHistory();
    const location = useLocation();

    useEffect(() => {
        var supplierID = getSupplierID();

        if (supplierID > 0) {
            const checkAccountStatus = async () => {
                try {
                    // Check if user is already on the renewal page
                    if (location.pathname.toLowerCase() === '/renew'
                        || location.pathname.toLowerCase().includes('/merchant-')
                        || location.pathname.toLowerCase().includes('/payment-')
                        || location.pathname.toLowerCase() === '/404') {
                        return; // Exit loop
                    }

                    const response = await fetch(`./Suppliers/IsAccountExpired?supplierID=${supplierID}`, {
                        method: 'GET',
                        headers: authHeader()
                    });

                    const data = await response.json();
                    const isExpired = data;

                    // If the account is expired, redirect to the renewal page
                    if (isExpired) {
                        history.push('/renew'); // Using history.push() instead of navigate
                    }
                } catch (error) {
                    console.error('Error checking account status:', error);

                }
            };

            // Run the check every time the route changes
            checkAccountStatus();
        }
    }, [history, location.pathname]);

    return (
        <>
            {children}
        </>
    )
};

export default RouteChangeListener;
