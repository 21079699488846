import "bootstrap/dist/css/bootstrap.css";
import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { Container } from 'reactstrap';
import { authHeader } from "../../helpers/auth-header";
import { getResourceString, handleBackground, handleCheckRegistrationExists, handleRegistrationValidationCheck, pageEnabled, showCustomError } from "../../helpers/general";
import Translation from "../controls/Translation";
import Footer from "../layout/Footer";
import NavMenu from "../layout/NavMenu";
import PaymentDetails from "../Payment/PaymentDetails";
import CompanyDetails from '../Registration/CompanyDetails';
import CompanyUserDetails from "../Registration/CompanyUserDetails";
import PackageSelection from "../Registration/PackageSelection";
import Summary from "../Registration/Summary";

require('datatables.net-bs4');

const Register = () => {
    const [regDetails, setRegDetails] = useState({ tempSupplierID: '00000000-0000-0000-0000-000000000000' });
    const [regComplete, setRegComplete] = useState(false);
    const [priceExcVat, setPriceExcVat] = useState(0);
    const [services, setServices] = useState([]);
    const [packages, setPackages] = useState({ isEnabled: null, packageList: [] });
    const [countries, setCountries] = useState([]);
    const [isPageEnabled, setIsPageEnabled] = useState(false);
    const [urlTC, setUrlTC] = useState(null);
    let location = useLocation();


    useEffect(() => {
        pageEnabled('Nav_Register', '/404').then(enabled => { setIsPageEnabled(enabled); });

        fetch(`./Packages/GetPackageDetails`, {
            method: 'GET',
            headers: authHeader(false, true)
        })
            .then(response => response.json())
            .then(data => {
                setServices(data);
            })
            .catch(err => {
                console.log(err);
            });

        fetch(`./Settings/ArePackagesEnabled`, {
            method: 'GET',
            headers: authHeader(false, true)
        })
            .then(response => response.json())
            .then(packagesEnabled => {
                setPackages(prevState => ({
                    ...prevState, isEnabled: packagesEnabled
                }));

                fetch(`./Packages/GetActivePackages`, {
                    method: 'GET',
                    headers: authHeader(false, true)
                })
                    .then(response => response.json())
                    .then(packages => {
                        setPackages(prevState => ({
                            ...prevState, packageList: packages
                        }));
                    })
                    .catch(err => {
                        console.log(err);
                    });
            })
            .catch(err => {
                console.log(err);
            });

        fetch(`./Settings/GetWebSetting?settingname=terms_and_conditions_url`, {
            method: 'GET',
            headers: authHeader(false, true)
        })
            .then(response => response.json())
            .then(urlData => {
                setUrlTC(urlData);
            })
            .catch(err => {
                console.log(err);
            });

        handleBackground();

        fetch(`./Countries/GetCountries`, {
            method: 'GET',
            headers: authHeader(false, true)
        })
            .then(response => response.json())
            .then(data => {
                let vCountries = [];

                vCountries.push({ label: "Select..", value: "-1" });

                data.map(obj => vCountries.push({ label: obj.title, value: obj.abbrev }));
                setCountries(vCountries);
            })
            .catch(err => {
                console.log(err);
            });

        if (location.state && location.state.packageID) {
            setRegDetails(prevState => ({
                ...prevState,
                'tempPlan': location.state.packageID
            }));
        } else if (location.state && location.state.tempSupplierID && location.state.paymentTryAgain) {
            fetch(`./Registration/GetRegistration?tempSupplierID=${location.state.tempSupplierID}`, {
                method: 'GET',
                headers: authHeader(false, true),
            })
                .then(response => response.json())
                .then(data => {
                    fetch(`./Packages/GetPackagePrice?packageID=${data.tempPlan}`, {
                        method: 'GET',
                        headers: authHeader(false, true),
                    })
                        .then(priceResponse => priceResponse.json())
                        .then(price => {
                            setRegDetails(data);
                            setRegComplete(true);
                            setPriceExcVat(price)
                        })
                        .catch(err => {
                            console.log(err);
                        });
                })  
            .catch(err => {
                console.log(err);
            });
        }
    }, [location.state]);

    // Target elements
    let multiStepFormNav = document.querySelector('.c-multi-step-form__progress-bar');
    let multiStepFormContent = document.querySelector('.c-multi-step-form__panel-container');

    // Store CSS class names
    let panelInViewClass = 'c-multi-step-form__panel--in-view';
    let panelInViewPastClass = 'c-multi-step-form__panel--in-view-past';
    let heightLimiterClass = 'c-multi-step-form__panel--limit-height';

    // Create array of panel heights
    let contentPanelHeights = [];

    // Function to set panel height
    function setMaxHeights(panelIndex, height) {
        multiStepFormContent.children[panelIndex].classList.add(heightLimiterClass);
    }


    // Change panel function
    function changePanel(panelNumber) {

        // Remove current step aria label from nav progress bar
        multiStepFormNav.querySelector('[aria-current="step"]').removeAttribute('aria-current');

        // Loop through all panels
        for (let i = 0; i < multiStepFormContent.children.length; i++) {

            // Target appropriate elements within nav progress bar and panels
            const navChild = multiStepFormNav.children[i];
            const navChildButton = navChild.querySelector('.js-change-panel');
            const navChildProgressMessage = navChild.querySelector('.js-progress-message');
            const contentChild = multiStepFormContent.children[i];

            // Set panels to a max height on the current panel
            setMaxHeights(i, contentPanelHeights[panelNumber]);


            // IF panel step has been completed (i.e. is to the left of current)
            if (i < panelNumber) {
                // Set previous steps in nav to 'completed'
                navChildProgressMessage.innerHTML = 'Completed: ';

                // Remove disabled attribute from previous step buttons
                navChildButton.removeAttribute('disabled');

                // Add in-view class to slide
                contentChild.classList.add(panelInViewPastClass);

                // Stop tabbing to previous slides
                contentChild.setAttribute('inert', '');

                // Panel is no longer the current panel, so remove current class
                navChildButton.classList.remove('c-multi-step-form__progress-step--current');

                // Panel is now complete, so add completed class
                navChildButton.classList.add('c-multi-step-form__progress-step--completed');
            }
            // IF panel step is the current panel in focus
            else if (i === parseInt(panelNumber)) {
                // Set current step in nav to 'current'
                navChildProgressMessage.innerHTML = 'Current: ';

                // In case panel was previously completed, disable the button in the nav
                navChildButton.setAttribute('disabled', 'true');

                // Panel is currently in view, add current class
                navChildButton.classList.add('c-multi-step-form__progress-step--current');

                // In case panel was previously completed, remove completed class
                navChildButton.classList.remove('c-multi-step-form__progress-step--completed');

                // Set current step aria label in nav progress bar
                navChild.setAttribute('aria-current', 'step');

                // Add in-view class to slide
                contentChild.classList.add(panelInViewClass);

                // Add in-view class to slide
                contentChild.classList.remove(panelInViewPastClass);

                // Make current panel 'tabbable'
                contentChild.removeAttribute('inert');

                // Set focus in new slide
                contentChild.focus();

                // Remove height limiter class after transition
                setTimeout(() => {
                    multiStepFormContent.children[panelNumber].classList.remove(heightLimiterClass);
                }, 500);
            }
            else {
                // Set future steps in nav to empty
                navChildProgressMessage.innerHTML = '';

                // In case panel was previously completed, disable the button in the nav
                navChildButton.setAttribute('disabled', 'true');

                // Remove any in-view classes from slides
                contentChild.classList.remove(panelInViewClass);

                // Add in-view class to slide
                contentChild.classList.remove(panelInViewPastClass);

                // Stop tabbing to future slides
                contentChild.setAttribute('inert', '');

                // In case panel was previously current, remove the current class
                navChildButton.classList.remove('c-multi-step-form__progress-step--current');

                // In case panel was previously completed, remove the completed class
                navChildButton.classList.remove('c-multi-step-form__progress-step--completed');
            }
        }
    }

    const checkValidity = async (obj, content) => {
        var bSuccess = true;

        // Loop through all the inputs that have the 'required' attribute
        Array.from(obj.querySelectorAll('[required]')).forEach(input => {
            // If the required input has not been completed - OR - the input has the class 'is-invalid' then start set success to failed
            if (input.value.length === 0 || input.classList.contains('is-invalid') || (input.id === 'addressCountry' && input.value === '-1')) {
                bSuccess = false;
                // If the input has not got the is-invalid class - so it has not yet been verified by a user clicking in the input, then process the validation
                if (!input.classList.contains('is-invalid')) {

                    document.getElementById(input.id).classList.add("is-invalid");

                    getResourceString('REG_VALIDATION_default').then(data => {
                        document.getElementById(`${input.name}_LABEL`).innerText = data;
                    })
                }
            }
        })

        // Loop through all the inputs that have the 'patter' attribute
        Array.from(obj.querySelectorAll('[pattern]')).forEach(input => {

            // If the input has not fulfilled the regex requirement - OR - the input has the class 'is-invalid' then start set success to failed
            if (input.validationMessage !== '' && input.classList.contains('is-invalid')) {
                bSuccess = false;

                // If the input has not got the is-invalid class - so it has not yet been verified by a user clicking in the input, then process the validation
                if (!input.classList.contains('is-invalid')) {
                    document.getElementById(input.id).classList.remove("is-valid");  // Remove green check
                    document.getElementById(input.id).classList.add("is-invalid");   // Add red X

                    getResourceString('VALIDATION_PatterDefault').then(data => {
                        document.getElementById(`${input.name}_LABEL`).innerText = data;
                    })
                }
            }
        })

        if (content === 'contact') {
            const contactCheck = await handleRegistrationValidationCheck(bSuccess, true, false, regDetails.tempSupplierID);
            bSuccess = bSuccess && contactCheck

            let contactEmail = document.getElementById('contactEmailAddress')
            if (contactEmail !== null) {
                await handleCheckRegistrationExists(contactEmail.id, contactEmail.value, regDetails.tempSupplierID)
                    .then(data => {
                        if (data && data.uniqueID === 2) {
                            data.objData.contactPassword = '';

                            const password = document.getElementById('contactPassword');
                            if (password) {
                                password.value = '';
                                password.classList.remove("is-invalid");
                                password.classList.remove("is-valid");
                            }

                            setRegDetails(data.objData)
                            return true;
                        }
                    })
            } else {
                showCustomError(`Something went wrong checking email validation.  Please re-enter your password and try again.`, 'Problem Validating Email', 'error')
            }
        } else if (content === 'company') {
            const companyCheck = await handleRegistrationValidationCheck(bSuccess, false, true, regDetails.tempSupplierID);
            bSuccess = bSuccess && companyCheck
        }

        return bSuccess;
    }

    const handleNextClick = async (e, content) => {
        const panel = e.target.closest('.c-multi-step-form__panel');
        if (!panel) {
            // If panel is undefined then user has selected from progress bar.
            // Find index and move on.
            changePanel(Array.from(e.target.closest('ol').children).indexOf(e.target.closest('li')));
        }
        else {
            // Check the inputs on the current view are valid before changing view
            const check = await checkValidity(panel, content)
            if (check) {
                // If valid, change view
                changePanel(Array.from(panel.parentElement.children).indexOf(panel) + 1);
            }
        }
        // Scroll to top of form
        multiStepFormNav.scrollIntoView();
    }

    if (isPageEnabled === true && packages.isEnabled !== null) {
        return (
            <>
                <NavMenu />

                <Container className='main-content u-container' fluid>
                    {!regComplete &&
                        <>
                            <div className="u-flex-center u-component-spacing">
                                <h2 className="u-component-spacing u-branded-underline u-inverted-text">Register your company</h2>
                            </div>

                            <div className="card main-content__inner u-component-spacing--3x">
                                <div className="card-body">
                                    <div className="c-card card u-component-spacing">
                                        <div className="card-body">

                                            <div className="c-multi-step-form">
                                                <nav aria-label="Steps of your registration">
                                                    <ol className="c-multi-step-form__progress-bar js-progress-bar">
                                                        <li aria-current="step">
                                                        <button className="c-multi-step-form__progress-step c-multi-step-form__progress-step--current js-change-panel" onClick={(e) => handleNextClick(e)}>
                                                                <span className="js-progress-message u-visually-hidden">Current: </span>User Details
                                                            </button>
                                                        </li>
                                                        <li>
                                                        <button disabled={true} className="c-multi-step-form__progress-step js-change-panel" onClick={(e) => handleNextClick(e)}>
                                                                <span className="js-progress-message u-visually-hidden"></span>Company Details
                                                            </button>
                                                        </li>
                                                        {packages && packages.isEnabled === true &&
                                                            <li>
                                                            <button disabled={true} className="c-multi-step-form__progress-step js-change-panel" onClick={(e) => handleNextClick(e)}>
                                                                    <span className="js-progress-message u-visually-hidden"></span>Packages
                                                                </button>
                                                            </li>
                                                        }
                                                        <li>
                                                        <button disabled={true} className="c-multi-step-form__progress-step js-change-panel" onClick={(e) => handleNextClick(e)}>
                                                                <span className="js-progress-message u-visually-hidden"></span>Summary
                                                            </button>
                                                        </li>
                                                    </ol>
                                                </nav>

                                                <div className="c-multi-step-form__panel-container u-component-spacing">

                                                    <CompanyUserDetails
                                                        regDetails={regDetails}
                                                        setRegDetails={setRegDetails}
                                                        handleNextClick={handleNextClick}
                                                    />

                                                    <CompanyDetails
                                                        regDetails={regDetails}
                                                        setRegDetails={setRegDetails}
                                                        packages={packages}
                                                        countries={countries}
                                                        handleNextClick={handleNextClick}
                                                    />

                                                    {packages.isEnabled === true &&
                                                        <PackageSelection
                                                            regDetails={regDetails}
                                                            setRegDetails={setRegDetails}
                                                            services={services}
                                                            availablePackages={packages.packageList}
                                                            setPriceExcVat={setPriceExcVat}
                                                            handleNextClick={handleNextClick}
                                                        />
                                                    }

                                                    <Summary
                                                        regDetails={regDetails}
                                                        setRegDetails={setRegDetails}
                                                        setRegComplete={setRegComplete}
                                                        priceExcVat={priceExcVat}
                                                        packagesEnabled={packages.isEnabled}
                                                        termsURL={urlTC}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </>
                    }
                    {regComplete && priceExcVat === 0 &&
                        <>
                            <div className="u-flex-center u-component-spacing" style={{ color: "rgb(255, 255, 255)" }} >
                                <h2 className="u-component-spacing u-branded-underline u-inverted-text">Registration Pending</h2>
                            </div>
                            <div className="card main-content__inner u-component-spacing--3x">
                                <div className="card-body">
                                    <div className="c-card card u-component-spacing">
                                        <div className="card-body">
                                            <h3 className="c-broken-underline u-component-spacing">Email Verification Required</h3>
                                        <p className="u-component-spacing"><span>Thank you for registering your company with</span> <span><Translation ID='Sell2Name' /></span>.</p>
                                            <p>An email has been sent to verify the email address and complete the registration.</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </>
                    }
                    {regComplete && priceExcVat > 0 &&
                        <PaymentDetails
                            countries={countries}
                            address1={regDetails.addressLine1}
                            address2={regDetails.addressLine2}
                            city={regDetails.addressCity}
                            country={regDetails.addressCountry}
                            postcode={regDetails.addressPostCode}
                            companyEmail={regDetails.companyEmail}
                            regDetails={regDetails}
                            registrationID={regDetails.tempSupplierID}
                            priceExcVat={priceExcVat}
                        />
                    }
                </Container>

                <Footer />
            </>
        );
    } else {
        return null;
    }
}

export default Register;