import "datatables.net-bs4/css/dataTables.bootstrap4.min.css";
import React, { useCallback, useEffect, useState } from "react";
import { Card, Col, Row, Table, Tooltip } from 'reactstrap';
import { authHeader } from "../../helpers/auth-header";
import { getContactID, getSupplierID } from "../../helpers/general";
import Translation from "../controls/Translation";
import ConfirmedBasketItemsModal from "../Payment/ConfirmedBasketItems_Modal";
import PaymentDetailsModal from "../Payment/PaymentDetails_Modal";

const Basket = () => {
    const [user, setUser] = useState({ supplierID: -1, contactID: -1 });
    const [basket, setBasket] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [showModal, setShowModal] = useState(false);
    const [purchasePermission, setPurchasePermission] = useState(false);
    const [tooltipOpen, setTooltipOpen] = useState(false);
    const [previousTotals, setPreviousTotals] = useState(null);
    const [modalSelect, setModalSelect] = useState(0);
    const [finalBasket, setFinalBasket] = useState([]);

    const toggle = () => { setShowModal(!showModal); }

    const toggleTooltip = () => setTooltipOpen(!tooltipOpen);

    useEffect(() => {
        setUser((prev) => ({
            ...prev,
            supplierID: getSupplierID(),
            contactID: getContactID(),
        }));

        return () => {
            setUser({ supplierID: -1, contactID: -1 });
        }
    }, []);


    const getBasketInfo = useCallback(() => {
        if (user.supplierID > 0 && user.contactID > 0) {
            fetch(`./Payment/GetBasketInfo?supplierID=${user.supplierID}&contactID=${user.contactID}`, {
                method: "GET",
                headers: authHeader(false, false),
            })
                .then((response) => response.json())
                .then((data) => {
                    setBasket(data);
                })
                .catch((err) => {
                    console.log("error");
                });
        }
    }, [user.contactID, user.supplierID])

    useEffect(() => {
        if (user.supplierID > 0) {
            fetch(`./Permissions/CanPurchaseProducts?contactID=${user.contactID}`, {
                method: 'GET',
                headers: authHeader()
            })
                .then(response => response.json())
                .then(data => {
                    if (data) {
                        setPurchasePermission(data);
                    }
                })
                .catch(error => console.log('Error: ', error));

            getBasketInfo();
        }
    }, [getBasketInfo, user.contactID, user.supplierID]);

    const handleProceedToPay = () => {
        setIsLoading(true)
        setModalSelect(1)
        toggle();
    }

    const handleDeleteBasketItem = (basketItemID) => {
        if (basketItemID && basketItemID > 0) {
            let removeData = {
                "BasketItemID": basketItemID,
                "SupplierID": user.supplierID,
                "ContactID": user.contactID
            };

            fetch(`./Payment/RemoveBasketItem`, {
                method: 'POST',
                headers: authHeader(false, false),
                body: JSON.stringify(removeData)
            })
                .then(response => response.json())
                .then(data => {
                    if (data) {
                        getBasketInfo();
                    }
                })
                .catch(err => {
                    console.log("ERROR", err);
                })
        }
    }

    const handleBasketItemChange = (e) => {
        e.persist();
        let checked = e.target.checked;

        let selectedID = Number(e.target.id.replace('bi_', ''));
        let updateBasketItems = basket.basketItems;
        var index = updateBasketItems.findIndex(x => x.basketItemID === selectedID);

        let basketItemDetails = updateBasketItems[index];

        let updateSubTotal = checked ? basket.subTotal + basketItemDetails.priceExcVat : basket.subTotal - basketItemDetails.priceExcVat;
        let updateTax = checked ? basket.totalTax + basketItemDetails.vat : basket.totalTax - basketItemDetails.vat;
        let updateTotal = checked ? basket.priceToCharge + basketItemDetails.totalPrice : basket.priceToCharge - basketItemDetails.totalPrice;

        setBasket({
            basketItems: updateBasketItems,
            discountItem: null,
            discountType: null,
            subTotal: updateSubTotal,
            vatRate: basket.vatRate,
            totalTax: updateTax,
            priceToCharge: updateTotal
        });

        updateBasketItems[index]["selected"] = e.target.checked;
    }

    useEffect(() => {
        if (basket && basket.basketItems) {
            let updateBasketItems = basket.basketItems;
            let selectedBasketItems = updateBasketItems.filter(x => x.selected === true);
            let tmpBasketItems = { renewal: false, basketItems: selectedBasketItems };
            let selectedSubTotal = 0;

            if (selectedBasketItems.length > 0) {
                let tmpSelected = selectedBasketItems.filter(x => x.refund === false);

                if (tmpSelected.length > 0) {
                    selectedSubTotal = tmpSelected
                        .map(item => item.priceExcVat)
                        .reduce((total, price) => total + price, 0);
                }
            }

            setFinalBasket(value => ({
                ...value,
                basketItemsInfo: tmpBasketItems,
                selectedSubTotal: selectedSubTotal,
                discountTotal: null,
                subTotal: basket.subTotal,
                vatRate: basket.vatRate,
                totalTax: basket.totalTax,
                priceToCharge: basket.priceToCharge
            }));
        }
    }, [basket])

    if (Object.keys(basket).length > 0) {
        return (
            <>
                <main>
                    <div className="padding-l-25 padding-r-20 card main-content__inner">
                        <Card body>
                            <h3 className="c-broken-underline u-component-spacing">
                                Basket Items
                            </h3>
                            <Table id={`BasketItemsTable`} size="sm" width="100%" className="c-invoice-modal__invoice-table">
                                <thead>
                                    <tr>
                                        <th className="c-invoice-modal__table-heading">Description</th>
                                        <th className="c-invoice-modal__table-heading"></th>
                                        <th className="c-invoice-modal__table-heading c-invoice-modal__center-text">Quantity</th>
                                        <th className="c-invoice-modal__table-heading" style={{ width: "16%" }}>Unit Price</th>
                                        <th className="c-invoice-modal__table-heading" style={{ width: "16%" }}>Price (exc vat)</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {basket.basketItems.map((basketItem, i) => {
                                        return (
                                            <tr key={i} className="c-invoice-modal__table-row">
                                                <td>
                                                    {basketItem.packageServiceID > 0 &&
                                                        <>
                                                            <input id={`bi_${basketItem.basketItemID}`} name={`bi_${basketItem.basketItemID}`} type="checkbox" className="custom-control-input" checked={basketItem.selected} onChange={(e) => handleBasketItemChange(e)} />
                                                            <label htmlFor={`bi_${basketItem.basketItemID}`} className='c-checkbox-label'>{basketItem.packageServiceDescription}</label>
                                                        </>
                                                    }
                                                    {basketItem.packageServiceID < 0 &&
                                                        <span>
                                                        <label style={{ marginLeft: "28px" }}>{basketItem.packageServiceDescription}</label>
                                                            {basketItem.refund === true && <span style={{color:'red'}}> *</span>}
                                                        </span>
                                                    }
                                                </td>
                                                <td>
                                                    {(basketItem.packageServiceID > 0 || (basketItem.packageServiceID < 0 && basketItem.refund === false)) &&
                                                        <button id={`delete-${basket.basketItemID}`} className="c-tender-actions__action-button c-tender-actions__action-button--bin" onClick={() => { handleDeleteBasketItem(basketItem.basketItemID) }}>Remove</button>
                                                    }
                                                </td>
                                                <td className="c-invoice-modal__center-text">{basketItem.quantity}</td>
                                                <td>&pound;{basketItem.unitPriceExcVat.toLocaleString('en', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</td>
                                                <td>&pound;{basketItem.priceExcVat.toLocaleString('en', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</td>
                                            </tr>
                                        )
                                    })}
                                    <tr>
                                        <td style={{ color: 'red', fontSize: '0.8em' }}>
                                            {basket.basketItems.some(x => x.package === true) && <Translation ID='BasketUpgradeNote1' /> }
                                        </td>
                                        <td></td>
                                        <td></td>
                                        <td className="c-invoice-modal__table-totalmary-term">Subtotal</td>
                                        <td className="c-invoice-modal__table-summary-value">&pound;{basket.subTotal.toLocaleString('en', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</td>
                                    </tr>
                                    <tr>
                                        <td style={{ border: "0px" }}></td>
                                        <td style={{ border: "0px" }}></td>
                                        <td style={{ border: "0px" }}></td>
                                        <td className="c-invoice-modal__table-summary-term" style={{ border: "0" }}>VAT ({basket.vatRate}%)</td>
                                        <td className="c-invoice-modal__table-summary-value" style={{ border: "0" }}>&pound;{basket.totalTax.toLocaleString('en', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</td>
                                    </tr>
                                    <tr>
                                        <td style={{ border: "0px" }}></td>
                                        <td style={{ border: "0px" }}></td>
                                        <td style={{ border: "0px" }}></td>
                                        <td className="c-invoice-modal__table-summary-term" style={{ paddingTop: "1.5rem", paddingBottom: "1rem", border: "0", fontWeight: "bold" }}>Total ({basket.vatRate}%)</td>
                                        <td className="c-invoice-modal__table-summary-value" style={{ paddingTop: "1.5rem", paddingBottom: "1rem", border: "0", fontWeight: "bold" }}>&pound;{basket.priceToCharge.toLocaleString('en', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</td>
                                    </tr>
                                </tbody>
                            </Table>
                            <hr />
                            {basket.priceToCharge > 0 &&
                                <Row style={{ marginTop: '20px' }}>
                                    <Col md={12} className='c-proceed-to-pay'>
                                        <button id="purchaseButton" className="c-cta" disabled={!purchasePermission} onClick={() => handleProceedToPay()}><Translation ID="ProceedToPay" /></button>
                                        {!purchasePermission &&
                                            <Tooltip
                                                placement="top"
                                                isOpen={tooltipOpen}
                                                target={`purchaseButton`}
                                                toggle={toggleTooltip}
                                            >
                                                You do not have the right permissions to purchase products and services.
                                            </Tooltip>
                                        }
                                    </Col>
                                </Row>
                            }

                        </Card>
                    </div>
                </main>

                {modalSelect === 1 &&
                    <ConfirmedBasketItemsModal
                        isOpen={modalSelect === 1}
                        toggle={toggle}
                        purchasedSubTotal={basket.subTotal}
                        previousTotals={previousTotals}
                        setPreviousTotals={setPreviousTotals}
                        finalBasket={finalBasket}
                        setFinalBasket={setFinalBasket}
                        setModalSelect={setModalSelect}
                        user={user}
                    />
                }

                {modalSelect === 2 &&
                    <PaymentDetailsModal
                        isOpen={modalSelect === 2}
                        toggle={toggle}
                        isLoading={isLoading}
                        setIsLoading={setIsLoading}
                        priceExcVat={finalBasket.subTotal}
                        basketItems={finalBasket.basketItemsInfo}
                        setModalSelect={setModalSelect}
                    />
                }
            </>
        );
    } else {
        return null;
    }
};

export default Basket;
