import React, { useEffect, useState } from "react";
import "bootstrap/dist/css/bootstrap.css";
import { PropTypes } from 'prop-types';
import { useCallback } from "react";
import { Input } from "reactstrap";
import { authHeader } from "../../helpers/auth-header";
import { getApplicationType, getContactID, getFilters, goTo, handleAlertWithInput, handleTimedSuccess, showError } from '../../helpers/general';
import Select2 from "../controls/Select2";
import Translation from '../controls/Translation';

const moment = require('moment');

require('datatables.net-bs4');

const TenderSearch = props => {
    let { setIsSearching, searchParams, setSearchParams, searchID, setSaveSearchTitle, saveSearchTitle, supplierID, setPageNumber } = props;

    const [comOrderBy, setComOrderBy] = useState([]);
    const [comOrderByDirection, setComOrderByDirection] = useState([]);
    const [institutions, setInstitutions] = useState([]);
    const [regions, setRegions] = useState([]);
    const [allowAddSearch, setAllowAddSearch] = useState();
    const [packagesEnabled, setPackagesEnabled] = useState(false);
    const [canPurchaseAddons, setCanPurchaseAddons] = useState(null);
    const [searchPermissions, setSearchPermissions] = useState({ canCreateSearch: false, canUpdateSearch: false, canPurchaseProducts: false });
    const [systemName, setSystemName] = useState('');
    const [pageConfig, setPageConfig] = useState(null);

    useEffect(() => {
        getApplicationType()
            .then(application => {
                setSystemName(application);

                fetch(`./Settings/ArePackagesEnabled`, {
                    method: 'GET',
                    headers: authHeader(false, true)
                })
                    .then(response => response.json())
                    .then(packagesEnabledData => {
                        setPackagesEnabled(packagesEnabledData)
                    });
            });
    }, []);

        useEffect(() => {
            fetch(`./Packages/GetTendererPackagePageConfig?supplierID=${supplierID}&pageConfig=TenderSearchConfig`, {
            method: 'GET',
            headers: authHeader()
        })
            .then(response => response.json())
            .then(data => {
                setPageConfig(data);
            });
    }, [supplierID]);


    const getConfig = useCallback((field, option, optionDefault) => {
        if (pageConfig !== null && pageConfig !== undefined) {
            if (pageConfig.length > 0) {
                let config = pageConfig.find(x => x.field === field);

                if (config && config !== undefined && config.options) {
                    return config.options[option]
                }
            } else {
                return optionDefault;
            }
        } else {
            return null;
        }
    }, [pageConfig])


    useEffect(() => {
        if (systemName !== '' && pageConfig !== null) {
            var url =
                systemName === 'Sell2UK'
                    ? `./Organisations/GetOrganisations?supplierID=${supplierID}&intendOnly=true&eujournalOnly=true&contractsFinderOnly=true&ftsOnly=true`
                    : `./Organisations/GetOrganisations?supplierID=${supplierID}&intendOnly=false&eujournalOnly=false&contractsFinderOnly=false&ftsOnly=false`;

            fetch(url, {
                method: 'GET',
                headers: authHeader()
            })
                .then(response => response.json())
                .then(data => {
                    let optionCount = getConfig('organisationid', 'maxOptions', -1);
                    let vOrganisations = [];

                    if (optionCount === -1) { 
                        // No limitation
                        vOrganisations = data;
                    } else if (optionCount === 0) { 
                        // Only the ALL option (replaces text with default text, and value is 0)
                        vOrganisations.push({ label: getConfig('organisationid', 'defaultText'), value: 0 });
                    } else {
                        // Limited to the max number for that package
                        vOrganisations = data.slice(0, optionCount);
                    }

                    setInstitutions(vOrganisations);
                })
                .catch(err => {
                    console.log(err);
                });

                // Worked to here
            fetch(`./Countries/GetTopLevelRegions?country=uk`, {
                method: 'GET',
                headers: authHeader(false, true)
            })
                .then(response => response.json())
                .then(data => {
                    let optionCount = getConfig('region', 'maxOptions', -1);
                    let vRegions = [];

                    if (optionCount === -1) {
                        vRegions.push({ label: "Show All", value: "" });
                        data.map(obj => vRegions.push({ label: obj.region, value: obj.code }));
                    } else {
                        vRegions.push({ label: getConfig('region', 'defaultText'), value: "" });
                    }

                    setRegions(vRegions);
                })
                .catch(error => console.log('Error: ', error));


            getFilters(orderByOptions, false).then(arrComFilters => {
                if (getConfig('orderBy', 'maxOptions', -1) === -1) {
                    setComOrderBy(arrComFilters);
                } else {
                    let vOrderBy = [];
                    vOrderBy.push({ label: getConfig('orderBy', 'defaultText'), value: "0" });
                    setComOrderBy(vOrderBy);
                }
            });

            getFilters(orderByDirectionOptions, false).then(arrComFilters => {
                if (getConfig('orderByDirection', 'maxOptions', -1) === -1) {
                    setComOrderByDirection(arrComFilters);
                } else {
                    let vOrderByDir = [];
                    vOrderByDir.push({ label: getConfig('orderByDirection', 'defaultText'), value: "0" });
                    setComOrderByDirection(vOrderByDir);
                }
            });

            fetch(`./Permissions/PermissionsTenderSearchOptions?contactID=${getContactID()}`, {
                method: 'GET',
                headers: authHeader()
            })
                .then(response => response.json())
                .then(data => {
                    if (data) {
                        setSearchPermissions({
                            canCreateSearch: data.canCreateSearch,
                            canUpdateSearch: data.canUpdateSearch,
                            canPurchaseProducts: data.canPurchaseProducts
                        });
                    }
                })
                .catch(error => console.log('Error: ', error));

            if (searchID || packagesEnabled === false) {
                setAllowAddSearch(true);
            } else {

                fetch(`./Packages/PackageAllowAddonsBySupplierID?supplierID=${supplierID}`, {
                    method: 'GET',
                    headers: authHeader(false, false)
                })
                    .then(response => response.json())
                    .then(PackageAllowAddonsData => {
                        setCanPurchaseAddons(PackageAllowAddonsData);

                        if (PackageAllowAddonsData) {
                            // Check to see if the supplier can add more users based on their current allowance
                            fetch(`./Suppliers/SupplierAllowSaveSearches?supplierID=${supplierID}&systemName=${systemName}`, {
                                method: 'GET',
                                headers: authHeader(false, false)
                            })
                                .then(response => response.json())
                                .then(AllowanceCheckData => {
                                    setAllowAddSearch(AllowanceCheckData);
                                })
                                .catch(error => {
                                    console.log('Error: ', error);
                                });
                        }
                    })
                    .catch(err => {
                        console.log(err);
                    });
            }
        }
    }, [getConfig, packagesEnabled, pageConfig, searchID, supplierID, systemName]);


    const handleChange = e => {
        e.persist();
        let val = e.target.value;

        setSearchParams(prevState => ({
            ...prevState,
            searchObject: {
                ...prevState.searchObject,
                [e.target.name]: e.target.type === 'checkbox' ? e.target.checked : encodeURIComponent(val)
            }
        }));
    }


    const handleSelect2Change = e => {
        e.persist();

        setSearchParams(prevState => ({
            ...prevState,
            searchObject: {
                ...prevState.searchObject,
                [e.target.id]: e.target.value
            }
        }));
    }


    const handleSaveSearch = async () => {
        if (searchID) {
            let searchTitle = await handleAlertWithInput('Update Search', '', 'You must enter a descriptive title for your search criteria.', saveSearchTitle);

            if (searchTitle && searchTitle.length > 0) {
                fetch(`./Suppliers/UpdateSupplierSaveSearch?supplierID=${supplierID}&searchID=${searchID}&searchTitle=${searchTitle}`, {
                    method: 'POST',
                    headers: authHeader(),
                    body: JSON.stringify(searchParams.searchObject)
                })
                    .then(response => response.json())
                    .then(data => {
                        if (data.success === true) {
                            setSaveSearchTitle(searchTitle);
                            handleTimedSuccess('success', 'Saved', `<strong>'${searchTitle}'</strong> has been updated.`);
                        } else {
                            console.log("Error");
                            showError(data.error, data.message);
                        }
                    })
                    .catch(err => {
                        console.log("Error");
                    });
            }
        } else {
            let searchTitle = await handleAlertWithInput('Save Your Search', 'Give your search a title:', 'You must enter a descriptive title for your search criteria.');

            if (searchTitle && searchTitle.length > 0) {

                fetch(`./Suppliers/SaveSupplierTenderSearch?systemName=${systemName}&supplierID=${supplierID}&description=${searchTitle}`, {
                    method: 'POST',
                    headers: authHeader(false, false),
                    body: JSON.stringify(searchParams.searchObject)
                })
                    .then(response => response.json())
                    .then(data => {
                        if (data.success === true) {
                            handleTimedSuccess('success', 'Saved', `Your search criteria has been updated as ${searchTitle}.`)

                            if (packagesEnabled) {
                                // Check to see if the supplier can add more users based on their current allowance
                                fetch(`./Suppliers/SupplierAllowSaveSearches?supplierID=${supplierID}&systemName=${systemName}`, {
                                    method: 'GET',
                                    headers: authHeader(false, false)
                                })
                                    .then(response => response.json())
                                    .then(data => {
                                        setAllowAddSearch(data);
                                    })
                                    .catch(error => {
                                        console.log('Error: ', error);
                                    });
                            }
                        } else {
                            showError(data.error, data.message);
                        }
                    })
                    .catch(err => {
                        console.log("Error::", err);
                    });
            }
        }
    }

    const handleSearchClick = () => {
        setPageNumber(1);
        setIsSearching(true)
    }

    const handleEnterKey = (e) => {
        if (e.key === 'Enter') {
            handleSearchClick();
        }
    }

    if (searchParams && searchParams.searchObject && pageConfig !== null) {
        return (
            <section className="c-search-section u-box-shadow">
                <div className="u-container">
                    {getConfig('upgradeBanner', 'disabled', false) === false && packagesEnabled === true &&
                        <div className="c-system-notifications c-system-notifications--important">
                            <h2 className="c-system-notifications__title">{getConfig('upgradeBanner', 'defaultText', '')}</h2>
                        </div>
                    }
                    {saveSearchTitle && saveSearchTitle.length > 0 &&
                        <div>
                            <label className="c-search-section__label">
                                <h2 className="c-page-title c-broken-underline" dangerouslySetInnerHTML={{ __html: saveSearchTitle }} />
                            </label>
                        </div>
                    }
                    <div className="c-search-section__inner u-component-spacing">
                        <div className="c-search-section__keyword">
                            <label className="c-search-section__label" htmlFor="txtSearchKeywords">
                                <Translation ID="Keyword" required={true} />
                            </label>
                            <Input name="keywords" className="c-form__input" type="text" defaultValue={searchParams.searchObject.keywords} onChange={(e) => handleChange(e)} onKeyUp={(e) => handleEnterKey(e)} placeholder="Please enter a search term" id="txtSearchKeywords" />
                            <span style={{ fontSize: "1em", fontStyle: "italic", float: "right" }}>(required)</span>

                            <div className="u-component-spacing--3x">
                                <div className="custom-checkbox custom-control">
                                    <input id="includeEOIPassed" name="includeEOIPassed" type="checkbox" className="custom-control-input" checked={searchParams.searchObject.includeEOIPassed || false} onChange={(e) => handleChange(e)} />
                                    <Translation ID="ShowDeadlinePassed" StringOnly={false} required={false} customClass='c-search-section__label c-checkbox-label' htmlFor='includeEOIPassed' />
                                </div>
                                <div className="custom-checkbox custom-control u-component-spacing">
                                    <input id="includeIgnored" name="includeIgnored" type="checkbox" className="custom-control-input" checked={searchParams.searchObject.includeIgnored || false} onChange={(e) => handleChange(e)} />
                                    <Translation ID="ShowHidden" StringOnly={false} required={false} customClass='c-search-section__label c-checkbox-label' htmlFor='includeIgnored' />
                                </div>
                            </div>
                        </div>

                        {regions.length > 0 &&
                            <div>
                                <label className="c-search-section__label" htmlFor="region">
                                    <Translation ID="Region" />
                                </label>
                                <Select2
                                    id='region'
                                    options={regions}
                                    disabled={getConfig('region', 'disabled', false)}
                                    change={(e) => handleSelect2Change(e)}
                                    defaultValue={searchParams.searchObject.region}
                                />
                            </div>
                        }

                        <div>
                            <fieldset className="c-search-section__datepicker-group">
                                <legend className="c-search-section__label">
                                    <Translation ID="DatePublished" />
                                </legend>
                                <div>
                                    <label className="c-search-section__sub-label" htmlFor="dateAddedFrom">
                                        <Translation ID="From" />
                                    </label>
                                    <input
                                        id="dateAddedFrom"
                                        className="c-search-section__datepicker"
                                        type="date"
                                        autoComplete="false"
                                        defaultValue={searchParams.searchObject.dateAddedFrom}
                                        onChange={(e) => handleChange(e)}
                                        onBlur={(e) => handleChange(e)}
                                        name="dateAddedFrom"
                                        max={moment().format('YYYY-MM-DD')}
                                        disabled={getConfig('dateAddedFrom', 'disabled', false)}
                                    />
                                </div>
                                <div>
                                    <label className="c-search-section__sub-label" htmlFor="dateAddedTo">
                                        <Translation ID="To" />
                                    </label>
                                    <input
                                        id="dateAddedTo"
                                        className="c-search-section__datepicker"
                                        type="date"
                                        autoComplete="false"
                                        defaultValue={searchParams.searchObject.dateAddedTo}
                                        onChange={(e) => handleChange(e)}
                                        onBlur={(e) => handleChange(e)}
                                        name="dateAddedTo"
                                        max={moment().format('YYYY-MM-DD')}
                                        disabled={getConfig('dateAddedTo', 'disabled', false)}
                                    />
                                </div>
                            </fieldset>
                        </div>


                        {comOrderBy.length > 0 &&
                            <div>
                                <label className="c-search-section__label" htmlFor="orderBy">
                                    <Translation ID="OrderBy" />
                                </label>
                                <Select2
                                    id='orderBy'
                                    options={comOrderBy}
                                    defaultValue={parseInt(searchParams.searchObject.orderBy) > 0 ? parseInt(searchParams.searchObject.orderBy) : 0}
                                    change={(e) => handleSelect2Change(e)}
                                    minimumResultsForSearch='Infinity'
                                    disabled={getConfig('orderBy', 'disabled', false)}
                                />
                            </div>
                        }

                        {institutions && institutions.length > 0 &&
                            <div>
                                <label className="c-search-section__label" htmlFor="organisationid">
                                    <Translation ID="Organisations" />
                                </label>
                                <Select2
                                    id='organisationid'
                                    search={true}
                                    options={institutions}
                                    change={(e) => handleSelect2Change(e)}
                                    defaultValue={parseInt(searchParams.searchObject.organisationid) > 0 ? parseInt(searchParams.searchObject.organisationid) : 0}
                                    disabled={getConfig('organisationid', 'disabled', false)}
                                />
                            </div>
                        }

                        <fieldset className="c-search-section__datepicker-group">
                            <legend className="c-search-section__label">Expression of Interest</legend>
                            <div>
                                <label className="c-search-section__sub-label" htmlFor="dateEOIFrom">
                                    <Translation ID="From" />
                                </label>
                                <input
                                    id="dateEOIFrom"
                                    className="c-search-section__datepicker"
                                    type="date"
                                    autoComplete="false"
                                    defaultValue={searchParams.searchObject.dateEOIFrom}
                                    onChange={(e) => handleChange(e)}
                                    onBlur={(e) => handleChange(e)}
                                    name="dateEOIFrom"
                                    disabled={getConfig('dateEOIFrom', 'disabled', false)}
                                />
                            </div>
                            <div>
                                <label className="c-search-section__sub-label" htmlFor="dateEOITo">
                                    <Translation ID="To" />
                                </label>
                                <input
                                    id="dateEOITo"
                                    className="c-search-section__datepicker"
                                    type="date"
                                    autoComplete="false"
                                    defaultValue={searchParams.searchObject.dateEOITo}
                                    onChange={(e) => handleChange(e)}
                                    onBlur={(e) => handleChange(e)}
                                    name="dateEOITo"
                                    disabled={getConfig('dateEOITo', 'disabled', false)}
                                />
                            </div>
                        </fieldset>

                        {comOrderByDirection.length > 0 &&
                            <div>
                                <label className="c-search-section__label" htmlFor="orderByDirection">
                                    <Translation ID="OrderDirection" />
                                </label>
                                <Select2
                                    id='orderByDirection'
                                    options={comOrderByDirection}
                                    defaultValue={parseInt(searchParams.searchObject.orderByDirection) > 0 ? parseInt(searchParams.searchObject.orderByDirection) : 0}
                                    change={(e) => handleSelect2Change(e)}
                                    disabled={getConfig('orderByDirection', 'disabled', false)}
                                />
                            </div>
                        }

                    </div>

                    <div className="c-search-section__submit-buttons u-component-spacing--2x">
                        {((searchID && searchPermissions.canUpdateSearch) || (!searchID && searchPermissions.canCreateSearch)) &&
                            <>
                                {(packagesEnabled === false || (allowAddSearch === true && canPurchaseAddons === true)) && !searchID && searchPermissions.canCreateSearch &&
                                    <button className="js-save-search c-search-section__save-search" data-saved-search="false" onClick={handleSaveSearch}>
                                        <svg className="c-search-section__save-search-icon" xmlns="http://www.w3.org/2000/svg" fillRule="evenodd" strokeLinejoin="round" strokeMiterlimit="2" clipRule="evenodd" viewBox="0 0 54 50" height="1.2em">
                                            <path className="c-search-section__save-search-icon-fill" fill="#34cb00" d="M3.25 23.7A14.55 14.55 0 1 1 27 7.04 14.54 14.54 0 1 1 50.75 23.7L27 50 3.25 23.7Z" />
                                            <path fill="#ccccccff" d="M3.25 23.7A14.55 14.55 0 1 1 27 7.04 14.54 14.54 0 1 1 50.75 23.7L27 50 3.25 23.7ZM6.42 21A10.38 10.38 0 1 1 23.43 9.2L27 15.1l3.57-5.9a10.38 10.38 0 1 1 17.02 11.79L27 43.79 6.42 20.98Z" />
                                        </svg>
                                        Save Search
                                    </button>
                                }

                                {searchID && searchPermissions.canUpdateSearch &&
                                    <button className="js-save-search c-search-section__save-search" data-saved-search="false" onClick={handleSaveSearch}>
                                        <svg className="c-search-section__save-search-icon" xmlns="http://www.w3.org/2000/svg" fillRule="evenodd" strokeLinejoin="round" strokeMiterlimit="2" clipRule="evenodd" viewBox="0 0 44 44" height="1.4em">
                                            <path fill="#ccc" d="M29.84 34.57c.96-.6 1.85-1.3 2.64-2.1l2.83 2.83a18.93 18.93 0 0 1-3.46 2.74l-2-3.46ZM28.53 35.3l.02.04 1.99 3.43a18.71 18.71 0 0 1-8.03 2.04v-4c2.15-.07 4.2-.6 6.02-1.5ZM21 40.8a18.69 18.69 0 0 1-7.97-2.25l2-3.47c1.8.96 3.83 1.57 5.98 1.7v4.01Z" />
                                            <path fill="#ccc" d="M36.79 21a14.83 14.83 0 1 0-23.04 13.3l-2 3.48A18.81 18.81 0 1 1 40.78 21h2.75l-4.77 7.57L34 21h2.79Z" />
                                            <path fill="#ccc" d="M20 11.07V23a2 2 0 0 0 .59 1.41l5.32 5.33a2 2 0 0 0 2.83-2.83L24 22.17v-11.1a2 2 0 0 0-4 0Z" />
                                        </svg>
                                        Update Search
                                    </button>
                                }
                            </>
                        }
                        {allowAddSearch === false && canPurchaseAddons &&
                            <button id='purchaseSaveSearches' className="c-tender-actions__action-button c-tender-actions__action-button--purchase" disabled={!searchPermissions.canPurchaseProducts} onClick={(() => goTo('/products'))}>
                                Purchase More Save Searches
                            </button>
                        }
                        {canPurchaseAddons === false &&
                            <button className="c-search-section__upgrade" onClick={(() => goTo('/upgrade'))}>
                                Upgrade to unlock more features
                            </button>
                        }
                        <input className="c-search-section__submit" type="submit" value={'Search'} onClick={() => handleSearchClick()} />
                    </div>
                </div>
            </section >
        )
    } else {
        return null;
    }
}

const orderByOptions = [
    { label: "LISTCUSTOM_ORDERBY_DATEADDED", value: 0 },
    { label: "LISTCUSTOM_ORDERBY_EOI", value: 1 }
]

const orderByDirectionOptions = [
    { label: "LISTCUSTOM_ORDERBYDIRECTION_DESC", value: 0 },
    { label: "LISTCUSTOM_ORDERBYDIRECTION_ASC", value: 1 }
]

TenderSearch.propTypes = {
    setSearchParams: PropTypes.func,
    setIsSearching: PropTypes.func,
    isSearching: PropTypes.bool,
    searchParams: PropTypes.object,
    searchID: PropTypes.string,
    saveSearchTitle: PropTypes.string
}

TenderSearch.defaultProps = {
    setSearchParams: () => { },
    setIsSearching: () => { },
    isSearching: true,
    searchParams: [],
    searchID: '',
    saveSearchTitle: ''
}

export default TenderSearch;