import React, { useEffect, useState } from 'react';
import { getApplicationType } from '../../helpers/general';

/**
 * The theme components only imports it's theme CSS-file. These components are lazy
 * loaded, to enable "code splitting" (in order to avoid the themes being bundled together)
 */
const BLPD = React.lazy(() => import('../themes/BLPD'));
const SEShared = React.lazy(() => import('../themes/SEShared'));
const Sell2UK = React.lazy(() => import('../themes/Sell2UK'));
const LiveDemo = React.lazy(() => import('../themes/LiveDemo'));
const Midlands = React.lazy(() => import('../themes/Midlands'));
const Housing = React.lazy(() => import('../themes/Housing'));
const NHS = React.lazy(() => import('../themes/NHS'));
const UN = React.lazy(() => import('../themes/UN'));
const Sykehusinnkjop = React.lazy(() => import('../themes/Sykehusinnkjop'));
const Universities = React.lazy(() => import('../themes/Universities'));
const Iceland = React.lazy(() => import('../themes/Iceland'));
const Pacific = React.lazy(() => import('../themes/Pacific'));
const Ireland = React.lazy(() => import('../themes/Ireland'));
const Caribbean = React.lazy(() => import('../themes/Caribbean'));

const StylesheetSelector = props => {
    let { children } = props;

    const [appName, setAppName] = useState('');

    useEffect(() => {
        getApplicationType().then(application => {
            setAppName(application.toUpperCase());
        });
    }, [])

    const Loading = () => <></>;

    if (appName.length) {
        return (
            <>
                <React.Suspense fallback={<Loading /> }>
                    {appName === 'SELL2BLPD' && <BLPD />}
                    {appName === 'SELL2SESHARED' && <SEShared />}
                    {appName === 'SELL2UK' && <Sell2UK />}
                    {appName === 'SELL2LIVEDEMO' && <LiveDemo />}
                    {appName === 'SELL2MIDLANDS' && <Midlands />}
                    {appName === 'SELL2HOUSING' && <Housing />}
                    {appName === 'SELL2NHS' && <NHS />}
                    {appName === 'SELL2UN' && <UN />}
                    {appName === 'SELL2SYKEHUSINNKJOP' && <Sykehusinnkjop />}
                    {appName === 'SELL2IRELAND' && <Ireland />}
                    {appName === 'SELL2UNIVERSITIES' && <Universities/>}
                    {appName === 'SELL2PACIFIC' && <Pacific />}
                    {appName === 'SELL2CARIBBEAN' && <Caribbean />}
                    {appName === 'SELL2ICELAND' && <Iceland />}
                    {children}
                </React.Suspense>
            </>
        );
    } else {
        return null;
    }
};

export default StylesheetSelector;