import "bootstrap/dist/css/bootstrap.css";
import React, { useEffect, useState } from "react";
import { authHeader } from "../../helpers/auth-header";
import { getApplicationType } from "../../helpers/general";
import Select2 from '../controls/Select2';

require('datatables.net-bs4');

const ContractFilters = (props) => {
    let { setFinalSearchParams, exportData, systemName, user, setIsSearching } = props;

    const [organisations, setOrganisations] = useState([]);
    const [customers, setCustomers] = useState([]);
    const [suppliers, setSuppliers] = useState([]);
    const [selectedOrganisation, setSelectedOrganisation] = useState(-1);
    const [searchParams, setSearchParams] = useState({
        includeArchived: 0,
        title: '',
        reference: '',
        supplier: '',
        organisation: 0,
        customer: '',
        dateExpiryFrom: '',
        dateExpiryTo: '',
        systemName: ''
    });
    const [canExport, setCanExport] = useState(null);

    useEffect(() => {
        setSearchParams(prev => ({
            ...prev,
            systemName: systemName
        }));

    }, [systemName]);

    useEffect(() => {
        fetch(`./Organisations/GetIntendPortalsList`, {
            method: 'GET',
            headers: authHeader(false, true)
        })
            .then(response => response.json())
            .then(data => {
                let vOrganisations = [];

                vOrganisations.push({ label: "All Organisations", value: "-1" });

                data.map(obj => vOrganisations.push({ label: obj.organisation, value: obj.uniqueID }));
                setOrganisations(vOrganisations);
            })
            .catch(err => {
                console.log(err);
            });

        fetch(`./Settings/GetWebSetting?settingname=allow_contract_export`, {
            method: 'GET',
            headers: authHeader(false, true)
        })
            .then(response => response.json())
            .then(canExportData => {
                setCanExport(canExportData)
            })
            .catch(err => {
                console.log(err);
            });

        return () => setOrganisations([]);
    }, [user.supplierID]);


    useEffect(() => {
        fetch(`./Contracts/GetContractCustomersByOrganisation?organisationID=${selectedOrganisation}`, {
            method: 'GET',
            headers: authHeader(false, true)
        })
            .then(response => response.json())
            .then(data => {
                let vCustomers = [];

                vCustomers.push({ label: "All Customers", value: "-1" });

                data.map(obj => vCustomers.push({ label: obj.customer.t, value: obj.customer.t }));
                setCustomers(vCustomers);
            })
            .catch(err => {
                console.log(err);
            });

        fetch(`./Contracts/GetContractSuppliers?organisationID=${selectedOrganisation}`, {
            method: 'GET',
            headers: authHeader(false, true)
        })
            .then(response => response.json())
            .then(data => {
                let vSuppliers = [];

                vSuppliers.push({ label: "All Suppliers", value: "" });

                data.map(obj => vSuppliers.push({ label: obj.supplier.t, value: obj.supplier.t }));
                setSuppliers(vSuppliers);
            })
            .catch(err => {
                console.log(err);
            });
    }, [selectedOrganisation]);

    const searchData = () => {
        setFinalSearchParams({ searchObject: searchParams })
        setIsSearching(true);
    }

    const isObject = (fld) => typeof fld === "object" && fld !== null

    const handleEnterKey = (e) => {
        if (e.key === 'Enter') {
            searchData();
        }
    }

    const handleOrganisationChange = (e) => {
        setSelectedOrganisation(e.target.value);
    }

    const handleChange = e => {
        e.persist();
        let val = e.target.value;

        setSearchParams(searchParams => ({
            ...searchParams,
            [e.target.name]: e.target.type === 'checkbox' ? e.target.checked : val
        }));
    }

    const handleSelect2Change = e => {
        e.persist();
        setSearchParams(searchParams => ({
            ...searchParams,
            [e.target.id]: e.target.value
        }));
    }

    const clearFilters = () => {
        getApplicationType()
            .then(application => {
                setSearchParams({
                    includeArchived: 0,
                    title: '',
                    reference: '',
                    organisation: 0,
                    customer: '',
                    dateExpiryFrom: '',
                    dateExpiryTo: '',
                    systemName: application
                });

                setFinalSearchParams({
                    searchObject: {
                        includeArchived: 0,
                        title: '',
                        reference: '',
                        organisation: 0,
                        customer: '',
                        dateExpiryFrom: '',
                        dateExpiryTo: '',
                        systemName: application
                    }
                })
            })

        document.getElementById('title').value = '';
        document.getElementById('reference').value = '';

        isObject(document.getElementById('supplier')) && (document.getElementById('supplier').value = '');
        isObject(document.getElementById('organisation')) && (document.getElementById('organisation').value = '-1');
        isObject(document.getElementById('customer')) && (document.getElementById('customer').value = '-1');

        document.getElementById('dateExpiryFrom').value = null;
        document.getElementById('dateExpiryTo').value = null;

        setSelectedOrganisation(-1);

    }

    return (
        <section className="c-search-section u-box-shadow">
            <div className="u-flex-center">
                <h2 className="u-component-spacing u-branded-underline">Public Contract Register</h2>
            </div>
            <div className="u-container u-component-spacing--2x">
                <div className="c-search-section__inner c-search-section__inner--v2">
                    <div>
                        <div>
                            <label className="c-search-section__label" htmlFor="title">Contract Title</label>
                            <input name='title' id='title' type='text' className='c-form__input' onChange={(e) => handleChange(e)} onKeyUp={(e) => handleEnterKey(e)} />
                        </div>
                        <span className="c-text-separator u-component-spacing">or</span>
                        <div className="u-component-spacing">
                            <label className="c-search-section__label" htmlFor="reference">Contract Reference</label>
                            <input name='reference' id='reference' type='text' className='c-form__input' onChange={(e) => handleChange(e)} onKeyUp={(e) => handleEnterKey(e)} />
                        </div>
                    </div>

                    <div className="reverse-order reverse-order--tablet">
                        <div>
                            <fieldset className="c-search-section__datepicker-group">
                                <legend className="c-search-section__label">Expiry between</legend>
                                <div>
                                    <label className="c-search-section__sub-label" htmlFor="dateExpiryFrom">From</label>
                                    <input
                                        id="dateExpiryFrom"
                                        name="dateExpiryFrom"
                                        className="c-search-section__datepicker"
                                        type="date"
                                        autoComplete="false"
                                        onChange={(e) => handleChange(e)}
                                    />
                                </div>
                                <div>
                                    <label className="c-search-section__sub-label" htmlFor="dateExpiryTo">To</label>
                                    <input
                                        id="dateExpiryTo"
                                        name="dateExpiryTo"
                                        className="c-search-section__datepicker"
                                        type="date"
                                        autoComplete="false"
                                        onChange={(e) => handleChange(e)}
                                    />
                                </div>
                            </fieldset>
                        </div>

                        {organisations.length > 1 &&
                            <div>
                                <label className="c-search-section__label" htmlFor="organisations">Organisation</label>
                                <Select2
                                    id={'organisation'}
                                    options={organisations}
                                    change={(e) => { handleOrganisationChange(e); handleSelect2Change(e) }}
                                />
                            </div>
                        }
                    </div>

                    <div>
                        <div>
                            <fieldset className="c-fieldset">
                                <legend className="c-fieldset__legend js-fieldset-legend">All Organisations</legend>
                                {suppliers.length > 1 &&
                                    <div>
                                        <label className="c-search-section__label" htmlFor="supplier">Supplier</label>
                                        <Select2
                                            id={'supplier'}
                                            options={suppliers}
                                            change={(e) => { handleSelect2Change(e) }}
                                        />
                                    </div>
                                }
                                {customers.length > 1 &&
                                    <div className="u-component-spacing">
                                        <label className="c-search-section__label" htmlFor="customer">Customer</label>
                                        <Select2
                                            id={'customer'}
                                            options={customers}
                                            change={(e) => handleSelect2Change(e)}
                                        />
                                    </div>
                                }
                            </fieldset>
                        </div>
                    </div>
                </div>

                <div className="c-search-section__submit-buttons u-component-spacing--2x">
                    {canExport === "1" &&
                        <input className="c-search-section__export" type="submit" value="Export" onClick={() => exportData()} />
                    }
                    <button className="c-search-section__clear-search" onClick={() => clearFilters()}>
                        Clear Search
                    </button>
                    <input className="c-search-section__submit" type="submit" value="Search" onClick={() => searchData()} />
                </div>
            </div>
        </section>
    );
}

export default ContractFilters;