import React, { useEffect, useState } from "react";
import { faArrowLeft, faArrowRight, faStar } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, Card, CardBody, Table, UncontrolledTooltip } from 'reactstrap';
import { authHeader } from "../../helpers/auth-header";
import { getContactID, getSupplierID, handleConfirm, showCustomError, StartFreePaymentProcess } from "../../helpers/general";
import EmptyTableRow from "../controls/EmptyTableRow";
import Translation from "../controls/Translation";
import PaymentDetailsModal from "../Payment/PaymentDetails_Modal";
import Packages from "./Packages";
import TermsAndConditionsModal from "./TermsAndConditions_Modal";

const Renew = () => {
    const [user, setUser] = useState({ supplierID: -1, contactID: -1 });
    const [renewStage, setRenewStage] = useState(0);
    const [renewSelection, setRenewSelection] = useState({ supplierID: 0, contactID: 0, packageID: 0, portals: [], users: [], saveSearches: [] });
    const [freePackageSelected, setFreePackageSelected] = useState(false);
    const [renewalPackage, setRenewalPackage] = useState(null);
    const [summaryInfo, setSummaryInfo] = useState(null);
    const [supplierContacts, setSupplierContacts] = useState([]);
    const [supplierPortals, setSupplierPortals] = useState([]);
    const [supplierSearches, setSupplierSearches] = useState([]);
    const [urlTC, setUrlTC] = useState(null);
    const [agreeTC, setAgreeTC] = useState(false);
    const [selectAll, setSelectAll] = useState({ users: false, portals: false, searches: false });

    useEffect(() => {
        let supplierID = getSupplierID();
        let contactID = getContactID();

        fetch(`./Suppliers/GetSupplierContacts?supplierID=${supplierID}&currentContactID=${contactID}`, {
            method: 'GET',
            headers: authHeader()
        })
            .then(response => response.json())
            .then(data => {
                if (data) {
                    setSupplierContacts(data);
                    let contacts = renewSelection.users;
                    let currentUser = data.find(contact => contact.contactID === contactID);

                    if (currentUser) {
                        contacts.push({ id: contactID, fullName: currentUser.fullName, email: currentUser.email, isAdmin: currentUser.isAdmin, makeAdmin: currentUser.isAdmin });
                    }

                    setRenewSelection(prev => ({
                        ...prev,
                        users: contacts
                    }));
                }
            })
            .catch(err => {
                console.log("ERROR", err);
            })


        fetch(`./Suppliers/GetSupplierActivePortalIDs?supplierID=${supplierID}`, {
            method: 'GET',
            headers: authHeader()
        })
            .then(response => response.json())
            .then(data => {
                if (data) {
                    setSupplierPortals(data);
                }
            })
            .catch(err => {
                console.log("ERROR", err);
            })


        fetch(`./Suppliers/GetSupplierSearchIDs?supplierID=${supplierID}`, {
            method: 'GET',
            headers: authHeader()
        })
            .then(response => response.json())
            .then(data => {
                if (data) {
                    setSupplierSearches(data);
                }
            })
            .catch(err => {
                console.log("ERROR", err);
            })

        fetch(`./Settings/GetWebSetting?settingname=terms_and_conditions_url`, {
            method: 'GET',
            headers: authHeader(false, true)
        })
            .then(response => response.json())
            .then(urlData => {
                setUrlTC(urlData);
            })
            .catch(err => {
                console.log(err);
            });

        setUser(prev => ({
            ...prev,
            supplierID: supplierID,
            contactID: contactID
        }));

        setRenewSelection(prev => ({
            ...prev,
            supplierID: supplierID,
            contactID: contactID
        }));


    }, [renewSelection.users]);

    const IsAdminTooltip = React.forwardRef((_props, ref) => {
        return (
            <>
                <FontAwesomeIcon forwardedRef={ref} icon={faStar} />
                <UncontrolledTooltip placement="right" defaultOpen={false} target={ref}>
                    Administrator Account
                </UncontrolledTooltip>

            </>
        )
    });

    if (renewStage === 0) {
        return (
            <div>
                <RenewPackages
                    supplierID={user.supplierID}
                    contactID={user.contactID}
                    showAllPackages={true}
                    setRenewStage={setRenewStage}
                    setRenewSelection={setRenewSelection}
                    setFreePackageSelected={setFreePackageSelected}
                    setSupplierContacts={setSupplierContacts}
                />
            </div>
        )
    } else if (renewStage === 1) {
        return (
            <Users
                supplierID={user.supplierID}
                contactID={user.contactID}
                renewStage={renewStage}
                setRenewStage={setRenewStage}
                renewSelection={renewSelection}
                setRenewSelection={setRenewSelection}
                freePackageSelected={freePackageSelected}
                supplierContacts={supplierContacts}
                setSupplierContacts={setSupplierContacts}
                setSummaryInfo={setSummaryInfo}
                setRenewalPackage={setRenewalPackage}
                IsAdminTooltip={IsAdminTooltip}
                selectAll={selectAll}
                setSelectAll={setSelectAll}
            />
        )
    } else if (renewStage === 2) {
        return (
            <Portals
                supplierID={user.supplierID}
                contactID={user.contactID}
                renewStage={renewStage}
                setRenewStage={setRenewStage}
                renewSelection={renewSelection}
                setRenewSelection={setRenewSelection}
                supplierPortals={supplierPortals}
                selectAll={selectAll}
                setSelectAll={setSelectAll}
            />
        )
    } else if (renewStage === 3) {
        return (
            <SaveSearches
                supplierID={user.supplierID}
                contactID={user.contactID}
                renewStage={renewStage}
                setRenewStage={setRenewStage}
                renewSelection={renewSelection}
                setRenewSelection={setRenewSelection}
                setRenewalPackage={setRenewalPackage}
                setSummaryInfo={setSummaryInfo}
                supplierSearches={supplierSearches}
                selectAll={selectAll}
                setSelectAll={setSelectAll}
            />
        )
    } else if (renewStage === 4) {
        return (
            <Summary
                supplierID={user.supplierID}
                contactID={user.contactID}
                setRenewStage={setRenewStage}
                renewSelection={renewSelection}
                freePackageSelected={freePackageSelected}
                renewalPackage={renewalPackage}
                setRenewalPackage={setRenewalPackage}
                setSummaryInfo={setSummaryInfo}
                summaryInfo={summaryInfo}
                IsAdminTooltip={IsAdminTooltip}
                agreeTC={agreeTC}
                setAgreeTC={setAgreeTC}
                termsURL={urlTC}
            />
        )
    }
}


const RenewPackages = props => {
    let { supplierID, contactID, setRenewStage, setRenewSelection, setFreePackageSelected, setSupplierContacts } = props;

    const handlePackageSelect = (selectedPackageID) => {
        setRenewSelection(prev => ({
            ...prev,
            packageID: selectedPackageID,
            portals: [],
            users: [],
            saveSearches: []
        }));
        setSupplierContacts([]);

        // Clear the basket for the supplier before they continue
        fetch(`./Payment/EmptyBasket?supplierID=${supplierID}&contactID=${contactID}`, {
            method: 'GET',
            headers: authHeader()
        })
            .then(response => response.json())
            .then(() => {
                fetch(`./Packages/GetFreePackageID`, {
                    method: 'GET',
                    headers: authHeader()
                })
                    .then(response => response.json())
                    .then(data => {
                        if (data) {
                            setFreePackageSelected(data === selectedPackageID);
                            setRenewStage(1);
                        }
                    })
                    .catch(err => {
                        console.log("ERROR", err);
                    })
            })
            .catch(err => {
                console.log("ERROR", err);
            });
    }

    return (
        <Card>
            <CardBody>
                <h3 className="c-broken-underline u-component-spacing">
                    <Translation ID="RenewalAccountExpired" />
                </h3>
                <Translation ID="RenewalAccountExpiredDesc" paragraph={true} customClass='table u-component-spacing--2x' />
                <Packages
                    supplierID={supplierID}
                    contactID={contactID}
                    handleOnPackageSelect={handlePackageSelect}
                    showAllPackages={true}
                    buttonLabel='Select'
                />
            </CardBody>
        </Card>
    );
}



const Users = props => {
    let { contactID, setRenewStage, renewSelection, setRenewSelection, freePackageSelected, supplierContacts, setSummaryInfo, setRenewalPackage, IsAdminTooltip, selectAll, setSelectAll } = props;

    const handleChange = (e) => {
        let contacts = renewSelection.users;
        let selectedContactID = Number(e.target.value);
        let adminCheck = document.getElementById(`admin_${selectedContactID}`)


        if (e.target.checked === true) {
            // If free account, remove pre-selected users, ready for new user
            if (freePackageSelected === true) {
                if (contacts.length > 0 && contacts[0]['id'] !== selectedContactID) {
                    let otherUserCheck = document.getElementById(`contact_${contacts[0]['id']}`)

                    // Deselect the other admin if selected
                    if (otherUserCheck && otherUserCheck.checked) {
                        otherUserCheck.checked = false;
                    }

                    let otherAdminCheck = document.getElementById(`admin_${contacts[0]['id']}`)
                    // Deselect the other admin if selected
                    if (otherAdminCheck && otherAdminCheck.checked) {
                        otherAdminCheck.checked = false;
                    }

                    // Free account = 1 account = admin

                    // Remove the first [0] entry (originally selected) so new one is added as [1] above, becomes the new [0])
                    contacts.splice(0, 1);
                }
                adminCheck.checked = true;
            }

            contacts.push({
                id: selectedContactID,
                fullName: e.target.attributes["data-name"].value,
                email: e.target.attributes["data-email"].value,
                makeAdmin: adminCheck.checked
            });
        } else {
            let index = contacts.findIndex(x => x.id === selectedContactID);
            contacts.splice([index], 1);
            let adminContact = document.getElementById(`admin_${selectedContactID}`);
            if (adminContact) {
                adminContact.checked = false;
            }
        }

        setRenewSelection(prev => ({
            ...prev,
            users: contacts
        }));
    }

    const handleConvertBasketToSummary = () => {
        fetch(`./Payment/RenewalSummary`, {
            method: 'POST',
            headers: authHeader(),
            body: JSON.stringify(renewSelection)
        })
            .then(response => response.json())
            .then(renewalData => {
                if (renewalData) {
                    setSummaryInfo(renewalData);

                    // Add Renewal to basket
                    fetch(`./Payment/ConvertRenewToBasket`, {
                        method: "POST",
                        headers: authHeader(),
                        body: JSON.stringify(renewalData)
                    })
                        .then((response) => response.json())
                        .then((basketItemsData) => {
                            setRenewalPackage(value => ({
                                ...value,
                                basketItemsInfo: { renewal: true, basketItems: basketItemsData },
                                totalPriceExcVat: renewalData.totalPriceExcVat,
                                discountTotal: null,
                                subTotal: renewalData.totalPriceExcVat,
                                vatRate: renewalData.vatRate,
                                totalTax: renewalData.totalTax,
                                priceToCharge: renewalData.priceToCharge
                            }));
                        })
                }
            })
            .catch(err => {
                console.log("ERROR", err);
            })
    }

    const onClickNext = () => {
        // Get all elements with input type checkbox
        var checkboxes = document.querySelectorAll('input[type="checkbox"]');
        let proceed = false;

        // Loop through all checkboxes
        for (let i = 0; i < checkboxes.length; i++) {
            let checkbox = checkboxes[i];

            // Check if the checkbox ID starts with 'contact_' and is not the one with the userID
            if (!proceed && checkbox.id.startsWith('contact_') && checkbox.checked) {
                // Disable the checkbox
                proceed = true;
            }
        }
        if (proceed === false) {
            showCustomError(`You must select ${(!freePackageSelected ? 'atleast ' : '')}1 user to continue`, 'No Users Selected', 'error');
            return false;
        }

        let adminCheck = renewSelection.users.find(x => x.makeAdmin === true);
        if (adminCheck === null || adminCheck === undefined) {
            showCustomError(`You must select a user to be the account admin`, 'No Admin Selected', 'error');
            return false;
        }

        let currentUserSelected = renewSelection.users.find(x => x.id === contactID);
        if (currentUserSelected) {
            if (freePackageSelected === true) {
                handleConvertBasketToSummary();
                setRenewStage(4);
            } else {
                setRenewStage(2);
            }
        } else {
            handleConfirm('You have not selected your account as an active user.  If you complete the account renewal without selecting your account, you will be logged out upon renewal completion.<br /><br />Do you wish to continue?',
                () => {
                    if (freePackageSelected) {
                        handleConvertBasketToSummary();
                        setRenewStage(4)
                    }

                    setRenewStage(freePackageSelected === true ? 4 : 2)
                }
            )
        }

    }

    const onClickBack = () => {
        setRenewStage(0)
    }

    function userExists(selectedContactID) {
        return (
            (renewSelection.users.length === 0 && selectedContactID === contactID) || (renewSelection.users.length > 0  // If no users have been selected yet (first time) and user ID is same as logged in user
                &&
                renewSelection.users.some(user => user.id === selectedContactID))  // Users have already been selected
        );
    }

    function adminSelected(selectedContactID) {
        return (
            (renewSelection.users.length === 0 && selectedContactID === contactID) || (renewSelection.users.length > 0  // If no users have been selected yet (first time) and user ID is same as logged in user
                &&
                renewSelection.users.some(user => user.id === selectedContactID && user.makeAdmin === true))  // Users have already been selected
        );
    }

    function toggleAdmin(e) {
        let contacts = renewSelection.users;
        let selectedContactID = Number(e.target.value);
        let contact = document.getElementById(`contact_${selectedContactID}`)
        let findUser = contacts.find(user => user.id === selectedContactID);

        // disable other admin
        let prevAdmin = contacts.find(user => user.makeAdmin === true);
        if (prevAdmin && prevAdmin.id !== selectedContactID) {
            prevAdmin.makeAdmin = false;
        }

        if (findUser) {
            findUser.makeAdmin = e.target.checked;
        } else if (e.target.checked === true) {
            // push the data
            contact.checked = true;
            contacts.push({
                id: selectedContactID,
                fullName: contact.attributes["data-name"].value,
                email: contact.attributes["data-email"].value,
                makeAdmin: e.target.checked
            });
        }

        setRenewSelection(prev => ({
            ...prev,
            users: contacts
        }));
    }

    const handleSelectAll = () => {
        const chkAll = document.getElementById("chk_all_users");

        if (chkAll) {
            const table = document.getElementById("RenewUsersList");

            if (table) {
                const rows = table.querySelectorAll("tbody tr"); // Get all rows in tbody
                let updatedUsers = []; // To store selected portals if "Select All" is checked

                rows.forEach(row => {
                    const checkbox = row.querySelector('input[type="checkbox"]'); // Find checkbox in the row
                    if (checkbox) {
                        checkbox.checked = chkAll.checked; // Set its checked state
                        let admin = document.getElementById(`admin_${checkbox.value}`) ? document.getElementById(`admin_${checkbox.value}`).checked : false;

                        // Update the state with portal values (if checkbox is TRUE and the ID doesn't already exist [pre-selected])
                        if (chkAll.checked && renewSelection.users.some(x => x.id !== Number(checkbox.value))) {
                            const user = {
                                id: Number(checkbox.value),
                                fullName: checkbox.getAttribute("data-name"),
                                email: checkbox.getAttribute("data-email"),
                                makeAdmin: admin
                            };
                            updatedUsers.push(user);
                        }
                    }
                });

                // Update the renewSelection state
                setRenewSelection(prev => ({
                    ...prev,
                    users: chkAll.checked ? updatedUsers : [], // If unchecked, clear the portals array
                }));
            }

            setSelectAll(prev => ({
                ...prev,
                users: chkAll.checked
            }));
        }
    };

    return (
        <Card>
            <CardBody>
                <h3 className="c-broken-underline u-component-spacing">
                    <Translation ID="RenewalSelectUsers" />
                </h3>
                <p className='table u-component-spacing--2x'>
                    <Translation ID="RenewalSelectUsersDesc" />
                </p>
                {freePackageSelected &&
                    <p className='table u-component-spacing--2x' style={{ color: "red", fontWeight: "bold", display: "flex", flexFlow: "column", justifyContent: "center", alignItems: "center" }}>
                        <Translation ID="RenewalFreeAccountSelected" />
                    </p>
                }
                <table id='RenewUsersList' className='table u-component-spacing--2x'>
                    <thead style={{ position: "sticky", top: "0", background: "var(--card-bg-color)" }}>
                        <tr>
                            <th style={{ borderBottom: "0", borderTop: "0", fontWeight: "500", padding: "0", width: "10%" }}>
                                <span style={{ display: "block", padding: "0.5rem 0.75rem", borderBottom: "0.0625rem solid var(--border-color)" }} >
                                    <div className="c-form__input-group">
                                        <div className="custom-control" >
                                            <input
                                                id={`chk_all_users`}
                                                name={`chk_all_users`}
                                                type="checkbox"
                                                className="custom-control-input"
                                                defaultChecked={selectAll.users}
                                                onChange={(e) => handleSelectAll()} />
                                            <label htmlFor={`chk_all_users`} className='c-search-section__label c-checkbox-label'>
                                                <span className="u-visually-hidden">Select </span><Translation ID="RenewalTHAll" />
                                            </label>
                                        </div>
                                    </div>
                                </span>
                            </th>
                            <th style={{ borderBottom: "0", borderTop: "0", fontWeight: "500", padding: "0", width: "25%" }}><span style={{ display: "block", padding: "0.5rem 0.75rem", borderBottom: "0.0625rem solid var(--border-color)" }} ><Translation ID="Name" /></span></th>
                            <th style={{ borderBottom: "0", borderTop: "0", fontWeight: "500", padding: "0", width: "30%" }}><span style={{ display: "block", padding: "0.5rem 0.75rem", borderBottom: "0.0625rem solid var(--border-color)" }} ><Translation ID="EmailAddress" /></span></th>
                            <th style={{ borderBottom: "0", borderTop: "0", fontWeight: "500", padding: "0", width: "5%" }}><span style={{ display: "block", padding: "0.5rem 0.75rem", borderBottom: "0.0625rem solid var(--border-color)", textAlign: "center" }} > <Translation ID="RenewalTHCurrentAdmin" /></span></th>
                            <th style={{ borderBottom: "0", borderTop: "0", fontWeight: "500", padding: "0", width: "20%" }}><span style={{ display: "block", padding: "0.5rem 0.75rem", borderBottom: "0.0625rem solid var(--border-color)", textAlign: "center" }} > <Translation ID="RenewalTHChangeAdmin" /></span></th>
                            <th style={{ borderBottom: "0", borderTop: "0", fontWeight: "500", padding: "0", width: "15%" }}><span style={{ display: "block", padding: "0.5rem 0.75rem", borderBottom: "0.0625rem solid var(--border-color)", textAlign: "center" }} > </span></th>
                        </tr>
                    </thead>
                    <tbody>
                        {Object.keys(supplierContacts).length > 0 && renewSelection && Object.keys(renewSelection.users).length >= 0 &&
                            supplierContacts.map((contact, i) => {
                                return (
                                    <tr key={i} className={i % 2 === 0 ? "odd" : "even"}>
                                        <td>
                                            <div className="c-form__input-group ">
                                                <div className="custom-control">
                                                    <input
                                                        id={`contact_${contact.contactID}`}
                                                        name={`contact_${contact.contactID}`}
                                                        data-name={contact.fullName}
                                                        data-email={contact.email}
                                                        value={contact.contactID}
                                                        type="checkbox"
                                                        className="custom-control-input"
                                                        defaultChecked={userExists(contact.contactID)}
                                                        onChange={(e) => handleChange(e)}
                                                    />

                                                    <label htmlFor={`contact_${contact.contactID}`} className='c-search-section__label c-checkbox-label'>
                                                        <Translation ID="Select" />
                                                    </label>
                                                </div>
                                            </div>
                                        </td>
                                        <td>
                                            <label style={{ marginBottom: "0" }} htmlFor={`contact_${contact.contactID}`}>
                                                {contact.fullName} {contact.hasLoggedIn === false && <Translation ID="RenewalUserNeverLoggedIn" span={true} customClass={''} />} {contact.contactID === contactID && <span style={{ fontSize: "0.9rem" }}><Translation ID="RenewalCurrentUserLoggedIn" span={true} customClass={''} /></span>}
                                            </label>
                                        </td>
                                        <td>
                                            <label style={{ marginBottom: "0" }} htmlFor={`contact_${contact.contactID}`}>
                                                {contact.email}
                                            </label>
                                        </td>
                                        <td style={{ textAlign: "center" }}>
                                            {contact.isAdmin === true &&
                                                <IsAdminTooltip ref={React.createRef()} />
                                            }
                                        </td>
                                        <td>
                                            <div className="c-form__input-group">
                                                <div className="custom-checkbox custom-control">
                                                    <input
                                                        id={`admin_${contact.contactID}`}
                                                        name='admin'
                                                        data-name={contact.fullName}
                                                        value={contact.contactID}
                                                        type="radio"
                                                        className="c-radio-input__radio u-visually-hidden"
                                                        defaultChecked={adminSelected(contact.contactID)}
                                                        onChange={(e) => toggleAdmin(e)}
                                                        disabled={freePackageSelected}
                                                    />

                                                    <label htmlFor={`admin_${contact.contactID}`} className='c-search-section__label c-checkbox-label'><Translation ID="RenewalMakeAdmin" /></label>
                                                </div>
                                            </div>
                                        </td>
                                        <td>
                                        </td>
                                    </tr>
                                )
                            })}


                        {Object.keys(supplierContacts).length === 0 &&
                            <EmptyTableRow />
                        }
                    </tbody>
                </table>
                <div className="u-flex-end u-component-spacing--2x">
                    <button id="buttonPreviousPackages" className="js-change-panel c-cta" style={{ marginRight: "5em" }} type='button' onClick={() => onClickBack()}><FontAwesomeIcon icon={faArrowLeft} style={{ marginRight: "2em" }} /><Translation ID="RenewalBackPackages" /></button>
                    <button id="buttonNextPortals" className="js-change-panel c-cta" type='button' onClick={() => onClickNext()}>{freePackageSelected ? <Translation ID="RenewalNextSummary" /> : <Translation ID="RenewalNextPortals" />}<FontAwesomeIcon icon={faArrowRight} style={{ marginLeft: "2em" }} /></button>
                </div>

            </CardBody>
        </Card>);
}


const Portals = props => {
    let { setRenewStage, renewSelection, setRenewSelection, supplierPortals, selectAll, setSelectAll } = props;

    const handleChange = (e) => {
        let portals = renewSelection.portals;

        if (e.target.checked === true) {
            portals.push({ id: Number(e.target.value), portalName: e.target.attributes["data-name"].value })
        } else {
            var index = portals.findIndex(x => x.id === Number(e.target.value));
            portals.splice([index], 1);
        }

        setRenewSelection(prev => ({
            ...prev,
            portals: portals
        }));
    }

    const onClickNext = () => {
        setRenewStage(3)
    }

    const onClickBack = () => {
        setRenewStage(1)
    }

    function portalExists(portalID) {
        return renewSelection.portals.some(portal => portal.id === portalID);
    }

    const handleSelectAll = () => {
        const chkAll = document.getElementById("chk_all_portals");

        if (chkAll) {
            const table = document.getElementById("RenewPortalsList");

            if (table) {
                const rows = table.querySelectorAll("tbody tr"); // Get all rows in tbody
                let updatedPortals = []; // To store selected portals if "Select All" is checked

                rows.forEach(row => {
                    const checkbox = row.querySelector('input[type="checkbox"]'); // Find checkbox in the row
                    if (checkbox) {
                        checkbox.checked = chkAll.checked; // Set its checked state

                        // Update the state with portal values
                        if (chkAll.checked) {
                            const portal = {
                                id: Number(checkbox.value),
                                portalName: checkbox.getAttribute("data-name"),
                            };
                            updatedPortals.push(portal);
                        }
                    }
                });

                // Update the renewSelection state
                setRenewSelection(prev => ({
                    ...prev,
                    portals: chkAll.checked ? updatedPortals : [], // If unchecked, clear the portals array
                }));
            }

            setSelectAll(prev => ({
                ...prev,
                portals: chkAll.checked
            }));
        }
    };

    return (
        <Card>
            <CardBody>
                <h3 className="c-broken-underline u-component-spacing">
                    <Translation ID="RenewalSelectPortals" />
                </h3>
                <p className='table u-component-spacing--2x'>
                    <Translation ID="RenewalSelectPortalsDesc" />
                </p>
                <table id='RenewPortalsList' className='table u-component-spacing--2x'>
                    <thead style={{ position: "sticky", top: "0", background: "var(--card-bg-color)" }}>
                        <tr>
                            <th style={{ borderBottom: "0", borderTop: "0", fontWeight: "500", padding: "0", width: "10%" }}>
                                <span style={{ display: "block", padding: "0.5rem 0.75rem", borderBottom: "0.0625rem solid var(--border-color)" }} >
                                    <div className="c-form__input-group">
                                        <div className="custom-control">
                                            <input
                                                id={`chk_all_portals`}
                                                name={`chk_all_portals`}
                                                type="checkbox"
                                                className="custom-control-input"
                                                defaultChecked={selectAll.portals}
                                                onChange={(e) => handleSelectAll()} />

                                            <label htmlFor={`chk_all_portals`} className='c-search-section__label c-checkbox-label'>
                                                <span className="u-visually-hidden">Select </span><Translation ID="RenewalTHAll" />
                                            </label>
                                        </div>
                                    </div>
                                </span>
                            </th>
                            <th style={{ borderBottom: "0", borderTop: "0", fontWeight: "500", padding: "0", width: "80%" }}><span style={{ display: "block", padding: "0.5rem 0.75rem", borderBottom: "0.0625rem solid var(--border-color)" }} ><Translation ID="RenewalTHPortals" /></span></th>
                        </tr>
                    </thead>
                    <tbody>
                        {Object.keys(supplierPortals).length > 0 &&
                            <>
                                {supplierPortals.map((portal, i) => {
                                    return (
                                        <tr key={i} className={i % 2 === 0 ? "odd" : "even"}>
                                            <td>
                                                <div className="c-form__input-group">
                                                    <div className="custom-control">
                                                        <input
                                                            id={`portal_${portal.portalID}`}
                                                            name={`portal_${portal.portalID}`}
                                                            data-name={portal.portalName}
                                                            value={portal.portalID}
                                                            type="checkbox"
                                                            className="custom-control-input"
                                                            defaultChecked={portalExists(portal.portalID)}
                                                            onChange={(e) => handleChange(e)} />

                                                        <label htmlFor={`portal_${portal.portalID}`} className='c-search-section__label c-checkbox-label'>
                                                            <Translation ID="Select" />
                                                        </label>
                                                    </div>
                                                </div>
                                            </td>
                                            <td>
                                                <label style={{ marginBottom: "0" }} htmlFor={`portal_${portal.portalID}`} >{portal.portalName}</label>

                                            </td>
                                        </tr>
                                    )
                                })}
                            </>
                        }

                        {Object.keys(supplierPortals).length === 0 &&
                            <EmptyTableRow />
                        }
                    </tbody>
                </table>
                <div className="u-flex-end u-component-spacing--2x">
                    <button id="buttonPreviousUsers" className="js-change-panel c-cta" style={{ marginRight: "5em" }} type='button' onClick={() => onClickBack()}><FontAwesomeIcon icon={faArrowLeft} style={{ marginRight: "2em" }} /><Translation ID="RenewalBackUsers" /></button>
                    <button id="buttonNextSaveSearches" className="js-change-panel c-cta" type='button' onClick={() => onClickNext()}><Translation ID="RenewalNextSearches" /><FontAwesomeIcon icon={faArrowRight} style={{ marginLeft: "2em" }} /></button>
                </div>
            </CardBody>
        </Card>);
}

const SaveSearches = props => {
    let { setRenewStage, renewSelection, setRenewSelection, setRenewalPackage, setSummaryInfo, supplierSearches, selectAll, setSelectAll } = props;

    const handleChange = (e) => {
        let searches = renewSelection.saveSearches;

        if (e.target.checked === true) {
            searches.push({ id: Number(e.target.value), searchName: e.target.attributes["data-name"].value })
        } else {
            var index = searches.findIndex(x => x.id === Number(e.target.value));
            searches.splice([index], 1);
        }

        setRenewSelection(prev => ({
            ...prev,
            saveSearches: searches
        }));
    }

    const onClickNext = () => {
        // Convert object to renewal package
        fetch(`./Payment/RenewalSummary`, {
            method: 'POST',
            headers: authHeader(),
            body: JSON.stringify(renewSelection)
        })
            .then(response => response.json())
            .then(renewalData => {
                if (renewalData) {
                    setSummaryInfo(renewalData);

                    // Add Renewal to basket
                    fetch(`./Payment/ConvertRenewToBasket`, {
                        method: "POST",
                        headers: authHeader(),
                        body: JSON.stringify(renewalData)
                    })
                        .then((response) => response.json())
                        .then((basketItemsData) => {
                            setRenewalPackage(value => ({
                                ...value,
                                basketItemsInfo: { renewal: true, basketItems: basketItemsData },
                                totalPriceExcVat: renewalData.totalPriceExcVat,
                                discountTotal: null,
                                subTotal: renewalData.totalPriceExcVat,
                                vatRate: renewalData.vatRate,
                                totalTax: renewalData.totalTax,
                                priceToCharge: renewalData.priceToCharge
                            }));
                        })
                }
            })
            .catch(err => {
                console.log("ERROR", err);
            })

        setRenewStage(4)
    }

    const onClickBack = () => {
        setRenewStage(2);
    }

    function searchExists(searchID) {
        return renewSelection.saveSearches.some(search => search.id === searchID);
    }

    const handleSelectAll = () => {
        const chkAll = document.getElementById("chk_all_searches");

        if (chkAll) {
            const table = document.getElementById("RenewSearchesList");

            if (table) {
                const rows = table.querySelectorAll("tbody tr"); // Get all rows in tbody
                let updatedSearches = []; // To store selected portals if "Select All" is checked

                rows.forEach(row => {
                    const checkbox = row.querySelector('input[type="checkbox"]'); // Find checkbox in the row
                    if (checkbox) {
                        checkbox.checked = chkAll.checked; // Set its checked state

                        // Update the state with portal values
                        if (chkAll.checked) {
                            const searches = {
                                id: Number(checkbox.value),
                                searchName: checkbox.getAttribute("data-name"),
                            };
                            updatedSearches.push(searches);
                        }
                    }
                });

                // Update the renewSelection state
                setRenewSelection(prev => ({
                    ...prev,
                    saveSearches: chkAll.checked ? updatedSearches : [], // If unchecked, clear the portals array
                }));
            }

            setSelectAll(prev => ({
                ...prev,
                searches: chkAll.checked
            }));
        }
    };

    return (
        <Card>
            <CardBody>
                <h3 className="c-broken-underline u-component-spacing">
                    <Translation ID="RenewalSelectSearches" />
                </h3>
                <p className='table u-component-spacing--2x'>
                    <Translation ID="RenewalSelectSearchesDesc" />
                </p>
                <table id='RenewSearchesList' className='table u-component-spacing--2x'>
                    <thead style={{ position: "sticky", top: "0", background: "var(--card-bg-color)" }}>
                        <tr>
                            <th style={{ borderBottom: "0", borderTop: "0", fontWeight: "500", padding: "0", width: "15%" }}>
                                <span style={{ display: "block", padding: "0.5rem 0.75rem", borderBottom: "0.0625rem solid var(--border-color)" }} >
                                    <div className="c-form__input-group">
                                        <div className="custom-control">
                                            <input
                                                id={`chk_all_searches`}
                                                name={`chk_all_searches`}
                                                type="checkbox"
                                                className="custom-control-input"
                                                defaultChecked={selectAll.searches}
                                                onChange={(e) => handleSelectAll()} />

                                            <label htmlFor={`chk_all_searches`} className='c-search-section__label c-checkbox-label'>
                                                <span className="u-visually-hidden">Select </span><Translation ID="RenewalTHAll" />
                                            </label>
                                        </div>
                                    </div>
                                </span>
                            </th>
                            <th style={{ borderBottom: "0", borderTop: "0", fontWeight: "500", padding: "0", width: "80%" }}><span style={{ display: "block", padding: "0.5rem 0.75rem", borderBottom: "0.0625rem solid var(--border-color)" }} ><Translation ID="RenewalTHSearches" /></span></th>
                        </tr>
                    </thead>
                    <tbody>
                        {Object.keys(supplierSearches).length > 0 &&
                            <>
                                {supplierSearches.map((search, i) => {
                                    return (
                                        <tr key={i} className={i % 2 === 0 ? "odd" : "even"}>
                                            <td>
                                                <div className="c-form__input-group">
                                                    <div className="custom-control">
                                                        <input
                                                            id={`search_${search.searchID}`}
                                                            name={`search_${search.searchID}`}
                                                            value={search.searchID}
                                                            data-name={search.searchName}
                                                            type="checkbox"
                                                            className="custom-control-input"
                                                            defaultChecked={searchExists(search.searchID)}
                                                            onChange={(e) => handleChange(e)} />

                                                        <label htmlFor={`search_${search.searchID}`} className='c-search-section__label c-checkbox-label'>
                                                            <Translation ID="Select" />
                                                        </label>
                                                    </div>
                                                </div>
                                            </td>
                                            <td>
                                                <label style={{ marginBottom: "0" }} htmlFor={`search_${search.searchID}`} >{search.searchName}</label>

                                            </td>
                                        </tr>
                                    )
                                })}
                            </>
                        }

                        {Object.keys(supplierSearches).length === 0 &&
                            <EmptyTableRow />
                        }
                    </tbody>
                </table>
                <div className="u-flex-end u-component-spacing--2x">
                    <button id="buttonPreviousPortals" className="js-change-panel c-cta" style={{ marginRight: "5em" }} type='button' onClick={() => onClickBack()}><FontAwesomeIcon icon={faArrowLeft} style={{ marginRight: "2em" }} /><Translation ID="RenewalBackPortals" /></button>
                    <button id="buttonNextSummary" className="js-change-panel c-cta" type='button' onClick={() => onClickNext()}><Translation ID="RenewalNextSummary" /><FontAwesomeIcon icon={faArrowRight} style={{ marginLeft: "2em" }} /></button>
                </div>
            </CardBody>
        </Card>
    );
}

const Summary = props => {
    let { supplierID, contactID, setRenewStage, renewSelection, freePackageSelected, renewalPackage, setRenewalPackage, setSummaryInfo, summaryInfo, IsAdminTooltip, agreeTC, setAgreeTC, termsURL } = props;
    const [previousTotals, setPreviousTotals] = useState(null);
    const [isLoading, setIsLoading] = useState(true);

    const [showModal, setShowModal] = useState(false);
    const [modalSelect, setModalSelect] = useState(0);
    const toggle = () => setShowModal(!showModal);

    const onClickBack = () => {
        setRenewStage(freePackageSelected === true ? 1 : 3)
    }

    function handleApplyDiscount(discountBasketItem) {
        setPreviousTotals({ subTotal: summaryInfo.totalPriceExcVat, totalTax: summaryInfo.totalTax, priceToCharge: summaryInfo.priceToCharge });

        let discountTotal = discountBasketItem.unitPriceExcVat > 0 ? discountBasketItem.unitPriceExcVat * -1 : discountBasketItem.unitPriceExcVat;
        let tmp = renewalPackage.basketItemsInfo.basketItems.map(item => item.unitPriceExcVat).reduce((total, price) => total + price, 0) + discountTotal;
        let updateSubTotal = tmp > 0 ? tmp : 0;

        let updateTax = (updateSubTotal / 100) * renewalPackage.vatRate;
        let updateTotal = updateSubTotal + updateTax;
        let tmpBasketItems = renewalPackage.basketItemsInfo.basketItems;
        tmpBasketItems.push(discountBasketItem);

        let basketInfo = { renewal: true, basketItems: tmpBasketItems };

        setSummaryInfo(prev => ({
            ...prev,
            discountItem: discountBasketItem,
            discountTotal: discountBasketItem.unitPriceExcVat,
            totalPriceExcVat: updateSubTotal,
            totalTax: updateTax,
            priceToCharge: updateTotal
        }));

        setRenewalPackage(prev => ({
            ...prev,
            basketItems: basketInfo,
            discountTotal: discountTotal,
            totalPriceExcVat: updateSubTotal,
            totalTax: updateTax,
            priceToCharge: updateTotal
        }));
    }

    const handleAddDiscount = () => {
        var discountCodeInput = document.getElementById('discountCode');
        let obData = { "discountCode": discountCodeInput.value, "subTotal": renewalPackage.totalPriceExcVat };
        if (renewalPackage.basketItemsInfo.basketItems.filter(x => x.discountCode !== '' && x.discountCode !== null).length === 0) {
            if (discountCodeInput !== null && discountCodeInput.value.length > 0) {
                fetch(`./Payment/ApplyDiscountCode?supplierID=${supplierID}&contactID=${contactID}`, {
                    method: "POST",
                    headers: authHeader(),
                    body: JSON.stringify(obData)
                })
                    .then((response) => response.json())
                    .then((data) => {
                        if (data.success === true) {
                            handleApplyDiscount(data.objData)
                        } else {
                            showCustomError(data.message, '', 'error');
                        }
                    })
                    .catch((err) => {
                        console.log("error", err);
                    });
                discountCodeInput.value = "";
            } else {
                showCustomError('No discount code entered', '', 'error')
            }
        } else {
            showCustomError('Active discount code already added', '', 'error')
        }
    }

    const handleRemoveDiscount = () => {
        let basketItems = renewalPackage.basketItemsInfo.basketItems;
        let index = basketItems.findIndex(x => x.discountCode !== null && x.discountCode !== '');
        if (index >= 0) {
            basketItems.splice(index, 1);

            setRenewalPackage(value => ({
                ...value,
                basketItems: basketItems,
                discountTotal: null,
                subTotal: previousTotals.subTotal,
                totalTax: previousTotals.totalTax,
                priceToCharge: previousTotals.priceToCharge
            }));

            var discountCodeInput = document.getElementById('discountCode');

            if (discountCodeInput !== null) {
                discountCodeInput.removeAttribute('disabled');
            }
        }

        setSummaryInfo(prev => ({
            ...prev,
            discountItem: null,
            discountTotal: null,
            totalPriceExcVat: previousTotals.subTotal,
            totalTax: previousTotals.totalTax,
            priceToCharge: previousTotals.priceToCharge
        }));

        setRenewalPackage(prev => ({
            ...prev,
            discountItem: null,
            discountTotal: null,
            totalPriceExcVat: previousTotals.subTotal,
            totalTax: previousTotals.totalTax,
            priceToCharge: previousTotals.priceToCharge
        }));
    }

    const proceedToPay = () => {
        if (renewalPackage.basketItemsInfo.basketItems) {
            if (renewalPackage.priceToCharge > 0) {
                setIsLoading(true)
                setModalSelect(2);
                toggle();
            } else {
                // Free payment, create an Ogone entry, and no card details
                let freeAccount = [];

                freeAccount.push({ field: 'priceExcVat', value: renewalPackage.priceToCharge });
                freeAccount.push({ field: 'basketItems', value: JSON.stringify(renewalPackage.basketItemsInfo) });

                let upgradePackageID = 0; // This needs to handle package upgrades too eventually
                StartFreePaymentProcess(freeAccount, supplierID, contactID, "00000000-0000-0000-0000-000000000000", upgradePackageID);
            }
        }
    }

    const handleProceedToPay = () => {
        let currentUserSelected = renewSelection.users.find(x => x.id === contactID);
        if (currentUserSelected) {
            proceedToPay();
        } else {
            handleConfirm('Before continuing to complete the renewal, you have not selected your account as an active user.  If you complete the account renewal without selecting your account as an active user, you will be logged out upon renewal completion.<br /><br />Do you wish to continue?',
                () => { proceedToPay(); }
            )
        }
    }

    const handleTermsAndConditions = () => {
        setIsLoading(true)
        setModalSelect(1)
        toggle();
    }

    return (
        <>
            <Card>
                <CardBody>
                    <h3 className="c-broken-underline u-component-spacing">
                        <Translation ID="RenewalPackageSummary" />
                    </h3>
                    {summaryInfo &&
                        <div className="padding-l-25 padding-r-20 card main-content__inner u-component-spacing--2x">
                            <Card className="">
                                <CardBody>
                                    <Table size="sm" width="100%" className="c-invoice-modal__invoice-table" style={{ marginTop: "1rem" }}>
                                        <thead>
                                            <tr>
                                                <th className="c-invoice-modal__table-heading" style={{ width: "52%" }}><Translation ID="Package" /></th>
                                                <th className="c-invoice-modal__table-heading c-invoice-modal__center-text" style={{ width: "16%" }}><Translation ID="Quantity" /></th>
                                                <th className="c-invoice-modal__table-heading" style={{ width: "16%" }}><Translation ID="UnitPrice" /></th>
                                                <th className="c-invoice-modal__table-heading" style={{ width: "16%" }}><Translation ID="PriceExcVat" /></th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr className="c-invoice-modal__table-row">
                                                <td>
                                                    <h3>{summaryInfo.packageName}</h3>
                                                </td>
                                                <td className="c-invoice-modal__center-text">1</td>
                                                <td>&pound;{summaryInfo.packagePriceExcVat.toLocaleString('en', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</td>
                                                <td>&pound;{summaryInfo.packagePriceExcVat.toLocaleString('en', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</td>
                                            </tr>
                                        </tbody>
                                    </Table>

                                    <h4 style={{ marginTop: "2rem", textDecoration: "underline" }}><Translation ID="PService_Users" /></h4>
                                    <Table size="sm" width="100%" className="c-invoice-modal__invoice-table" style={{ marginTop: "1rem" }}>
                                        <thead>
                                            <tr>
                                                <th className="c-invoice-modal__table-heading" style={{ width: "5%" }}>Admin</th>
                                                <th className="c-invoice-modal__table-heading" style={{ width: "30%" }}><Translation ID="User" /></th>
                                                <th className="c-invoice-modal__table-heading" style={{ width: "18%" }}> </th>
                                                <th className="c-invoice-modal__table-heading c-invoice-modal__center-text" style={{ width: "15%" }}><Translation ID="Quantity" /></th>
                                                <th className="c-invoice-modal__table-heading" style={{ width: "16%" }}><Translation ID="UnitPrice" /></th>
                                                <th className="c-invoice-modal__table-heading" style={{ width: "16%" }}><Translation ID="PriceExcVat" /></th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {summaryInfo.users.map((user, i) => {
                                                return (
                                                    <tr key={i} className="c-invoice-modal__table-row">
                                                        <td style={{ textAlign: "center", verticalAlign: "top" }}>
                                                            {user.makeAdmin === true &&
                                                                <IsAdminTooltip ref={React.createRef()} />
                                                            }
                                                        </td>
                                                        <td>
                                                            <p>{user.fullName}</p>
                                                            <p><i>({user.email}) </i></p>

                                                        </td>
                                                        <td>
                                                            {user.priceExcVat === 0.00 ? <Translation ID="IncludedInPackageAllowance" span={true} customClass='' /> : <></>}
                                                        </td>
                                                        <td className="c-invoice-modal__center-text">1</td>
                                                        <td>&pound;{user.priceExcVat.toLocaleString('en', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</td>
                                                        <td>&pound;{user.priceExcVat.toLocaleString('en', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</td>
                                                    </tr>
                                                )
                                            })}
                                            <tr className="c-invoice-modal__table-row">
                                                <td style={{ borderTop: "2px solid" }}></td>
                                                <td style={{ borderTop: "2px solid" }}></td>
                                                <td style={{ borderTop: "2px solid" }}></td>
                                                <td style={{ borderTop: "2px solid" }}></td>
                                                <td className="c-invoice-modal__table-summary-term" style={{ borderTop: "2px solid" }}><Translation ID="SubTotal" /></td>
                                                <td style={{ borderTop: "2px solid" }}>&pound;{summaryInfo.users.map(item => item.priceExcVat).reduce((total, price) => total + price, 0).toLocaleString('en', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</td>
                                            </tr>
                                        </tbody>
                                    </Table>

                                    <h4 style={{ marginTop: "2rem", textDecoration: "underline" }}><Translation ID="Portals" /></h4>
                                    {summaryInfo.portals.length > 0 &&
                                        <Table size="sm" width="100%" className="c-invoice-modal__invoice-table" style={{ marginTop: "1rem" }}>
                                            <thead>
                                                <tr>
                                                    <th className="c-invoice-modal__table-heading" style={{ width: "52%" }}><Translation ID="Portal" /></th>
                                                    <th className="c-invoice-modal__table-heading c-invoice-modal__center-text" style={{ width: "16%" }}><Translation ID="Quantity" /></th>
                                                    <th className="c-invoice-modal__table-heading" style={{ width: "16%" }}><Translation ID="UnitPrice" /></th>
                                                    <th className="c-invoice-modal__table-heading" style={{ width: "16%" }}><Translation ID="PriceExcVat" /></th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {summaryInfo.portals.map((portal, i) => {
                                                    return (
                                                        <tr key={i} className="c-invoice-modal__table-row">
                                                            <td>
                                                                {portal.portalName}{portal.priceExcVat === 0.00 ? <Translation ID="IncludedInPackageAllowance" span={true} customClass='' /> : <></>}
                                                            </td>
                                                            <td className="c-invoice-modal__center-text">1</td>
                                                            <td>&pound;{portal.priceExcVat.toLocaleString('en', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</td>
                                                            <td>&pound;{portal.priceExcVat.toLocaleString('en', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</td>
                                                        </tr>
                                                    )
                                                })}
                                                <tr className="c-invoice-modal__table-row">
                                                    <td style={{ borderTop: "2px solid" }}></td>
                                                    <td style={{ borderTop: "2px solid" }}></td>
                                                    <td className="c-invoice-modal__table-summary-term" style={{ borderTop: "2px solid" }}><Translation ID="SubTotal" /></td>
                                                    <td style={{ borderTop: "2px solid" }}>&pound;{summaryInfo.portals.map(item => item.priceExcVat).reduce((total, price) => total + price, 0).toLocaleString('en', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</td>
                                                </tr>
                                            </tbody>
                                        </Table>
                                    }
                                    {summaryInfo.portals.length === 0 &&
                                        <div className="u-component-spacing" style={{ marginLeft: "2rem" }}><Translation ID="NoPortalsSelected" /></div>
                                    }


                                    <h4 style={{ marginTop: "2rem", textDecoration: "underline" }}><Translation ID="PService_SaveSearches" /></h4>
                                    {summaryInfo.saveSearches.length > 0 &&
                                        <Table size="sm" width="100%" className="c-invoice-modal__invoice-table" style={{ marginTop: "1rem" }}>
                                            <thead>
                                                <tr>
                                                    <th className="c-invoice-modal__table-heading" style={{ width: "52%" }}><Translation ID="SaveSearch" /></th>
                                                    <th className="c-invoice-modal__table-heading c-invoice-modal__center-text" style={{ width: "16%" }}><Translation ID="Quantity" /></th>
                                                    <th className="c-invoice-modal__table-heading" style={{ width: "16%" }}><Translation ID="UnitPrice" /></th>
                                                    <th className="c-invoice-modal__table-heading" style={{ width: "16%" }}><Translation ID="PriceExcVat" /></th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {summaryInfo.saveSearches.map((search, i) => {
                                                    return (
                                                        <tr key={i} className="c-invoice-modal__table-row">
                                                            <td>
                                                                {search.searchName}{search.priceExcVat === 0.00 ? <Translation ID="IncludedInPackageAllowance" span={true} customClass='' /> : <></>}
                                                            </td>
                                                            <td className="c-invoice-modal__center-text">1</td>
                                                            <td>&pound;{search.priceExcVat.toLocaleString('en', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</td>
                                                            <td>&pound;{search.priceExcVat.toLocaleString('en', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</td>
                                                        </tr>
                                                    )
                                                })}
                                                <tr className="c-invoice-modal__table-row">
                                                    <td style={{ borderTop: "2px solid" }}></td>
                                                    <td style={{ borderTop: "2px solid" }}></td>
                                                    <td className="c-invoice-modal__table-summary-term" style={{ borderTop: "2px solid" }}><Translation ID="SubTotal" /></td>
                                                    <td style={{ borderTop: "2px solid" }}>&pound;{summaryInfo.saveSearches.map(item => item.priceExcVat).reduce((total, price) => total + price, 0).toLocaleString('en', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</td>
                                                </tr>
                                            </tbody>
                                        </Table>
                                    }
                                    {summaryInfo.saveSearches.length === 0 &&
                                        <div className="u-component-spacing" style={{ marginLeft: "2rem" }}><Translation ID="NoSaveSearchesSelected" /></div>
                                    }

                                    {summaryInfo.discountItem &&
                                        <Table size="sm" width="100%" className="c-invoice-modal__invoice-table u-component-spacing--2x">
                                            <thead>
                                                <tr>
                                                    <th className="c-invoice-modal__table-heading" style={{ width: "36%" }}><Translation ID="Discount" /></th>
                                                    <th className="c-invoice-modal__table-heading" style={{ width: "16%" }}></th>
                                                    <th className="c-invoice-modal__table-heading c-invoice-modal__center-text" style={{ width: "16%" }}><Translation ID="Quantity" /></th>
                                                    <th className="c-invoice-modal__table-heading" style={{ width: "16%" }}></th>
                                                    <th className="c-invoice-modal__table-heading" style={{ width: "16%" }}><Translation ID="PriceExcVat" /></th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr className="c-invoice-modal__table-row">
                                                    <td>
                                                        <span style={{ color: "red" }} dangerouslySetInnerHTML={{ __html: summaryInfo.discountItem.description }} />
                                                    </td>
                                                    <td>
                                                        <button id={`removeDiscount`} className="c-tender-actions__action-button c-tender-actions__action-button--bin" onClick={() => { handleRemoveDiscount() }}><Translation ID="RemoveDiscount" /></button>
                                                    </td>
                                                    <td className="c-invoice-modal__center-text">1</td>
                                                    <td></td>
                                                    <td style={{ color: "red" }}>&pound;{summaryInfo.discountItem.priceExcVat.toLocaleString('en', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</td>
                                                </tr>
                                            </tbody>
                                        </Table>
                                    }

                                    <Table style={{ marginTop: "1rem" }}>
                                        <thead>
                                            <tr>
                                                <th className="" style={{ width: "52%" }}></th>
                                                <th className="" style={{ width: "16%" }}></th>
                                                <th className="" style={{ width: "16%" }}></th>
                                                <th className="" style={{ width: "16%" }}></th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {summaryInfo.discountItem === null &&
                                                <tr style={{ border: "0px" }}>
                                                    <td style={{ border: "0px" }}></td>
                                                    <td style={{ border: "0px" }}></td>
                                                    <td className="c-invoice-modal__table-summary-term" style={{ verticalAlign: "top" }}>
                                                        <span style={{ paddingTop: "calc(.75rem + 1px)", display: "block" }}><Translation ID="DiscountCode" /></span>
                                                    </td>
                                                    <td style={{ border: "0px" }} className="c-invoice-modal__table-summary-value">
                                                        <input id='discountCode' name='discountCode' type='text' className='c-form__input' />
                                                        <Button color="secondary" style={{ fontSize: "1rem", float: "right", marginTop: "5px" }} onClick={() => handleAddDiscount()}><Translation ID="ApplyCode" /></Button>
                                                    </td>
                                                </tr>
                                            }
                                            {summaryInfo.discountItem &&
                                                <tr style={{ color: "red" }}>
                                                    <td style={{ border: "0px" }}></td>
                                                    <td style={{ border: "0px" }}></td>
                                                    <td className="c-invoice-modal__table-summary-term" style={{ paddingTop: "2rem", border: "0px" }}><Translation ID="Discount" /></td>
                                                    <td className="c-invoice-modal__table-summary-value" style={{ paddingTop: "2rem", border: "0px" }}>
                                                        &pound;{summaryInfo.discountItem.priceExcVat.toLocaleString('en', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}
                                                        {/*-&pound;{summaryInfo.discountItem.unitPriceExcVat.toLocaleString('en', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}*/}
                                                    </td>
                                                </tr>
                                            }
                                            <tr>
                                                <td style={{ border: "0px" }}></td>
                                                <td style={{ border: "0px" }}></td>
                                                <td className="c-invoice-modal__table-summary-term" style={{ border: "0px" }}><Translation ID="SubTotal" /></td>
                                                <td className="c-invoice-modal__table-summary-value" style={{ border: "0px" }}>&pound;{summaryInfo.totalPriceExcVat.toLocaleString('en', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</td>
                                            </tr>
                                            <tr>
                                                <td style={{ border: "0px" }}></td>
                                                <td style={{ border: "0px" }}></td>
                                                <td className="c-invoice-modal__table-summary-term" style={{ border: "0" }}><Translation ID="VAT" /> ({summaryInfo.vatRate}%)</td>
                                                <td className="c-invoice-modal__table-summary-value" style={{ border: "0" }}>&pound;{summaryInfo.totalTax.toLocaleString('en', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</td>
                                            </tr>
                                            <tr>
                                                <td style={{ border: "0px" }}></td>
                                                <td style={{ border: "0px" }}></td>
                                                <td className="c-invoice-modal__table-summary-term" style={{ border: "0", fontWeight: "bold" }}><Translation ID="Total" /> ({summaryInfo.vatRate}%)</td>
                                                <td className="c-invoice-modal__table-summary-value" style={{ border: "0", fontWeight: "bold" }}>&pound;{summaryInfo.priceToCharge.toLocaleString('en', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</td>
                                            </tr>
                                        </tbody>
                                    </Table>
                                </CardBody>
                            </Card>
                        </div>
                    }

                    <div className="u-flex-end u-component-spacing--2x">
                        <button id="buttonPreviousPackages" className="js-change-panel c-cta" style={{ marginRight: "5em" }} type='button' onClick={() => onClickBack()}><FontAwesomeIcon icon={faArrowLeft} style={{ marginRight: "2em" }} />{freePackageSelected === true ? <Translation ID="RenewalBackUsers" /> : <Translation ID="RenewalBackSearches" />}</button>
                        {summaryInfo && <button id="purchaseButton" className="c-cta" onClick={() => handleTermsAndConditions()}><Translation ID={(freePackageSelected || summaryInfo.priceToCharge === 0) ? "ConfirmAndProceed" : "ProceedToPay"} /><FontAwesomeIcon icon={faArrowRight} style={{ marginLeft: "2em" }} /></button>}
                    </div>
                </CardBody>
            </Card>


            {modalSelect === 1 &&
                <TermsAndConditionsModal
                    isOpen={showModal}
                    toggle={toggle}
                    agreeTC={agreeTC}
                    setAgreeTC={setAgreeTC}
                    termsURL={termsURL}
                    handleContinueFromTC={handleProceedToPay}
                />
            }

            {modalSelect === 2 &&
                <PaymentDetailsModal
                    isOpen={modalSelect === 2}
                    toggle={toggle}
                    isLoading={isLoading}
                    setIsLoading={setIsLoading}
                    priceExcVat={summaryInfo.totalPriceExcVat}
                    basketItems={renewalPackage.basketItemsInfo}
                    setModalSelect={setModalSelect}
                />
            }

        </>
    )
}

export default Renew;