import React, { useEffect, useState } from "react";
import { authHeader } from "../../helpers/auth-header";
import { getApplicationType, getContactID, getSupplierID, goTo, handleBackground, pageEnabled } from "../../helpers/general";
import NavMenu from "../layout/NavMenu";
import "bootstrap/dist/css/bootstrap.css";

require('datatables.net-bs4');

const ContractPageCheck = (props) => {
    let { ContractPage } = props;
    const [user, setUser] = useState({ supplierID: -1, contactID: -1 });
    const [isPageEnabled, setIsPageEnabled] = useState(false);
    const [systemName, setSystemName] = useState('');
    const [upgradeRequired, setUpgradeRequired] = useState({ purchase: null, upgrade: null });

    useEffect(() => {
        if (user.supplierID !== -1 && systemName !== '') {
            pageEnabled(user.supplierID > 0 ? 'Nav_Contracts' : 'Nav_PublicContracts', '/404')
                .then(enabled => {
                    setIsPageEnabled(enabled);

                    if (enabled === true && user.supplierID > 0) {
                        fetch(`./Settings/ArePackagesEnabled`, {
                            method: 'GET',
                            headers: authHeader(false, true)
                        })
                            .then(response => response.json())
                            .then(packagesEnabled => {
                                if (packagesEnabled === true) {
                                    fetch(`./Packages/GetContractServiceStatus?supplierID=${user.supplierID}`, {
                                        method: 'GET',
                                        headers: authHeader(false, false)
                                    })
                                        .then(response => response.json())
                                        .then(data => {
                                            if (data !== null) {
                                                setUpgradeRequired({ purchase: data.purchase, upgrade: data.upgrade });
                                            }
                                        })
                                } else {
                                    setUpgradeRequired({ purchase: false, upgrade: false });
                                }
                            })
                            .catch(err => {
                                console.log(err);
                            })
                    } else {
                        setUpgradeRequired({ purchase: false, upgrade: false });
                    }
                });
        }
    }, [systemName, user.supplierID]);

    useEffect(() => {
        setUser(prev => ({
            ...prev,
            supplierID: getSupplierID(),
            contactID: getContactID()
        }))

        getApplicationType()
            .then(application => {
                setSystemName(application);
            });
    }, []);


    useEffect(() => {
        handleBackground();
    }, [user.supplierID])

    if (isPageEnabled === true) {
        if (upgradeRequired.purchase === false && upgradeRequired.upgrade === false && systemName !== '') {
            return (
                <>
                    {/* ---------------------------------------------------------------- */}
                    {/* ---------------------------------------------------------------- */}
                    {/*HERE IS THE COMPONENT PASSED DOWN*/}

                    <ContractPage
                        isPageEnabled={isPageEnabled}
                        systemName={systemName}
                        user={user}
                    />

                    {/* ---------------------------------------------------------------- */}
                    {/* ---------------------------------------------------------------- */}
                </>
            );
        } else {
            return (
                <>
                    <NavMenu />
                    <section className="c-search-section u-box-shadow">
                        <div className="u-flex-center">
                            <h2 className="u-component-spacing u-branded-underline">Contract Register</h2>
                        </div>
                        <div className="u-container u-component-spacing--2x">
                            {upgradeRequired.purchase === true &&
                                <>
                                    <div>
                                        <label className="c-search-section__label" htmlFor="title">Your package allows the purchase of the Contract Register service</label>
                                        <button id='purchaseContractRegister' className="c-tender-actions__action-button c-tender-actions__action-button--purchase u-component-spacing" onClick={(() => goTo('/products'))}>
                                            Purchase Contract Register Service
                                        </button>
                                    </div>
                                    <div className="u-component-spacing--3x">
                                        <label className="c-search-section__label" htmlFor="title">Why not upgrade your package - this includes the Contract Register along with more Single Sign On and Save Search Allowance</label>
                                        <button style={{ display: "unset", marginBottom: "1em" }} className="c-tender-actions__action-button c-tender-actions__action-button--100 c-tender-actions__action-button--upgrade u-component-spacing" title='Package Upgrade Required' onClick={() => goTo(`/upgrade`)}>
                                            Upgrade your package
                                        </button>
                                    </div>
                                </>
                            }
                            {upgradeRequired.upgrade === true &&
                                <>
                                    <label className="c-search-section__label" htmlFor="title">You must upgrade your package to access the Contract Register</label>
                                    <button style={{ display: "unset", marginBottom: "1em" }} className="c-tender-actions__action-button c-tender-actions__action-button--100 c-tender-actions__action-button--upgrade u-component-spacing--2x" title='Package Upgrade Required' onClick={() => goTo(`/upgrade`)}>
                                        Upgrade your package
                                    </button>
                                </>
                            }
                        </div>
                    </section>
                </>
            );
        }
    } else {
        return null;
    }
}

export default ContractPageCheck;