import React, { useEffect, useState } from 'react';
import { Card, CardBody } from 'reactstrap';
import { authHeader } from '../../helpers/auth-header';
import { useQuery } from '../../helpers/general';
import ContractDetails from '../Contracts/ContractDetails';
import ContractPageCheck from '../Contracts/ContractPageCheck';
import BackToContracts from '../controls/BackToContracts';
import Footer from '../layout/Footer';
import NavMenu from '../layout/NavMenu';
import SpinnerOverlay from '../layout/SpinnerOverlay';

const ContractExternalDetails = () => {
    let query = useQuery();
    let secureID = query.get('secureid');
    const [organisation, setOrganisation] = useState('');
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        if (secureID) {
            fetch(`./Contracts/GetContractOrganisation?secureid=${secureID}`, {
                method: 'GET',
                headers: authHeader(false, true)
            })
                .then(response => response.json())
                .then(dataOrganisation => {
                    setOrganisation(dataOrganisation)
                })
                .catch(err => {
                    console.log(err);
                });
        }
    }, [secureID]);


    return (
        <>
            <NavMenu />
            <main className='main-content u-container'>
                <BackToContracts />
                <Card>
                    <CardBody>
                        <h2 className="c-broken-underline u-component-spacing">{organisation} Contract</h2>
                        <Card className=" u-component-spacing">
                            <CardBody>
                                <ContractDetails
                                    secureID={secureID}
                                    setIsLoading={setIsLoading}
                                />
                                {isLoading &&
                                    <SpinnerOverlay />
                                }
                            </CardBody>
                        </Card>
                    </CardBody>
                </Card>
            </main>

            <Footer />
        </>
    )
}


//Create the child component above, and use in the generic Contract Page Check (which checks if the page is enabled or not)
const ContractExternal = () => {
    return (
        <ContractPageCheck
            ContractPage={ContractExternalDetails}
        />
    )
}

export default ContractExternal;