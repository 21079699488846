import React, { useEffect, useState } from 'react';
import { Button, Card, CardBody, CardHeader } from 'reactstrap';
import { authHeader } from '../../helpers/auth-header';
import { getApplicationType, handleConfirm, handleTimedSuccess, showCustomError, showError } from '../../helpers/general';
import { history } from '../../helpers/history';
import EmptyTableRow from '../controls/EmptyTableRow';
import Translation from '../controls/Translation';

require('datatables.net-bs4');

const SavedSearches = props => {
    const { supplierID, contactID, CardClass, PanelName, userPermissions } = props;
    const [packageService, setPackageService] = useState({ id: 0, price: 0 });
    const [savedSearches, setSavedSearches] = useState([]);

    useEffect(() => {
        if (supplierID > 0) {

            getApplicationType()
                .then(application => {
                    fetch(`./Suppliers/GetAllSupplierSavedSearches?supplierID=${supplierID}&contactID=${contactID}&systemName=${application}`, {
                        method: 'GET',
                        headers: authHeader(false, false)
                    })
                        .then(response => response.json())
                        .then(data => {
                            if (data) {
                                setSavedSearches(data);
                            }
                        })
                        .catch(err => {
                            console.log("ERROR", err);
                        })

                    fetch(`./Packages/GetPackageServiceDetails?packageService=pservice_savesearches`, {
                        method: 'GET',
                        headers: authHeader(false, false)
                    })
                        .then(response => response.json())
                        .then(data => {
                            if (data) {
                                setPackageService({ id: data.uniqueID, price: data.priceExc })
                            }
                        })
                        .catch(err => {
                            console.log("ERROR", err);
                        });
                });
        }

        return () => {
            setSavedSearches({ id: 0, price: 0 });
            setSavedSearches([]);
        }
    }, [contactID, supplierID]);

    const handleRemoveFromBasket = uniqueID => {
        if (uniqueID) {
            let removeData = {
                "SupplierID": supplierID,
                "ContactID": contactID,
                "PackageServiceID": packageService.id,
                "TendererPackageServiceID": uniqueID
            };

            fetch(`./Payment/RemoveBasketItem`, {
                method: 'POST',
                headers: authHeader(false, false),
                body: JSON.stringify(removeData)
            })
                .then(response => response.json())
                .then(data => {
                    if (data) {
                        let updatedSearches = [...savedSearches];
                        var index = updatedSearches.findIndex(x => x.uniqueID === uniqueID);
                        updatedSearches[index]["status"] = 1;
                        setSavedSearches(updatedSearches);
                    }
                })
                .catch(err => {
                    console.log("ERROR", err);
                })

        }
    }

    const addSearchToBasket = (uniqueID, searchTitle) => {
        let searchData = {
            "PackageServiceID": packageService.id,
            "TendererPackageServiceID": uniqueID,
            "Description": searchTitle,
            "PriceExcVat": packageService.price
        };

        fetch(`./Payment/AddBasketItem?supplierID=${supplierID}&contactID=${contactID}`, {
            method: 'POST',
            headers: authHeader(false, false),
            body: JSON.stringify(searchData)
        })
            .then(response => response.json())
            .then(data => {
                if (data > 0) {
                    let updatedSearches = [...savedSearches];
                    var index = updatedSearches.findIndex(x => x.uniqueID === uniqueID);
                    updatedSearches[index]["status"] = 2;
                    setSavedSearches(updatedSearches);
                }
            })
            .catch(err => {
                console.log("ERROR", err);
            })
    }

    const linkSearchToAccount = (uniqueID, searchTitle) => {
        let searchData = {
            "SupplierID": supplierID,
            "ContactID": contactID,
            "PackageServiceID": packageService.id,
            "TendererPackageServiceID": uniqueID
        };

        getApplicationType()
            .then(application => {
                fetch(`./Suppliers/LinkSearchToAccount?systemName=${application}`, {
                    method: 'POST',
                    headers: authHeader(false, false),
                    body: JSON.stringify(searchData)
                })
                    .then(response => response.json())
                    .then(data => {
                        if (data) {
                            let updatedSearches = [...savedSearches];
                            var index = updatedSearches.findIndex(x => x.uniqueID === uniqueID);
                            updatedSearches[index]["status"] = 0;
                            setSavedSearches(updatedSearches);
                            handleTimedSuccess('success', 'Save Search Purchased', `The Saved Search - <b>${searchTitle}</b> - has been purchased using your accounts available Save Search balance.`)
                        } else {
                            showCustomError("A problem occurred while retrieving your search, please try again.", "Cannot link search", "error")
                        }
                    })
                    .catch(err => {
                        console.log("ERROR", err);
                    })
            });
    }

    const handleSelectSearch = (uniqueID, searchTitle) => {
        fetch(`./Suppliers/SupplierHasAvailableAllowance?supplierID=${supplierID}&packageService=pservice_savesearches`, {
            method: 'GET',
            headers: authHeader(false, false)
        })
            .then(allowanceResponse => allowanceResponse.json())
            .then(allowanceData => {
                if (allowanceData && allowanceData > 0) {
                    handleConfirm(
                        `You currently have ${allowanceData} search allowance - would you like to use your search allowance and continue?`,
                        function () {
                            linkSearchToAccount(uniqueID, searchTitle)
                        }
                    );

                } else {
                    addSearchToBasket(uniqueID, searchTitle)
                }
            });
    }

    const deleteSavedSearch = (searchID) => {
        getApplicationType()
            .then(application => {
                fetch(`./Suppliers/DeleteSuppliersTenderSearch?systemName=${application}&supplierID=${supplierID}&searchID=${searchID}`, {
                    method: 'GET',
                    headers: authHeader(false, false)
                })
                    .then(response => response.json())
                    .then(data => {
                        if (data.success === true) {
                            let updateSearches = [...savedSearches];
                            var index = updateSearches.findIndex(x => x.searchID === searchID);
                            updateSearches.splice([index], 1);

                            setSavedSearches(updateSearches);

                            handleTimedSuccess('success', 'Deleted', `Search deleted.`)
                        } else {
                            showError(data.error, data.message);
                        }
                    })
                    .catch(err => {
                        console.log("ERROR", err);
                    });
            });
    };


    const handleDelete = (searchID, searchTitle) => {
        if (searchID && searchID !== "") {
            handleConfirm(
                `Are you sure you wish to delete the saved search '${searchTitle}'?`,
                function () {
                    deleteSavedSearch(searchID);
                }
            );
        };
    }

    if (supplierID > 0) {
        return (
            <>
                <Card className={CardClass} >
                    <CardHeader className="ic-save">
                        <span>
                            <Translation ID={PanelName} />
                        </span>
                    </CardHeader>
                    <CardBody className='padding-5-1 card-module'>
                        <Card className="card-success">
                            <CardBody className={'padding-0-10'} style={{ maxHeight: "420px", overflow: "auto" }}>
                                <table id='TendererTenderFeedSearchesTable' className='table'>
                                    <thead style={{ position: "sticky", top: "0", background: "var(--card-bg-color)" }}>
                                        <tr>
                                            <th style={{ borderBottom: "0", borderTop: "0", fontWeight: "500", padding: "0", width: "95%" }}><span style={{ display: "block", padding: "0.5rem 0.75rem", borderBottom: "0.0625rem solid var(--border-color)" }} >Saved Search</span></th>
                                            <th style={{ borderBottom: "0", borderTop: "0", fontWeight: "500", padding: "0", width: "5%" }}><span style={{ display: "block", padding: "0.5rem 0.75rem", borderBottom: "0.0625rem solid var(--border-color)" }} >Action</span></th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {Object.keys(savedSearches).length > 0 &&
                                            <>
                                                {savedSearches.map((search, i) => {
                                                    return (
                                                        <tr key={i} className={i % 2 === 0 ? "odd" : "even"}>
                                                            <td>
                                                                {search.status === 0 &&
                                                                    <a
                                                                        href={`/save-search/${search.searchID}`}
                                                                        onClick={(e) => { e.preventDefault(); history.push(`/tenders?searchid=${search.searchID}`) }}
                                                                        style={{ color: '#00A65A' }}>
                                                                        {search.searchDescription}
                                                                    </a>
                                                                }
                                                                {search.status !== 0 &&
                                                                    <span style={{ color: '#757575', fontStyle: 'italic' }}>
                                                                        {search.searchDescription}
                                                                    </span>
                                                                }
                                                            </td>
                                                            <td>
                                                                {search.status === 0 && userPermissions.canDeleteSearch &&
                                                                    <button id={`delete-${search.searchID}`} className="c-tender-actions__action-button c-tender-actions__action-button--bin" onClick={() => handleDelete(search.searchID, search.searchDescription)}>Delete Search</button>
                                                                }
                                                                {search.status === 1 &&
                                                                    <button className="c-tender-actions__action-button c-tender-actions__action-button--add-to-basket" disabled={!userPermissions.canPurchaseProducts} title='Account registered via another Sell2 system. Requires purchasing' onClick={() => handleSelectSearch(search.uniqueID, search.searchDescription)}>
                                                                        Add to basket
                                                                    </button>
                                                                }
                                                                {search.status === 2 && userPermissions.canPurchaseProducts &&
                                                                    <Button size="sm" color='link' className='c-text-link' onClick={(() => handleRemoveFromBasket(search.uniqueID))} >
                                                                        Remove from basket
                                                                    </Button>
                                                                }
                                                                {search.status === 3 &&
                                                                    <button className="c-tender-actions__action-button c-tender-actions__action-button--upgrade" onClick={() => history.push(`/upgrade`)}>
                                                                        Upgrade
                                                                    </button>
                                                                }
                                                            </td>
                                                        </tr>
                                                    )
                                                })}
                                            </>
                                        }

                                        {Object.keys(savedSearches).length === 0 &&
                                            <EmptyTableRow />
                                        }
                                    </tbody>
                                </table>
                            </CardBody>
                        </Card>
                    </CardBody>
                </Card>
            </>
        )
    } else {
        return null;
    }
}

export default SavedSearches;