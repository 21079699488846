import React, { useContext, useEffect, useState } from 'react';
import { useLocation } from "react-router-dom";
import { authHeader } from '../../helpers/auth-header';
import { getApplicationType, getContactID, getSupplierID, getVirtualDirectory, handleBackground, removeCookie, setSessionVal } from '../../helpers/general';
import { history } from '../../helpers/history';
import Login from '../Auth/Login';
import Footer from "../layout/Footer";
import MenuContext from '../layout/MenuContext';
import NavMenu from '../layout/NavMenu';

const PaymentSuccessful = () => {
    let location = useLocation();
    const { setNavMenu } = useContext(MenuContext);
    const [user, setUser] = useState({ supplierID: -1, contactID: -1 });

    useEffect(() => {
        handleBackground();
        let supplierID = getSupplierID();
        let contactID = getContactID();

        setUser(prev => ({
            ...prev,
            supplierID: supplierID,
            contactID: contactID
        }));

        if (supplierID > 0) {
            // Check if the user is still allowed in (happens after after successful renewal payment)
            fetch(`./SupplierUsers/CheckUserAccountStatus?supplierID=${supplierID}&contactID=${contactID}`, {
                method: 'GET',
                headers: authHeader()
            })
                .then(response => response.json())
                .then(data => {
                    if (data !== true) {
                        removeCookie(`token-${getVirtualDirectory()}`);
                        setSessionVal('TendersPageNumber', 1); // Reset the page number for Tenders when you log out (to reset if the user viewed private tenders)
                    }
                });
        }

        fetch(`./NavMenu/GetNavBar?supplierID=${supplierID}`, {
            method: 'GET',
            headers: authHeader(false, true)
        })
            .then(response => response.json())
            .then(nav => {
                setNavMenu(nav);
            });

    }, [setNavMenu]);

    return (
        <>
            <NavMenu />
            <div className="main-content u-container u-container--small container-fluid">
                <div className="u-flex-center u-component-spacing">
                    <h2 className="u-component-spacing u-branded-underline">Payment Successful</h2>
                </div>

                <div className="row u-component-spacing">
                    <div className="col-md u-component-spacing--2x">
                        <div className="card main-content__inner" style={{ height: "calc(100% - 1rem)" }} >
                            <div className="card-body">
                                <div className="c-card card u-component-spacing" style={{ height: "calc(100% - 1rem)" }}>
                                    <div className="card-body">
                                        {user.supplierID === 0 &&
                                            <>
                                                <h3 className="c-broken-underline u-component-spacing">Registration Complete</h3>
                                                <div className="c-form u-component-spacing--2x">
                                                    <p>
                                                        Your payment has been successful, and your account has been completed.  You can now log in below.
                                                    </p>
                                                    <p>
                                                        Your Invoice Number is <strong>{location.state.invoiceNo}</strong>.
                                                    </p>
                                                    <div className="c-login-forms__form u-component-spacing">
                                                        <Login />
                                                    </div>
                                                </div>
                                            </>
                                        }
                                        {user.supplierID > 0 &&
                                            <>
                                                <h3 className="c-broken-underline u-component-spacing">Thank you for your payment</h3>
                                                <div className="c-form u-component-spacing--2x">
                                                    <p>
                                                        Your payment has been successful.
                                                    </p>
                                                    <p>
                                                        Your Invoice Number is  <strong>{location.state.invoiceNo}</strong>.  You can view your invoices by clicking <a style={{ fontWeight: "bold", textDecoration: "underline" }} href='/invoices' onClick={(e) => { e.preventDefault(); history.push({ pathname: '/invoices'}) }} >HERE</a> - or you can view your invoices at any time by accessing the Invoices page, (Account -{'>'} Invoices).
                                                    </p>
                                                </div>
                                            </>
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </>
    )
}

export default PaymentSuccessful;