import React, { useCallback, useEffect, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { Button, Input } from "reactstrap";
import { authHeader } from '../../helpers/auth-header';
import { getApplicationType, getContactID, getSessionVal, getSupplierID, handleConfirm, handleTimedSuccess, logIntoPortal, pageEnabled, registerOnPortal, setSessionVal, showCustomError } from '../../helpers/general';
import { history } from '../../helpers/history';
import ScrollArrow from '../controls/ScrollArrow';
import Select2 from "../controls/Select2";
import Translation from '../controls/Translation';
import SpinnerOverlay from '../layout/SpinnerOverlay';

const PortalList = () => {
    const [user, setUser] = useState({ supplierID: -1, contactID: -1 });
    const [regions, setRegions] = useState([]);
    const [portals, setPortals] = useState([]);
    const [isSearching, setIsSearching] = useState(true);
    const [portalSearchParams, setPortalSearchParams] = useState({});
    const [finalSearchParams, setFinalSearchParams] = useState({ searchObject: { organisationName: '', region: -1, systemName: '' } });
    const [packagesEnabled, setPackagesEnabled] = useState(false);
    const [packageService, setPackageService] = useState({ id: 0, price: 0 });
    const [portalPermissions, setPortalPermissions] = useState({ canRegisterPortal: false, canPurchaseProducts: false })
    const [isPageEnabled, setIsPageEnabled] = useState(false);
    const [pageConfig, setPageConfig] = useState(null);
    const [pageNumber, setPageNumber] = useState(1);
    const [totalPages, setTotalPages] = useState(0);

    let location = useLocation();

    useEffect(() => {
        setUser(prev => ({
            ...prev,
            supplierID: getSupplierID(),
            contactID: getContactID()
        }));

        return () => {
            setUser({ supplierID: -1, contactID: -1 });
        }
    }, []);

    useEffect(() => {
        getApplicationType()
            .then(applicationName => {
                if (location.state && location.state.portalSearchObject) {

                    setPortalSearchParams(location.state.portalSearchObject);
                    setFinalSearchParams({ searchObject: location.state.portalSearchObject });

                } else {
                    setPortalSearchParams({ organisationName: '', region: -1, systemName: applicationName });
                    setFinalSearchParams({ searchObject: { organisationName: '', region: -1, systemName: applicationName } });
                }
            })
    }, [location.state]);

    useEffect(() => {
        if (user.supplierID > 0) {
            fetch(`./Packages/GetTendererPackagePageConfig?supplierID=${user.supplierID}&pageConfig=PortalListConfig`, {
                method: 'GET',
                headers: authHeader()
            })
                .then(response => response.json())
                .then(data => {
                    setPageConfig(data);
                });
        }
    }, [user.supplierID]);

    const getConfig = useCallback((field, option, optionDefault) => {
        if (pageConfig !== null && pageConfig !== undefined) {
            if (pageConfig.length > 0) {
                let config = pageConfig.find(x => x.field === field);

                if (config && config !== undefined && config.options) {
                    return config.options[option]
                }
            } else {
                return optionDefault;
            }
        } else {
            return null;
        }
    }, [pageConfig])


    const getAdditionalConfig = useCallback((setting, optionDefault) => {
        if (pageConfig !== null && pageConfig !== undefined) {
            if (pageConfig.length > 0) {
                var additionalOptions = pageConfig.find(x => x.field === 'additionalOptions');
                if (additionalOptions) {
                    let config = additionalOptions['miscConfigOptions'].find(x => x.configName === setting);
                    if (config && config !== undefined) {
                        return config.configValue;
                    }
                }
            } else {
                return optionDefault;
            }
        } else {
            return null;
        }
    }, [pageConfig])


    useEffect(() => {
        if (pageConfig) {
            pageEnabled('Nav_Portals', '/404').then(enabled => { setIsPageEnabled(enabled); });

            fetch(`./Countries/GetTopLevelRegions?country=uk`, {
                method: 'GET',
                headers: authHeader(false, true)
            })
                .then(response => response.json())
                .then(data => {
                    let optionCount = getConfig('region', 'maxOptions', -1);
                    let vRegions = [];

                    if (optionCount === -1) {
                        vRegions.push({ label: "Show All", value: "-1" });
                        data.forEach(obj => vRegions.push({ label: obj.region, value: obj.code }));
                    } else {
                        vRegions.push({ label: getConfig('region', 'defaultText'), value: "-1" });
                    }

                    setRegions(vRegions);
                })
                .catch(error => console.log('Error: ', error));

            fetch(`./Settings/ArePackagesEnabled`, {
                method: 'GET',
                headers: authHeader(false, true)
            })
                .then(response => response.json())
                .then(data => {
                    setPackagesEnabled(data)
                })
                .catch(err => {
                    console.log(err);
                })

            fetch(`./Packages/GetPackageServiceDetails?packageService=pservice_portals`, {
                method: 'GET',
                headers: authHeader(false, false)
            })
                .then(response => response.json())
                .then(data => {
                    if (data) {
                        setPackageService({ id: data.uniqueID, price: data.priceExc })
                    }
                })
                .catch(err => {
                    console.log("ERROR", err);
                });
        }
    }, [getConfig, pageConfig, user]);

    const loadPortalList = useCallback(() => {
        if (getAdditionalConfig('portalList', 'true') === 'true') {
            
            fetch(`./Organisations/GetIntendPortals?page=${pageNumber}&strSearch=${JSON.stringify(finalSearchParams.searchObject)}&supplierID=${user.supplierID}&contactID=${user.contactID}`, {
                method: 'GET',
                headers: authHeader()
            })
                .then(response => response.json())
                .then(intendPortalData => {
                    setPortals(intendPortalData);

                    if (intendPortalData.length) {
                        let vTotal = intendPortalData[0].totalCount;
                        let vPages = vTotal / 20;
                        vPages = Math.ceil(vPages);
                        setTotalPages(vPages);
                    }

                    fetch(`./Permissions/PermissionsPortals?contactID=${user.contactID}`, {
                        method: 'GET',
                        headers: authHeader()
                    })
                        .then(response => response.json())
                        .then(permissionsData => {
                            if (permissionsData) {
                                setPortalPermissions({
                                    canRegisterPortal: permissionsData.canRegisterPortal,
                                    canPurchaseProducts: permissionsData.canPurchaseProducts
                                });
                            }

                            setIsSearching(false);
                        })
                        .catch(error => console.log('Error: ', error));
                }).catch(err => {
                    console.log("Error: ", err);
                })
        }
        else {
            setIsSearching(false);
        }
    }, [finalSearchParams.searchObject, getAdditionalConfig, pageNumber, user.contactID, user.supplierID])


    const linkPortalToAccount = useCallback(portalID => {
        let searchData = {
            "SupplierID": user.supplierID,
            "ContactID": user.contactID,
            "PackageServiceID": packageService.id,
            "TendererPackageServiceID": portalID
        };

        getApplicationType()
            .then(application => {
                fetch(`./Suppliers/LinkPortalToAccount?systemName=${application}`, {
                    method: 'POST',
                    headers: authHeader(false, false),
                    body: JSON.stringify(searchData)
                })
                    .then(response => response.json())
                    .then(data => {
                        if (data) {
                            handleTimedSuccess('success', 'Account Linked', `Your account has been linked - portal allowance has been used.`);
                            loadPortalList();
                        } else {
                            showCustomError("A problem occurred while linking your account, please try again.", "Cannot link account", "error")
                        }
                    })
                    .catch(err => {
                        console.log("ERROR", err);
                    })
            });
    }, [loadPortalList, packageService.id, user.contactID, user.supplierID])


    const refreshPortals = useCallback(() => {
        if (pageConfig) {
            if (user.supplierID > 0 && finalSearchParams.searchObject.systemName !== '' && isSearching === true) {
                sessionStorage.removeItem('registeredOnPortal');
                loadPortalList();
            }
        }
    }, [finalSearchParams.searchObject.systemName, isSearching, loadPortalList, pageConfig, user.supplierID])


    useEffect(() => {
        refreshPortals()
    }, [refreshPortals])



    useEffect(() => {
        window.addEventListener('registeredOnPortal', function () { refreshPortals() })

        return function cleanup() {
            window.removeEventListener('registeredOnPortal', refreshPortals);
        }
    }, [refreshPortals])


    const searchPortals = () => {
        setFinalSearchParams({ searchObject: portalSearchParams });
        setIsSearching(true);
    }

    const handleEnterKey = (e) => {
        if (e.key === 'Enter') {
            searchPortals();
        }
    }

    const handleChange = e => {
        e.persist();
        setPortalSearchParams(portalSearchParams => ({
            ...portalSearchParams,
            [e.target.name]: e.target.type === 'checkbox' ? e.target.checked : encodeURIComponent(e.target.value)
        }));
    }


    const handleSelect2Change = e => {
        e.persist();
        setPortalSearchParams(portalSearchParams => ({
            ...portalSearchParams,
            [e.target.id]: e.target.value
        }));
    }


    const handleViewChange = (activeClass, button, resultsList) => {
        // Removes the Active class from who ever has it
        const activeButton = document.querySelector('.' + activeClass);
        if (activeButton) {
            activeButton.classList.remove(activeClass);
        }

        button.classList.add(activeClass);

        // Results list, not the buttons
        resultsList.dataset.viewMode = button.dataset.viewMode;
        setSessionVal('PortalsViewMode', button.dataset.viewMode);
    }


    useEffect(() => {
        const resultsList = document.querySelector('.js-results-list');
        const viewChangerButtons = document.querySelectorAll('.js-view-changer');
        const activeClass = 'c-view-changer__button--active';

        const viewMode = getSessionVal('PortalsViewMode') ?? 'list';

        Array.from(viewChangerButtons).forEach(button => {
            if (button.dataset.viewMode === viewMode) {
                button.classList.add(activeClass);
                resultsList.dataset.viewMode = viewMode;
            }

            button.addEventListener('click', function () { handleViewChange(activeClass, button, resultsList) });

            return function cleanup() {
                button.removeEventListener('click', handleViewChange);
            }
        });
    });


    const handleGoToPortal = (registered, url, portalID, portalName) => {
        // portalID here is the organisation you are viewing
        logIntoPortal(registered, url, user.supplierID, user.contactID, -1, portalID, portalName)
    }


    const changePortalStatus = (uniqueID, status) => {
        let updatedPortals = portals.map(x => {
            return {
                portals: x.portals.map(y => {
                    if (y.uniqueID === uniqueID) {
                        y.status = status;
                    }
                    return y;
                }),
                primaryPortalLetter: x.primaryPortalLetter,
                totalCount: x.totalCount
            }
        });

        setPortals(updatedPortals)
    }


    const handleRemoveFromBasket = uniqueID => {
        if (uniqueID) {
            let removeData = {
                "SupplierID": user.supplierID,
                "ContactID": user.contactID,
                "PackageServiceID": packageService.id,
                "TendererPackageServiceID": uniqueID
            };

            fetch(`./Payment/RemoveBasketItem`, {
                method: 'POST',
                headers: authHeader(false, false),
                body: JSON.stringify(removeData)
            })
                .then(response => response.json())
                .then(data => {
                    if (data) {
                        changePortalStatus(uniqueID, 1)
                    }
                })
                .catch(err => {
                    console.log("ERROR", err);
                });
        }

        return false;
    }


    const addPortalToBasket = (uniqueID, portalName) => {
        let searchData = {
            "PackageServiceID": packageService.id,
            "TendererPackageServiceID": uniqueID,
            "Description": portalName,
            "PriceExcVat": packageService.price
        };

        fetch(`./Payment/AddBasketItem?supplierID=${user.supplierID}&contactID=${user.contactID}`, {
            method: 'POST',
            headers: authHeader(false, false),
            body: JSON.stringify(searchData)
        })
            .then(response => response.json())
            .then(data => {
                if (data > 0) {
                    changePortalStatus(uniqueID, 2)
                }
            })
            .catch(err => {
                console.log("ERROR", err);
            });
    }


    const handleRegisterOnPortal = (portalID, portalName) => {
        getApplicationType()
            .then(application => {
                if (packagesEnabled) {
                    fetch(`./Suppliers/SupplierHasAvailableAllowance?supplierID=${user.supplierID}&packageService=pservice_portals`, {
                        method: 'GET',
                        headers: authHeader(false, false)
                    })
                        .then(allowanceResponse => allowanceResponse.json())
                        .then(allowanceData => {
                            if (allowanceData && allowanceData > 0) {
                                handleConfirm(
                                    `You currently have ${allowanceData} portal allowance - would you like to use your portal allowance and register?`,
                                    function () {
                                        registerOnPortal(portalID, portalName, user.supplierID, user.contactID, application)
                                            .then(() => {
                                                loadPortalList();
                                            });
                                    });

                            } else {
                                registerOnPortal(portalID, portalName, user.supplierID, user.contactID, application)
                                    .then(regResponse => {
                                        if (regResponse) {
                                            linkPortalToAccount(portalID)
                                            loadPortalList()
                                        }
                                    })
                            }
                        });
                } else {
                    registerOnPortal(portalID, portalName, user.supplierID, user.contactID, application);
                }
            });
    }


    const handleSelectPortal = (portalID, portalName, isRegistered, status) => {
        getApplicationType()
            .then(application => {
                // If Unlimited package
                if (status === 5) {
                    if (isRegistered) {
                        linkPortalToAccount(portalID, portalName, 0)
                    } else {
                        registerOnPortal(portalID, portalName, user.supplierID, user.contactID, application);
                    }
                } else {
                    // Otherwise check allowance
                    fetch(`./Suppliers/SupplierHasAvailableAllowance?supplierID=${user.supplierID}&packageService=pservice_portals`, {
                        method: 'GET',
                        headers: authHeader(false, false)
                    })
                        .then(allowanceResponse => allowanceResponse.json())
                        .then(allowanceData => {
                            if (allowanceData && allowanceData > 0) {
                                handleConfirm(
                                    `You currently have ${allowanceData} portal allowance - would you like to use your portal allowance and continue?`,
                                    function () {
                                        if (isRegistered) {
                                            linkPortalToAccount(portalID, portalName, 0)
                                        } else {
                                            registerOnPortal(portalID, portalName, user.supplierID, user.contactID, application);
                                        }
                                    }
                                )
                            } else {
                                addPortalToBasket(portalID, portalName)
                            }
                        })
                }
            });
    }

    useEffect(() => {
        setPageNumber(parseInt(getSessionVal('TendersPageNumber') ?? 1));
    }, [setPageNumber]);

    const disableNextPrevious = (vIsNext) => {
        if (vIsNext) {
            return pageNumber >= totalPages;
        } else {
            return pageNumber <= 1; // Are we on the first page - if so, disabled the previous button
        }
    }

    const handleNextPrevious = (vIsNext) => {
        if (vIsNext) {
            setPageNumber(prevPage => prevPage + 1);
        } else {
            setPageNumber(prevPage => prevPage - 1);
        }

        setIsSearching(true);
        window.scrollTo(0, 0);
    }


    if (isPageEnabled === true) {
        return (
            <main>
                <section className="c-search-section u-box-shadow">
                    <div className="u-container">
                        <div className="c-search-section__inner c-search-section__inner--inline">
                            <div className="u-flex-column">
                                <Translation ID='Organisation' StringOnly={false} required={false} customClass={'c-search-section__label'} htmlFor='organisationName' />
                                <Input
                                    id="organisationName"
                                    name="organisationName"
                                    className="c-form__input"
                                    type="text"
                                    defaultValue={portalSearchParams.organisationName}
                                    disabled={getConfig('organisationName', 'disabled', false)}
                                    onChange={(e) => handleChange(e)} onKeyUp={(e) => handleEnterKey(e)}
                                    placeholder={getConfig('organisationName', 'defaultText', '')} />
                            </div>

                            {regions.length > 0 &&
                                <div>
                                    <Translation ID='Region' StringOnly={false} required={false} customClass={'c-search-section__dropdown'} htmlFor='region' />
                                    <Select2
                                        id='region'
                                        options={regions}
                                        disabled={getConfig('region', 'disabled', false)}
                                        defaultValue={portalSearchParams.region}
                                        change={(e) => handleSelect2Change(e)}
                                    />
                                </div>
                            }

                            {getAdditionalConfig('search') === 'true' &&
                                <div className="c-search-section__submit-buttons u-component-spacing" style={{ justifyContent: 'flex-start' }}>
                                    <input className="c-search-section__submit" type="submit" value={'Search'} onClick={() => searchPortals()} />
                                </div>
                            }
                        </div>

                    </div>
                </section>

                <div className="main-content u-container">
                    {portals.length > 0 && getAdditionalConfig('portalList', 'false') === 'true' &&
                        <div className="c-list-header">
                            <div className="c-list-header__content">
                                <h1 className="c-page-title u-component-spacing--2x">In-tend Organisations</h1>
                                <p>
                                    {
                                        portals.length > 0 ? `${portals[0].totalCount} results found` : ''
                                    }
                                    {
                                        portals.length > 0 ? ` - Showing ${(pageNumber - 1) * 20 + 1} to ${(pageNumber * 20) >= portals[0].totalCount ? portals[0].totalCount : pageNumber * 20}` : ''
                                    }
                                </p>
                            </div>
                        </div>
                    }

                    {getAdditionalConfig('portalList', 'true') !== 'true' &&
                        <div className="u-component-spacing">
                            <div style={{ textAlign:"center" }} className="c-results js-results-list">
                                <section className="c-results-list">
                                    <h2>Portal List available with Subscription Only</h2>
                                </section>

                                <button style={{ display: "unset", marginBottom:"1em" }} className="c-tender-actions__action-button c-tender-actions__action-button--100 c-tender-actions__action-button--upgrade u-component-spacing--2x" title='Package Upgrade Required' onClick={() => history.push(`/upgrade`)}>
                                        Upgrade
                                </button>
                            </div>
                        </div>
                    }
                    {getAdditionalConfig('portalList', 'true') === 'true' &&
                        <div className="u-component-spacing">
                            <div className="c-list-pagination">
                                <button className="c-list-pagination__button c-list-pagination__button--previous" onClick={() => handleNextPrevious(false)} hidden={disableNextPrevious(false)}>Previous</button>
                                <button className="c-list-pagination__button c-list-pagination__button--next" onClick={() => handleNextPrevious(true)} hidden={disableNextPrevious(true)}>Next</button>
                            </div>

                            <div data-view-mode="list" className="c-results js-results-list">
                                {portals && Object.keys(portals).length > 0 &&
                                    <div className="c-view-changer">
                                        <p>Display mode:</p>
                                        <button className="c-view-changer__button js-view-changer" data-view-mode="list">
                                            <svg className="c-view-changer__button-icon" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 18 18" width="14" height="14">
                                                <path className="c-view-changer__button-icon-path" d="M18 7.999a.998.998 0 0 0-.998-.998H.998A.998.998 0 0 0 0 7.999v2.002c0 .552.447.998.998.998h16.004a.998.998 0 0 0 .998-.998V7.999ZM18 .998A.998.998 0 0 0 17.002 0H.998A.998.998 0 0 0 0 .998V3c0 .552.447.998.998.998h16.004A.998.998 0 0 0 18 3V.998ZM18 15a.998.998 0 0 0-.998-.998H.998A.998.998 0 0 0 0 15v2.002c0 .552.447.998.998.998h16.004a.998.998 0 0 0 .998-.998V15Z"></path>
                                            </svg>
                                            <span className="c-view-changer__button-text">List</span>
                                        </button>
                                        <button className="c-view-changer__button js-view-changer" data-view-mode="grid">
                                            <svg className="c-view-changer__button-icon" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 22 22" width="14" height="14">
                                                <path className="c-view-changer__button-icon-path" d="M9.435 1.794C9.435.804 8.63 0 7.64 0H1.794C.804 0 0 .804 0 1.794V7.64c0 .991.804 1.795 1.794 1.795H7.64c.991 0 1.795-.804 1.795-1.795V1.794ZM22 1.794C22 .804 21.197 0 20.206 0h-5.847c-.99 0-1.794.804-1.794 1.794V7.64c0 .991.804 1.795 1.794 1.795h5.847c.99 0 1.794-.804 1.794-1.795V1.794ZM9.435 14.36c0-.991-.804-1.795-1.795-1.795H1.794C.804 12.565 0 13.37 0 14.36v5.847C0 21.196.804 22 1.794 22H7.64c.991 0 1.795-.803 1.795-1.794v-5.847ZM22 14.36c0-.991-.803-1.795-1.794-1.795h-5.847c-.99 0-1.794.804-1.794 1.794v5.847c0 .99.804 1.794 1.794 1.794h5.847c.99 0 1.794-.803 1.794-1.794v-5.847Z"></path>
                                            </svg>
                                            <span className="c-view-changer__button-text">Grid</span>
                                        </button>
                                    </div>
                                }

                                {portals.map((collection, i) => {
                                    return (
                                        <section className="c-results-list" key={i}>
                                            <h2 className="c-results-list__heading">{collection.primaryPortalLetter}</h2>
                                            <ol className="c-results-list__list u-component-spacing">
                                                {collection.portals.map((portal, j) => {
                                                    return (
                                                        <li className="c-results-list__list-item" key={j}>
                                                            <div className="c-result">
                                                                <Link to={{ pathname: '/organisation', search: `?oid=${portal.uniqueID}`, state: { portalSearchObject: portalSearchParams, prevLocation: location.pathname } }} className="c-result__content">
                                                                    <h3 className="c-result__heading" dangerouslySetInnerHTML={{ __html: portal.entityName }} />

                                                                    <dl className="c-result__summary u-component-spacing">
                                                                        <div className="c-result__summary-item c-result__summary-item--location">
                                                                            <dt className="c-result__summary-term">Address:</dt>
                                                                            <dd className="c-result__summary-definition">
                                                                                <div className="c-address">
                                                                                    {portal.building && <span className="c-address__address-line">{portal.building}</span>}
                                                                                    {portal.street && <span className="c-address__address-line">{portal.street}</span>}
                                                                                    {portal.town && <span className="c-address__address-line">{portal.town}</span>}
                                                                                    {portal.county && <span className="c-address__address-line">{portal.county}</span>}
                                                                                    {portal.country && <span className="c-address__address-line">{portal.country}</span>}
                                                                                    {portal.postcode && <span className="c-address__address-line">{portal.postcode}</span>}
                                                                                </div>
                                                                            </dd>
                                                                        </div>
                                                                        <div className="c-result__summary-item c-result__summary-item--phone">
                                                                            <dt className="c-result__summary-term">Phone:</dt>
                                                                            <dd className="c-result__summary-definition">{portal.telephone}</dd>
                                                                        </div>
                                                                        <div className="c-result__summary-item c-result__summary-item--website">
                                                                            <dt className="c-result__summary-term">Website:</dt>
                                                                            <dd className="c-result__summary-definition">{portal.website}</dd>
                                                                        </div>
                                                                    </dl>
                                                                </Link>

                                                                <div className="c-result__footer">
                                                                    <Link to={{ pathname: '/organisation', search: `?oid=${portal.uniqueID}` }} className="c-result__prompt">
                                                                        Find out more
                                                                    </Link>
                                                                    <div className="c-tender-actions">
                                                                        {/* STATUS = 0: Purchase not required */}
                                                                        {portal.status === 0 &&
                                                                            <>
                                                                                {portal.registerControlStatus > 0 && !portal.registered && (portal.registerControlStatus === 1 || !packagesEnabled) && portalPermissions.canRegisterPortal &&
                                                                                    <button id='register' className="c-tender-actions__action-button c-tender-actions__action-button--register" onClick={() => { handleRegisterOnPortal(portal.uniqueID, portal.entityName, user.supplierID, user.contactID) }}>
                                                                                        Register with Organisation
                                                                                    </button>
                                                                                }

                                                                                {portal.registered &&
                                                                                    <button className="c-cta" onClick={() => { handleGoToPortal(portal.registered, portal.portalURL, portal.uniqueID, portal.entityName) }}>
                                                                                        Log in to supplier portal
                                                                                    </button>
                                                                                }
                                                                            </>
                                                                        }

                                                                        {/* STATUS = 1: Supplier has no allowance, requires portal purchase, BASKET icon */}
                                                                        {portal.status === 1 &&
                                                                            <button className="c-tender-actions__action-button c-tender-actions__action-button--add-to-basket" disabled={!portalPermissions.canPurchaseProducts} onClick={(() => handleSelectPortal(portal.uniqueID, portal.entityName, portal.registered))}>
                                                                                Add to basket
                                                                            </button>
                                                                        }

                                                                        {/* STATUS = 2: Portal already in the basket, BASKET REMOVE icon*/}
                                                                        {portal.status === 2 &&
                                                                            <>
                                                                                <Button size="sm" color='link' className='c-text-link' disabled={!portalPermissions.canPurchaseProducts} onClick={(() => handleRemoveFromBasket(portal.uniqueID))} >
                                                                                    Remove from basket
                                                                                </Button>
                                                                                <button id={`removeBasketPortal-${portal.uniqueID}`} className="c-tender-actions__action-button c-tender-actions__action-button--view-basket" disabled={!portalPermissions.canPurchaseProducts} onClick={() => history.push(`/basket`)}>
                                                                                    View basket
                                                                                </button>
                                                                            </>
                                                                        }

                                                                        {/* STATUS = 3: Free account, requires account upgrade, GREEN ARROW icon*/}
                                                                        {portal.status === 3 &&
                                                                            <button className="c-tender-actions__action-button c-tender-actions__action-button--upgrade" title='Package Upgrade Required' onClick={() => history.push(`/upgrade`)}>
                                                                                Upgrade
                                                                            </button>
                                                                        }

                                                                        {/* STATUS = 4: Supplier has available portal allowance, portal allowance to be used - already registered on another Sell2, Link account.  LINK button 
                                                                        STATUS = 5: Unlimited balance */}
                                                                        {(portal.status === 4 || portal.status === 5) && portal.registered && portalPermissions.canRegisterPortal &&
                                                                            <button className="c-tender-actions__action-button c-tender-actions__action-button--link" onClick={() => { handleSelectPortal(portal.uniqueID, portal.entityName, portal.registered, portal.status) }}>
                                                                                Link Existing Sell2 Registration
                                                                            </button>
                                                                        }

                                                                        {/* STATUS = 4: Supplier has available portal allowance, portal allowance to be used - needs to register.  LINK button
                                                                        STATUS = 5: Unlimited balance */}
                                                                        {(portal.status === 4 || portal.status === 5) && !portal.registered && portalPermissions.canRegisterPortal &&
                                                                            <button id='register' className="c-tender-actions__action-button c-tender-actions__action-button--register" onClick={() => { handleRegisterOnPortal(portal.uniqueID, portal.entityName, user.supplierID, user.contactID) }}>
                                                                                Register with Organisation
                                                                            </button>
                                                                        }

                                                                        {/* STATUS = 6: Portal has been purchased, but not registered with yet, REGISTER button */}
                                                                        {portal.status === 6 && portalPermissions.canRegisterPortal &&
                                                                            <button id='register' className="c-tender-actions__action-button c-tender-actions__action-button--register" onClick={() => { handleRegisterOnPortal(portal.uniqueID, portal.entityName, user.supplierID, user.contactID) }}>
                                                                                Register with Organisation
                                                                            </button>
                                                                        }

                                                                        {!portal.registered && portal.status !== 3 &&
                                                                            <button className="c-tender-actions__action-button c-tender-actions__action-button--portal-link" onClick={() => { handleGoToPortal(portal.registered, portal.portalURL, portal.entityName) }}>
                                                                                Go to supplier portal
                                                                            </button>
                                                                        }
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </li>
                                                    )
                                                })
                                                }
                                            </ol>
                                        </section>
                                    )
                                })}

                                <ScrollArrow />

                                {isSearching &&
                                    <SpinnerOverlay />
                                }
                            </div>



                            <div className="c-list-pagination">
                                <button className="c-list-pagination__button c-list-pagination__button--previous" onClick={() => handleNextPrevious(false)} hidden={disableNextPrevious(false)}>Previous</button>
                                <button className="c-list-pagination__button c-list-pagination__button--next" onClick={() => handleNextPrevious(true)} hidden={disableNextPrevious(true)}>Next</button>
                            </div>
                        </div>
                    }
                </div>
            </main>
        );
    } else {
        return null;
    }
}

export default PortalList;