import React from 'react';
import 'bootstrap-datepicker/dist/css/bootstrap-datepicker.min.css';
import Translation from './Translation';
import { Link } from 'react-router-dom';
import { CardHeader } from 'reactstrap';

const BackToContracts = () => {
    return (
        <CardHeader className="card-header card-header--no-icon">
            <div>
                <Link to='/contracts' className="c-back-button">
                    <Translation ID="BackToContracts" />
                </Link>
            </div>
        </CardHeader>
    )
}

export default BackToContracts;