import React, { useCallback, useEffect, useState } from "react";
import { PropTypes } from 'prop-types';
import { Input } from "reactstrap";
import { getFilters } from '../../helpers/general';
import "bootstrap/dist/css/bootstrap.css";
import Select2 from "../controls/Select2";
import Translation from '../controls/Translation';

require('datatables.net-bs4');

const PublicTenderSearch = props => {
    let { setIsSearching, searchParams, setSearchParams } = props;

    const [comOrderBy, setComOrderBy] = useState([]);
    const [comOrderByDirection, setComOrderByDirection] = useState([]);


    const searchData = useCallback(() => {
        setIsSearching(true);
    }, [setIsSearching])

    useEffect(() => {
        getFilters(orderByOptions, false).then(arrComFilters => {
            setComOrderBy(arrComFilters);
        });

        getFilters(orderByDirectionOptions, false).then(arrComFilters => {
            setComOrderByDirection(arrComFilters);
        });
    }, []);


    const handleChange = e => {
        e.persist();
        let val = e.target.value;

        setSearchParams(prevState => ({
            ...prevState,
            searchObject: {
                ...prevState.searchObject,
                [e.target.name]: e.target.type === 'checkbox' ? e.target.checked : encodeURIComponent(val)
            }
        }));
    }


    const handleSelect2Change = e => {
        e.persist();

        setSearchParams(prevState => ({
            ...prevState,
            searchObject: {
                ...prevState.searchObject,
                [e.target.id]: e.target.value
            }
        }));
    }

    const handleEnterKey = (e) => {
        if (e.key === 'Enter') {
            searchData();
        }
    }

    if (searchParams && searchParams.searchObject) {
        return (
            <section className="c-search-section u-box-shadow">
                <div className="u-container">
                    <div className="c-search-section__inner c-search-section__inner--inline">
                        <div className="u-flex-column">
                            Keyword
                            <Input name="keywords" className="c-search-section__keyword-input u-flex-column__100pc" type="textbox" defaultValue={searchParams.searchObject.keywords} onChange={(e) => handleChange(e)} onKeyUp={(e) => handleEnterKey(e)} placeholder="Keyword" id="txtSearchKeywords" />
                        </div>

                        {comOrderBy.length > 0 &&
                            <div>
                                <Translation ID="OrderBy" customClass={'c-search-section__dropdown'} />
                                <Select2
                                    id='orderBy'
                                    options={comOrderBy}
                                    defaultValue={parseInt(searchParams.searchObject.orderBy) > 0 ? parseInt(searchParams.searchObject.orderBy) : 0}
                                    change={(e) => handleSelect2Change(e)}
                                    minimumResultsForSearch='Infinity'
                                />
                            </div>
                        }

                        {comOrderByDirection.length > 0 &&
                            <div>
                                <label className="c-search-section__label" htmlFor="orderByDirection">
                                    <Translation ID="OrderDirection" />
                                </label>
                                <Select2
                                    id='orderByDirection'
                                    options={comOrderByDirection}
                                    defaultValue={parseInt(searchParams.searchObject.orderByDirection) > 0 ? parseInt(searchParams.searchObject.orderByDirection) : 0}
                                    change={(e) => handleSelect2Change(e)}
                                />
                            </div>
                        }
                        <div className="c-search-section__submit-buttons u-component-spacing" style={{ justifyContent: 'flex-start' }}>
                            <input className="c-search-section__submit" type="submit" value={'Search'} onClick={searchData} />
                        </div>
                    </div>

                </div>
            </section>
        )
    } else {
        return null;
    }
}

const orderByOptions = [
    { label: "LISTCUSTOM_ORDERBY_DATEADDED", value: 0 },
    { label: "LISTCUSTOM_ORDERBY_EOI", value: 1 }
]

const orderByDirectionOptions = [
    { label: "LISTCUSTOM_ORDERBYDIRECTION_DESC", value: 0 },
    { label: "LISTCUSTOM_ORDERBYDIRECTION_ASC", value: 1 }
]

PublicTenderSearch.propTypes = {
    setSearchParams: PropTypes.func,
    setIsSearching: PropTypes.func,
    isSearching: PropTypes.bool,
}

PublicTenderSearch.defaultProps = {
    setSearchParams: () => { },
    setIsSearching: () => { },
    isSearching: true,
}

export default PublicTenderSearch;