import React, { useEffect, useState } from 'react';
import { Card, CardBody, CardHeader } from 'reactstrap';
import { getSupplierDetails } from '../../helpers/general';
import Translation from '../controls/Translation';

const CompanyDetails = props => {
    const { supplierID, CardClass, PanelName } = props;
    const [companyDetails, setCompanyDetails] = useState({});

    useEffect(() => {
        if (supplierID > 0) {
            getSupplierDetails(supplierID)
                .then(data => {
                    setCompanyDetails(data)
                })
        }

        return () => {
            setCompanyDetails({});
        }
    }, [supplierID])

        return (
            <>
                {companyDetails &&
                    <Card className={CardClass} >
                        <CardHeader className="ic-details">
                            <span>
                                <Translation ID={PanelName} />
                            </span>
                        </CardHeader>
                        <CardBody className='padding-5-1 card-module'>
                            <h4 className="card-heading u-branded-underline">
                                {companyDetails.name}
                            </h4>
                            <h4 style={{ marginTop: "20px" }}>
                                Address
                            </h4>
                            <p>{companyDetails.building}</p>
                            <p>{companyDetails.street}</p>
                            <p>{companyDetails.city}</p>
                            <p style={{ marginBottom: "0.5em" }}>{companyDetails.state ? companyDetails.state + ', ' : ''}{companyDetails.postalCode}</p>
                            <br />
                            <h4>
                                Contact Details
                            </h4>
                            <p>Phone: {companyDetails.phone}</p>
                            <p>Email: {companyDetails.publishEmail}</p>
                            <p>Website: {companyDetails.webSite}</p>
                        </CardBody>
                    </Card>
                }
            </>
        )
}

export default CompanyDetails;