import { faArchive, faLock } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect, useState } from 'react';
import ReactDOM from 'react-dom';
import { Button, Card, CardBody, CardHeader, Modal, ModalBody, ModalFooter, UncontrolledTooltip } from 'reactstrap';
import { getApplicationType } from '../../helpers/general';
import { history } from '../../helpers/history';
import DataTable from '../controls/DataTable';
import Translation from '../controls/Translation';

const moment = require('moment');

const SupplierEOIS_Modal = (props) => {
    let { id, isOpen, toggle, supplierID, cardClass, panelName, tableName } = props;
    const [includeArchived, setIncludeArchived] = useState(false)
    const [finalSearchString, setFinalSearchString] = useState('');

    useEffect(() => {
        if (supplierID) {
        	// statusID is enumb from CvExpiringTenders
        	getApplicationType()
            	.then(application => {
                	setFinalSearchString(`supplierID=${supplierID}&includeArchived=${includeArchived}&systemName=${application}`);
            	});
        }
    }, [includeArchived, supplierID]);

    const handleArchivedChange = (e) => {
        e.persist();
        getApplicationType()
            .then(application => {
                setIncludeArchived(e.target.checked);
                setFinalSearchString(`supplierID=${supplierID}&showArchived=${e.target.checked}&systemName=${application}`)
            });
    }

    return (
        <Modal
            id={id}
            isOpen={isOpen}
            toggle={toggle}
            size="xl"
            centered
            backdrop="static"
            unmountOnClose
        >
            <ModalBody>
                {finalSearchString && finalSearchString.length &&
                    <Card className={cardClass} >
                        <CardHeader className="ic-interest">
                            <span>
                                <Translation ID={panelName} />
                            </span>
                        </CardHeader>
                        <CardBody className='padding-5-1 card-module'>
                            <div className='c-form__input-group'>
                                <input id={`${tableName}_modal_includeArchived`} name='includeArchived' type='checkbox' className="c-form__checkbox" onChange={((e) => { handleArchivedChange(e) })} />
                                <Translation ID='IncludeArchived' htmlFor={`${tableName}_modal_includeArchived`} />
                            </div>
                            <hr />
                            <DataTable
                                id={tableName}
                                columns={columns}
                                info={false}
                                sorting
                                paging={false}
                                defaultOrder={[3, "asc"]}
                                url="./Suppliers/GetSupplierEOIs"
                                params={finalSearchString}
                                isSearching={true}
                                scrollY='550px'
                                pagingType='full'
                                scrollCollapse={true}
                            />
                        </CardBody>
                    </Card>
                }
            </ModalBody>
            <ModalFooter>
                <div className="p-2 text-right col-6">
                    <Button id="close" color="danger" size="sm" onClick={toggle}>{'Close'}</Button>
                </div>
            </ModalFooter>
        </Modal>
    )
}

const ClosedTooltip = React.forwardRef((props, ref) => {
    return (
        <>
            <FontAwesomeIcon forwardedRef={ref} icon={faLock} />
            <UncontrolledTooltip placement="right" defaultOpen={false} target={ref}>
                Closed Opportunity
            </UncontrolledTooltip>

        </>
    )
});

const ArchivedTooltip = React.forwardRef((props, ref) => {
    return (
        <>
            <FontAwesomeIcon forwardedRef={ref} icon={faArchive} />
            <UncontrolledTooltip placement="right" defaultOpen={false} target={ref}>
                Archived
            </UncontrolledTooltip>

        </>
    )
});

const columns = [
    { name: "tenderFeedID", data: "tenderFeedID", visible: false },
    {
        name: "closedTender",
        header: "",
        data: "closedTender",
        createdCell: (td, data) => {
            let ref = React.createRef();
            ReactDOM.render(
                data === true ? <>
                    <ClosedTooltip ref={ref} />
                </> : null, td)
        },
        width: "5%"
    },
    {
        name: "title",
        header: "Tender",
        data: (data) => {
            return data.tenderFeedID + '|' + data.title
        },
        createdCell: (td, data) => {
            ReactDOM.render(
                <a
                    href={`/tender-details?tid=${data.split('|')[0]}`}
                    onClick={(e) => { e.preventDefault(); history.push(`/tender-details?tid=${data.split('|')[0]}`) }}
                    style={{ color: '#00A65A' }}>
                    {data.split('|')[1]}
                </a>, td
            )
        },
        width: "75%"
    },
    {
        name: "dateExpressedInterest",
        header: "Actioned",
        data: (data) => { return (data.dateExpressedInterest === '' ? '-' : moment(data.dateExpressedInterest).format('DD/MM/YYYY')) }, 
        width: "15%"
    },
    {
        name: "archived",
        header: "",
        data: "archived",
        createdCell: (td, data) => {
            let ref = React.createRef();
            ReactDOM.render(
                data === true ? <>
                    <ArchivedTooltip ref={ref} />
                </> : null, td)
        },
        width: "5%"
    }
];

export default SupplierEOIS_Modal;