import React, { useState } from "react";
import { vDateFormats } from '../../helpers/controls';
import ContractDetailsModal from '../Contracts/ContractDetails_Modal';
import DataTable from "../controls/DataTable";
import Footer from "../layout/Footer";
import "bootstrap/dist/css/bootstrap.css";

require('datatables.net-bs4');

const moment = require('moment');

const ContractSearchResults = (props) => {
    let { finalSearchParams, isPageEnabled, isSearching } = props;

    const [showModal, setShowModal] = useState(false);
    const [secureID, setSecureID] = useState(null);
    const [isLoading, setIsLoading] = useState(true);
    const toggle = () => setShowModal(!showModal);

    function handleClick(row, data) {
        setIsLoading(true)
        setSecureID(data.secureID);
        toggle();
    }

    if (isPageEnabled === true) {
        return (
            <>
                <div className="main-content u-container">
                    <div className="c-list-header">
                        <div className="c-list-header__content">
                            <h1 className="c-page-title u-component-spacing--2x">Search results for 'search term'</h1>
                        </div>
                    </div>
                    <div className="u-component-spacing">
                        <div id="ContractsTable_wrapper" className="dataTables_wrapper dt-bootstrap4 no-footer">
                            <div className="row table-scroll">
                                <div className="col-sm-12">
                                    {finalSearchParams.searchObject.systemName !== '' &&
                                        <DataTable
                                            id='Contracts'
                                            columns={columns}
                                            sorting
                                            paging
                                            info={false}
                                            defaultOrder={[3, "asc"]}
                                            url="./Contracts/GetContracts"
                                            pagingType='full'
                                            allowAnonymous
                                            onClick={handleClick}
                                            params={`filter=${JSON.stringify(finalSearchParams.searchObject)}`}
                                            isSearching={isSearching}
                                        />
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <ContractDetailsModal
                    id={'contractDetails_modal'}
                    secureID={secureID}
                    setSecureID={setSecureID}
                    isOpen={showModal}
                    toggle={toggle}
                    isLoading={isLoading}
                    setIsLoading={setIsLoading}
                />

                <Footer />
            </>
        );
    } else {
        return null;
    }
}

const columns = [
    { name: "SecureID", data: "secureID", visible: false },
    { name: "Reference", data: "reference" },
    { name: "Title", data: "title" },
    { name: "Supplier", data: "supplier" },
    {
        name: "ExpiryDate",
        header: "Expiry Date",
        data: "expiryDate",
        render: (data) => {
            return moment(data, vDateFormats, true).isValid() && moment(data, vDateFormats, true).isAfter('1900-01-01T00:00:00') ? moment(data, vDateFormats, true).format('DD/MM/YYYY') : '';
        }
    },
    {
        name: "Value",
        header: "Value",
        data: "value",
        render: (data) => {
            return `&pound;${data ? data.toLocaleString('en', { minimumFractionDigits: 2, maximumFractionDigits: 2 }) : 0.00 }`;
        }
    },
    { name: "EntityName", data: "entityName", header: "Organisation" },
    { name: "Customer", data: "customer", header: "Customer" },
];

export default ContractSearchResults;