import React from 'react';
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import ContractDetails from '../Contracts/ContractDetails';
import SpinnerOverlay from '../layout/SpinnerOverlay';
import { history } from '../../helpers/history';

const ContractDetails_Modal = (props) => {
    let { id, secureID, setSecureID, isOpen, toggle, isLoading, setIsLoading } = props;

    const closeModal = () => {
        setSecureID(null);
        toggle();
    }

    const handleCopyURL = () => {
        var arrSplit = window.location.pathname.split('/')
        let newUrl = window.location.origin;

        if (!newUrl.includes('localhost')) {
            newUrl += `/${arrSplit[1]}`;
        }

        newUrl += `/view-contract?secureid=${secureID}`
        navigator.clipboard.writeText(newUrl);

        var tooltip = document.getElementById("copy-url-button");
        if (tooltip) {
            tooltip.innerHTML = `External contract url copied -  ${newUrl}`;
        }
    }

    return (
        <Modal
            id={id}
            isOpen={isOpen}
            toggle={toggle}
            size="xl"
            centered
            backdrop="static"
            unmountOnClose
            scrollable={true}
        >
            <ModalHeader>
                <div className="modal-title">
                    {'Contract Details'} -&nbsp;
                    <span className="u-component-spacing" style={{ fontSize: "0.85rem" }}>
                        <a id='copy-url-button' href='/' onClick={(e) => { e.preventDefault(); handleCopyURL() }} style={{ color: '#00A65A' }}>
                            Click to copy External Link to the contract
                        </a>
                    
                    </span>
                </div>
            </ModalHeader>
            <ModalBody>
                <ContractDetails
                    secureID={secureID}
                    setIsLoading={setIsLoading}
                />
                {isLoading &&
                    <SpinnerOverlay />
                }
            </ModalBody>
            <ModalFooter>
                <div className="p-2 text-right col-6">
                    <Button id="close" color="danger" size="sm" onClick={() => closeModal()}>{'Close'}</Button>
                </div>
            </ModalFooter>
        </Modal>
    )
}

export default ContractDetails_Modal;