import { faExpandAlt } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect, useState } from 'react';
import { Button, Card, CardBody, CardHeader } from 'reactstrap';
import { authHeader } from '../../helpers/auth-header';
import { getApplicationType, handleConfirm, handleTimedSuccess, logIntoPortal, showCustomError } from '../../helpers/general';
import { history } from '../../helpers/history';
import EmptyTableRow from '../controls/EmptyTableRow';
import Translation from '../controls/Translation';
import CompanyPortalsModal from '../Organisations/CompanyPortals_Modal';

require('datatables.net-bs4');

const CompanyPortals = props => {
    const { contactID, supplierID, PanelName, TableName, CardClass, userPermissions } = props;
    const [showModal, setShowModal] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [companyPortals, setCompanyPortals] = useState([]);
    const [packageService, setPackageService] = useState({ id: 0, price: 0 });
    const toggle = () => setShowModal(!showModal);

    useEffect(() => {
        if (supplierID > 0) {
            // statusID is enum from CvExpiringTenders
            fetch(`./Suppliers/GetSuppliersActiveOrganiserLinks?supplierID=${supplierID}&contactID=${contactID}`, {
                method: 'GET',
                headers: authHeader(false, false)
            })
                .then(response => response.json())
                .then(data => {
                    if (data) {
                        setCompanyPortals(data);
                    }
                })
                .catch(err => {
                    console.log("ERROR", err);
                })

            fetch(`./Packages/GetPackageServiceDetails?packageService=pservice_portals`, {
                method: 'GET',
                headers: authHeader(false, false)
            })
                .then(response => response.json())
                .then(data => {
                    if (data) {
                        setPackageService({ id: data.uniqueID, price: data.priceExc })
                    }
                })
                .catch(err => {
                    console.log("ERROR", err);
                });
        }

        return () => {
            setCompanyPortals([]);
            setPackageService({ id: 0, price: 0 })
        }
    }, [contactID, supplierID]);

    const handleExpandClick = () => {
        setIsLoading(true)
        toggle();
    }

    const changePortalStatus = (uniqueID, status) => {
        let updatedCompanyPortals = [...companyPortals];
        var index = updatedCompanyPortals.findIndex(x => x.uniqueID === uniqueID);
        updatedCompanyPortals[index]["status"] = status;
        setCompanyPortals(updatedCompanyPortals);
    }

    const handleRemoveFromBasket = uniqueID => {
        if (uniqueID) {
            let removeData = {
                "SupplierID": supplierID,
                "ContactID": contactID,
                "PackageServiceID": packageService.id,
                "TendererPackageServiceID": uniqueID
            };

            fetch(`./Payment/RemoveBasketItem`, {
                method: 'POST',
                headers: authHeader(false, false),
                body: JSON.stringify(removeData)
            })
                .then(response => response.json())
                .then(data => {
                    if (data) {
                        changePortalStatus(uniqueID, 1);
                    }
                })
                .catch(err => {
                    console.log("ERROR", err);
                })

        }
    }

    const addPortalToBasket = (uniqueID, portalName) => {
        let searchData = {
            "PackageServiceID": packageService.id,
            "TendererPackageServiceID": uniqueID,
            "Description": portalName,
            "PriceExcVat": packageService.price
        };

        fetch(`./Payment/AddBasketItem?supplierID=${supplierID}&contactID=${contactID}`, {
            method: 'POST',
            headers: authHeader(false, false),
            body: JSON.stringify(searchData)
        })
            .then(response => response.json())
            .then(data => {
                if (data > 0) {
                    changePortalStatus(uniqueID, 2);
                }
            })
            .catch(err => {
                console.log("ERROR", err);
            })
    }

    const linkPortalToAccount = (uniqueID) => {
        let searchData = {
            "SupplierID": supplierID,
            "ContactID": contactID,
            "PackageServiceID": packageService.id,
            "TendererPackageServiceID": uniqueID
        };

        getApplicationType()
            .then(application => {
                fetch(`./Suppliers/LinkPortalToAccount?systemName=${application}`, {
                    method: 'POST',
                    headers: authHeader(false, false),
                    body: JSON.stringify(searchData)
                })
                    .then(response => response.json())
                    .then(data => {
                        if (data) {
                            changePortalStatus(uniqueID, 0);

                            handleTimedSuccess('success', 'Account Linked', `Your account has been linked - portal allowance has been used.`);
                        } else {
                            showCustomError("A problem occurred while retrieving your portal link, please try again.", "Cannot link portal", "error")
                        }
                    })
                    .catch(err => {
                        console.log("ERROR", err);
                    })
            });
    }

    const handleSelectPortal = (uniqueID, portalName, portalStatus) => {
        if (portalStatus === 5) {
            linkPortalToAccount(uniqueID)
        } else {
            fetch(`./Suppliers/SupplierHasAvailableAllowance?supplierID=${supplierID}&packageService=pservice_portals`, {
                method: 'GET',
                headers: authHeader(false, false)
            })
                .then(allowanceResponse => allowanceResponse.json())
                .then(allowanceData => {
                    if (allowanceData && allowanceData > 0) {
                        handleConfirm(
                            `You currently have ${allowanceData} portal allowance - would you like to use your portal allowance and continue?`,
                            function () {
                                linkPortalToAccount(uniqueID)
                            }
                        );

                    } else {
                        addPortalToBasket(uniqueID, portalName)
                    }
                });
        }
    }

    if (supplierID > 0) {
        return (
            <>

                <Card className={CardClass} >
                    <CardHeader className="ic-company">
                        <span>
                            <Translation ID={PanelName} />
                        </span>
                        <span style={{ justifyContent: 'right' }}>
                            <button id='expandCompanyPortals' onClick={(() => handleExpandClick())}>
                                <FontAwesomeIcon icon={faExpandAlt} style={{ marginRight: '20px' }} />
                            </button>
                        </span>
                    </CardHeader>
                    <CardBody className='padding-5-1 card-module'>
                        <Card className="card-success">
                            <CardBody className={'padding-0-10'} style={{maxHeight:"420px", overflow:"auto"}}>
                                <table id='TendererTenderFeedSearchesTable' className='table'>
                                    <thead style={{position:"sticky", top:"0", background:"var(--card-bg-color)"}}>
                                        <tr>
                                            <th style={{ borderBottom: "0", borderTop: "0", fontWeight: "500", padding: "0" }}><span style={{ display: "block", padding: "0.5rem 0.75rem", borderBottom: "0.0625rem solid var(--border-color)" }} ></span></th>
                                            <th style={{ borderBottom: "0", borderTop: "0", fontWeight: "500", padding: "0", width: "95%" }}><span style={{ display:"block", padding:"0.5rem 0.75rem", borderBottom:"0.0625rem solid var(--border-color)"}} >Portals</span></th>
                                            <th style={{ borderBottom: "0", borderTop: "0", fontWeight: "500", padding: "0", width: "5%" }}><span style={{ display: "block", padding: "0.5rem 0.75rem", borderBottom: "0.0625rem solid var(--border-color)" }} >Action</span></th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {Object.keys(companyPortals).length > 0 &&
                                            <>
                                                {companyPortals.map((portal, i) => {
                                                    return (
                                                        <tr key={i} className={i % 2 === 0 ? "odd" : "even"}>
                                                            <td style={{ padding: "0.75rem 0.75rem" }}></td>
                                                            <td style={{ padding: "0.75rem 0.75rem" }}>
                                                                {portal.status === 0 &&
                                                                    <a
                                                                        href={`/organisation?oid=${portal.uniqueID}`}
                                                                        onClick={(e) => { e.preventDefault(); history.push(`/organisation?oid=${portal.uniqueID}`) }}
                                                                        style={{ color: '#00A65A' }}>
                                                                        {portal.portalName}
                                                                    </a>
                                                                }
                                                                {portal.status !== 0 &&
                                                                    <span style={{ color: '#757575', fontStyle: 'italic', verticalAlign: '-webkit-baseline-middle' }}>{portal.portalName}</span>
                                                                }
                                                            </td>
                                                            <td style={{ padding: "0.75rem 0.75rem" }}>
                                                                {/* STATUS = 0: Supplier has register and link is valid */}
                                                                {portal.status === 0 && !portal.requiresRegistering &&
                                                                    <button className="c-cta" style={{ fontSize: ".875rem", width: "10em" }} onClick={() => { logIntoPortal(true, portal.url, supplierID, contactID, -1, portal.uniqueID, portal.portalName) } }>
                                                                        Login to portal
                                                                    </button>
                                                                }

                                                                {/* STATUS = 1: Supplier has no allowance, requires portal purchase, BASKET icon */}
                                                                {portal.status === 1 && 
                                                                    <button className="c-tender-actions__action-button c-tender-actions__action-button--add-to-basket" disabled={!userPermissions.canPurchaseProducts} title='Account registered via another Sell2 system. Requires purchasing' onClick={() => handleSelectPortal(portal.uniqueID, portal.portalName)}>
                                                                        Add to basket
                                                                    </button>
                                                                }

                                                                {/* STATUS = 2: Portal already in the basket, BASKET REMOVE icon*/}
                                                                {portal.status === 2 && userPermissions.canPurchaseProducts &&
                                                                    <Button size="sm" color='link' className='c-text-link' onClick={(() => handleRemoveFromBasket(portal.uniqueID))} >
                                                                        Remove from basket
                                                                    </Button>
                                                                }

                                                                {/* STATUS = 3: Free account, requires account upgrade, GREEN ARROW icon*/}
                                                                {portal.status === 3 &&
                                                                    <button className="c-tender-actions__action-button c-tender-actions__action-button--upgrade" title='Package Upgrade Required' onClick={() => history.push(`/upgrade`)}>
                                                                        Upgrade
                                                                    </button>
                                                                }

                                                                {/* STATUS = 4: Supplier has available portal allowance, portal allowance to be used - already registered on another Sell2, Link account.  LINK button */}
                                                                {(portal.status === 4 || portal.status === 5) && !portal.requiresRegistering && userPermissions.canRegisterPortal &&
                                                                    <button className="c-tender-actions__action-button c-tender-actions__action-button--link" onClick={() => { handleSelectPortal(portal.uniqueID, portal.portalName, portal.status) }}>
                                                                        Link Existing Sell2 Registration
                                                                    </button>
                                                                }
                                                            </td>
                                                        </tr>
                                                    )
                                                })}
                                            </>
                                        }

                                        {Object.keys(companyPortals).length === 0 &&
                                            <EmptyTableRow />
                                        }
                                    </tbody>
                                </table>
                            </CardBody>
                        </Card>

                    </CardBody>
                </Card>

                <CompanyPortalsModal
                    id={`${TableName}_Modal`}
                    isOpen={showModal}
                    toggle={toggle}
                    isLoading={isLoading}
                    setIsLoading={setIsLoading}
                    cardClass={CardClass}
                    panelName={PanelName}
                    tableName={`${TableName}_Modal_DataTable`}
                    supplierID={supplierID}
                    contactID={contactID}
                    scrollable
                />
            </>
        )
    } else {
        return null;
    }
}


export default CompanyPortals;