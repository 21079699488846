import React from 'react';
import { Button, Card, Col, Modal, ModalBody, ModalHeader, Row, Table } from 'reactstrap';
import { authHeader } from '../../helpers/auth-header';
import { showCustomError, StartFreePaymentProcess } from '../../helpers/general';
import Translation from '../controls/Translation';
import 'datatables.net-bs4/css/dataTables.bootstrap4.min.css';

const ConfirmedBasketItems_Modal = (props) => {
    let { isOpen, toggle, previousTotals, setPreviousTotals, finalBasket, setFinalBasket, setModalSelect, user } = props;

    const handleProceedToPay = () => {
        let tmpBasketItems = { renewal: false, basketItems: finalBasket.basketItemsInfo.basketItems };

        setFinalBasket(value => ({
            ...value,
            basketItems: tmpBasketItems
        }));

        if (finalBasket.priceToCharge > 0) {
            setModalSelect(2);
            toggle();
        } else {
            // Free payment, create an Ogone entry, and no card details
            let data = [];

            data.push({ field: 'priceExcVat', value: finalBasket.priceToCharge });
            data.push({ field: 'basketItems', value: JSON.stringify(finalBasket.basketItemsInfo) });

            let upgradePackageID = 0; // This needs to handle package upgrades too eventually
            StartFreePaymentProcess(data, user.supplierID, user.contactID, "00000000-0000-0000-0000-000000000000", upgradePackageID);
        }
    }

    const handleClose = () => {
        setModalSelect(0);
        toggle();
    }

    function handleApplyDiscount(discountBasketItem) {
        setPreviousTotals({ subTotal: finalBasket.subTotal, totalTax: finalBasket.totalTax, priceToCharge: finalBasket.priceToCharge });

        let discountTotal = discountBasketItem.unitPriceExcVat > 0 ? discountBasketItem.unitPriceExcVat * -1 : discountBasketItem.unitPriceExcVat;
        let updateSubTotal = finalBasket.subTotal + discountTotal > 0 ? finalBasket.subTotal + discountTotal : 0;
        let updateTax = (updateSubTotal / 100) * finalBasket.vatRate;
        let updateTotal = updateSubTotal + updateTax;
        let basketItems = finalBasket.basketItemsInfo.basketItems;

        basketItems.push(discountBasketItem);

        let basketInfo = { renewal: false, basketItems: basketItems };

        setFinalBasket(value => ({
            ...value,
            basketItems: basketInfo,
            discountTotal: discountTotal,
            subTotal: updateSubTotal,
            totalTax: updateTax,
            priceToCharge: updateTotal
        }));
    }

    const handleAddDiscount = () => {
        var discountCodeInput = document.getElementById('discountCode');
        let obData = { "discountCode": discountCodeInput.value, "subTotal": finalBasket.selectedSubTotal };
        if (finalBasket.basketItemsInfo.basketItems.filter(x => x.discountCode !== '' && x.discountCode !== null).length === 0) {
            if (discountCodeInput !== null && discountCodeInput.value.length > 0) {
                fetch(`./Payment/ApplyDiscountCode?supplierID=${user.supplierID}&contactID=${user.contactID}`, {
                    method: "POST",
                    headers: authHeader(),
                    body: JSON.stringify(obData)
                })
                    .then((response) => response.json())
                    .then((data) => {
                        if (data.success === true) {
                            handleApplyDiscount(data.objData)
                        } else {
                            showCustomError(data.message, '', 'error');
                        }
                    })
                    .catch((err) => {
                        console.log("error", err);
                    });
                discountCodeInput.value = "";
            } else {
                showCustomError('No discount code entered', '', 'error')
            }
        } else {
            showCustomError('Active discount code already added', '', 'error')
        }
    }

    const handleRemoveDiscount = () => {
        let basketItems = finalBasket.basketItemsInfo.basketItems;
        let index = basketItems.findIndex(x => x.discountCode !== null && x.discountCode !== '');
        if (index >= 0) {
            basketItems.splice(index, 1);

            setFinalBasket(value => ({
                ...value,
                basketItems: basketItems,
                discountTotal: null,
                subTotal: previousTotals.subTotal,
                totalTax: previousTotals.totalTax,
                priceToCharge: previousTotals.priceToCharge
            }));

            var discountCodeInput = document.getElementById('discountCode');
            
            if (discountCodeInput !== null) {
                discountCodeInput.removeAttribute('disabled');
            }
        }
    }

    return (
        <Modal
            id='ConfirmedBasketItems_Modal'
            isOpen={isOpen}
            toggle={toggle}
            size="xl"
            centered
            backdrop="static"
            unmountOnClose
        >
            <ModalHeader toggle={handleClose}></ModalHeader>
            <ModalBody>
                <main>
                    <div className="padding-l-25 padding-r-20 card main-content__inner">
                        <Card body>
                            <h3 className="c-broken-underline u-component-spacing">
                                Basket Items
                            </h3>
                            <Table id={`BasketItemsTable`} size="sm" width="100%" className="c-invoice-modal__invoice-table">
                                <thead>
                                    <tr>
                                        <th className="c-invoice-modal__table-heading">Description</th>
                                        <th className="c-invoice-modal__table-heading"></th>
                                        <th className="c-invoice-modal__table-heading c-invoice-modal__center-text">Quantity</th>
                                        <th className="c-invoice-modal__table-heading" style={{ width: "16%" }}>Unit Price</th>
                                        <th className="c-invoice-modal__table-heading" style={{ width: "16%" }}>Price (exc vat)</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {finalBasket &&
                                        <>
                                        {finalBasket.basketItemsInfo.basketItems.map((basketItem, i) => {
                                            return (
                                                <tr key={i} className="c-invoice-modal__table-row" style={basketItem.discountCode ? { color: 'red' } : undefined }>
                                                        <td>
                                                        <label><span dangerouslySetInnerHTML={{ __html: basketItem.packageServiceDescription }} /></label>
                                                        </td>
                                                        <td>
                                                            {(basketItem.discountCode !== null && basketItem.discountCode !== '') &&
                                                                <button id={'deleteDiscount'} className="c-tender-actions__action-button c-tender-actions__action-button--bin"
                                                                    onClick={() => { handleRemoveDiscount() }}>Remove</button>
                                                            }
                                                        </td>
                                                        <td className="c-invoice-modal__center-text">{(basketItem.discountCode === null || basketItem.discountCode === '') && <>{basketItem.quantity}</>}</td>
                                                        <td>{(basketItem.discountCode === null || basketItem.discountCode === '') && <>&pound;{basketItem.unitPriceExcVat.toLocaleString('en', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</>}</td>
                                                        <td>&pound;{basketItem.priceExcVat.toLocaleString('en', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</td>
                                                    </tr>
                                                )
                                            })}
                                        </>}
                                    <tr>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td className="c-invoice-modal__table-summary-term" style={{ verticalAlign: "top" }}>
                                            <span style={{ paddingTop: "calc(.75rem + 1px)", display: "block" }}>Discount Code</span>
                                        </td>
                                        <td className="c-invoice-modal__table-summary-value">
                                            <input id='discountCode' name='discountCode' type='text' className='c-form__input' />
                                            <Button color="secondary" style={{ fontSize: "1rem", float: "right", marginTop: "5px" }} onClick={() => handleAddDiscount()}>apply code</Button>
                                        </td>
                                    </tr>
                                    {finalBasket.discountTotal !== 0 && finalBasket.discountTotal !== null &&
                                        <tr style={{color:"red"}}>
                                            <td style={{ border: "0px" }}></td>
                                            <td style={{ border: "0px" }}></td>
                                            <td style={{ border: "0px" }}></td>
                                            <td className="c-invoice-modal__table-summary-term" style={{ paddingTop: "2rem", border: "0px" }}>Discount</td>
                                            <td className="c-invoice-modal__table-summary-value" style={{ paddingTop: "2rem", border: "0px" }}>
                                                -&pound;{finalBasket.discountTotal.toLocaleString('en', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}
                                            </td>
                                        </tr>
                                    }
                                    <tr>
                                        <td style={{ border: "0px" }}></td>
                                        <td style={{ border: "0px" }}></td>
                                        <td style={{ border: "0px" }}></td>
                                        <td className="c-invoice-modal__table-summary-term" style={{ border: "0px" }}>Subtotal</td>
                                        <td className="c-invoice-modal__table-summary-value" style={{ border: "0px" }}>&pound;{finalBasket.subTotal.toLocaleString('en', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</td>
                                    </tr>
                                    <tr>
                                        <td style={{ border: "0px" }}></td>
                                        <td style={{ border: "0px" }}></td>
                                        <td style={{ border: "0px" }}></td>
                                        <td className="c-invoice-modal__table-summary-term" style={{ border: "0" }}>VAT ({finalBasket.vatRate}%)</td>
                                        <td className="c-invoice-modal__table-summary-value" style={{ border: "0" }}>&pound;{finalBasket.totalTax.toLocaleString('en', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</td>
                                    </tr>
                                    <tr>
                                        <td style={{ border: "0px" }}></td>
                                        <td style={{ border: "0px" }}></td>
                                        <td style={{ border: "0px" }}></td>
                                        <td className="c-invoice-modal__table-summary-term" style={{ paddingTop: "1.5rem", paddingBottom: "1rem", border: "0", fontWeight: "bold" }}>Total ({finalBasket.vatRate}%)</td>
                                        <td className="c-invoice-modal__table-summary-value" style={{ paddingTop: "1.5rem", paddingBottom: "1rem", border: "0", fontWeight: "bold" }}>&pound;{finalBasket.priceToCharge.toLocaleString('en', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</td>
                                    </tr>
                                </tbody>
                            </Table>
                            <hr />
                            {(finalBasket.priceToCharge > 0 || finalBasket.basketItemsInfo.basketItems.findIndex(x => x.discountCode !== null || x.discountCode !== "") >= 0) &&
                                <Row style={{ marginTop: '20px' }}>
                                    <Col md={12} className='c-proceed-to-pay'>
                                        <button id="purchaseButton" className="c-cta" onClick={() => handleProceedToPay()}><Translation ID={finalBasket.priceToCharge === 0 ? "ConfirmAndProceed" : "ProceedToPay"} /></button>
                                    </Col>
                                </Row>
                            }

                        </Card>
                    </div>
                </main>
            </ModalBody>
        </Modal>
    )
}

export default ConfirmedBasketItems_Modal;