import React, { useContext, useEffect, useRef, useState } from 'react';
import { NavLink as Link, useLocation } from 'react-router-dom';
import { Collapse, Navbar, NavbarToggler } from 'reactstrap';
import { authHeader } from '../../helpers/auth-header';
import { getApplicationType, getSupplierID } from '../../helpers/general';
import blpd from '../../images/logos/bl-logo.png';
import livedemo from '../../images/logos/in-tend-logo-white.png';
import seshared from '../../images/logos/se-shared-services-logo.png';
import sykehusinnkjop from '../../images/logos/sykehusinnkjop.png';
import housing from '../../images/logos/sell2-housing-logo.png';
import un from '../../images/logos/sell2-un-logo.png';
import midlands from '../../images/logos/sell2-midlands-logo.png';
import nhs from '../../images/logos/sell2-nhs-logo.png';
import universities from '../../images/logos/in-tend-logo-white.png';
import iceland from '../../images/logos/in-tend-logo-white.png';
import ireland from '../../images/logos/in-tend-logo-white.png';
import pacific from '../../images/logos/in-tend-logo-white.png';
import caribbean from '../../images/logos/in-tend-logo-white.png';
import MenuContext from './MenuContext';
import NavMenuPrivate from './NavMenuPrivate';
import NavMenuPublic from './NavMenuPublic';

const NavMenu = () => {
    const [isOpen, setIsOpen] = useState(false);
    const toggle = () => setIsOpen(!isOpen);
    const [isLoggedIn, setIsLoggedIn] = useState(null);
    const [navImage, setNavImage] = useState(null);
    const location = useLocation();
    const [supplierID, setSupplierID] = useState(-1);
    const [packagesEnabled, setPackagesEnabled] = useState(false);

    const { navMenu } = useContext(MenuContext);

    let altImage = useRef();

    useEffect(() => {
        if (location) {
            setIsOpen(false);
        }
    }, [location]);

    useEffect(() => {
        setSupplierID(getSupplierID())
    }, [navMenu]);

    useEffect(() => {
        fetch(`./Settings/ArePackagesEnabled`, {
            method: 'GET',
            headers: authHeader(false, true)
        })
            .then(response => response.json())
            .then(packagesEnabled => {
                setPackagesEnabled(packagesEnabled);
            })
            .catch(err => {
                console.log(err);
            })

        return () => {
            setPackagesEnabled(false);
        }
    }, []);

    useEffect(() => {
        if (supplierID > -1) {
            setIsLoggedIn(supplierID > 0);

            getApplicationType().then(type => {
                altImage.current = type.toLowerCase();
                switch (type.toLowerCase()) {
                    case 'sell2uk':
                        setNavImage(type.toLowerCase());
                        break;

                    case 'sell2blpd':
                        setNavImage(blpd);
                        break;

                    case 'sell2seshared':
                        setNavImage(seshared);
                        break;

                    case 'sell2livedemo':
                        setNavImage(livedemo);
                        break;

                    case 'sell2sykehusinnkjop':
                        setNavImage(sykehusinnkjop);
                        break;

                    case 'sell2housing':
                        setNavImage(housing);
                        break;

                    case 'sell2un':
                        setNavImage(un);
                        break;

                    case 'sell2midlands':
                        setNavImage(midlands);
                        break;

                    case 'sell2nhs':
                        setNavImage(nhs);
                        break;

                    case 'sell2universities':
                        setNavImage(universities);
                        break;

                    case 'sell2pacific':
                        setNavImage(pacific);
                        break;

                    case 'sell2ireland':
                        setNavImage(ireland);
                        break;

                    case 'sell2iceland':
                        setNavImage(iceland);
                        break;

                    case 'sell2caribbean':
                        setNavImage(caribbean);
                        break;

                    default:
                        break;
                }
            });

            return () => {
                setIsLoggedIn(null);
                setNavImage(null);
            }
        }
    }, [supplierID]);

    return (
        <header className="c-header c-header--bespoke">
            <div className="c-header__inner">
                <div className="c-header__logo">
                    <div className="c-logo">
                        <Link className="c-logo__link" to={isLoggedIn ? "/dashboard" : "/"}>
                            {navImage === 'sell2uk' &&
                                <>
                                    <svg className="c-logo__icon" aria-hidden="true"
                                        xmlns="http://www.w3.org/2000/svg"
                                        fillRule="evenodd"
                                        strokeLinejoin="round"
                                        strokeMiterlimit="2"
                                        clipRule="evenodd"
                                        viewBox="0 0 145 51"
                                        width="145">
                                        <path fill="#fff" d="M29.84 37.97H0V13.03h29.84L6.07 0h34.66l14.13 7.69V0h8.72v51h-8.72v-7.69L40.73 51H6.07l23.77-13.03Z" />
                                        <path className="c-logo__path" d="M123.9 33.09H80.42V51H62.58V33.09H0V17.91h62.58V0h17.84v17.91h43.48v-6.37L145 25.5l-21.1 13.96V33.1Z" />
                                        <path className="c-logo__path" d="m12.14 0 23.7 13.03h10L22.14 0h-10ZM21.16 51l23.7-13.03h10L31.16 51h-10Z" />

                                        <svg className="c-logo__icon-inner"
                                            xmlns="http://www.w3.org/2000/svg"
                                            fillRule="evenodd"
                                            strokeLinejoin="round"
                                            strokeMiterlimit="2"
                                            clipRule="evenodd"
                                            viewBox="0 0 145 51">
                                            <path fill="#fff" fillRule="nonzero" d="M77.58 31.13v2.59H65.43v-2.05l6.55-6.22a7.18 7.18 0 0 0 1.5-1.85c.25-.52.38-1.05.38-1.56 0-.77-.26-1.36-.78-1.77-.51-.4-1.28-.61-2.28-.61-1.68 0-2.97.57-3.86 1.72l-2.15-1.65a6.34 6.34 0 0 1 2.6-2.02 9.03 9.03 0 0 1 3.67-.72c1.79 0 3.22.43 4.28 1.28 1.07.84 1.6 2 1.6 3.46 0 .9-.18 1.73-.56 2.52a9.85 9.85 0 0 1-2.17 2.69l-4.4 4.19h7.77Z" />
                                            <path fill="#fff" d="M38.64 31.3c-.86 0-1.68-.12-2.47-.36a5.16 5.16 0 0 1-1.9-.97l.72-1.6a6.07 6.07 0 0 0 3.65 1.22c.82 0 1.43-.13 1.84-.4.4-.26.6-.6.6-1.04 0-.31-.1-.57-.33-.78a2.4 2.4 0 0 0-.86-.48c-.35-.12-.83-.25-1.43-.4-.84-.2-1.52-.4-2.04-.6-.52-.2-.97-.5-1.35-.94-.37-.42-.56-1-.56-1.73 0-.6.17-1.16.5-1.66.33-.5.83-.9 1.5-1.2.67-.3 1.5-.44 2.46-.44.67 0 1.34.09 2 .25a5.5 5.5 0 0 1 1.68.73L42 22.5a6.38 6.38 0 0 0-3.05-.87 3.2 3.2 0 0 0-1.8.4c-.4.28-.6.65-.6 1.1 0 .31.12.57.34.77.23.2.51.36.86.48.35.11.82.24 1.43.4.82.18 1.5.38 2.02.59.52.2.98.51 1.35.94.37.42.56.99.56 1.7 0 .61-.17 1.17-.5 1.66-.33.5-.83.9-1.5 1.19-.68.3-1.5.44-2.47.44ZM52.9 26.97l-.04.58h-6.62c.12.63.42 1.12.9 1.48.5.37 1.1.55 1.83.55.93 0 1.7-.3 2.3-.92l1.05 1.22c-.38.45-.85.8-1.43 1.02-.58.24-1.24.35-1.96.35-.93 0-1.75-.18-2.45-.55a3.98 3.98 0 0 1-1.64-1.54 4.35 4.35 0 0 1-.58-2.24c0-.82.2-1.56.56-2.22.38-.66.9-1.17 1.56-1.54a4.54 4.54 0 0 1 2.25-.55c.82 0 1.55.18 2.2.54.65.37 1.15.88 1.52 1.54.36.65.54 1.42.54 2.28Zm-4.27-2.78c-.64 0-1.17.19-1.6.56-.45.37-.71.87-.8 1.5H51a2.33 2.33 0 0 0-.77-1.5 2.36 2.36 0 0 0-1.61-.56ZM54.79 19.42h1.98v11.73h-1.98zM59.36 19.42h1.98v11.73h-1.98zM86.32 31.3A4.81 4.81 0 0 1 82.74 30c-.85-.86-1.28-2.09-1.28-3.69v-6.24h2.06v6.16c0 2.17.94 3.26 2.81 3.26s2.8-1.09 2.8-3.26v-6.16h2.02v6.24c0 1.6-.42 2.83-1.27 3.7a4.79 4.79 0 0 1-3.56 1.28ZM97.84 26.51l-1.76 1.8v2.83h-2.05V20.08h2.05v5.67l5.47-5.67h2.3l-4.64 4.95 4.93 6.11h-2.4l-3.9-4.63Z" />
                                        </svg>
                                    </svg>
                                    <span className="u-visually-hidden">Sell2UK Home</span>
                                </>
                            }
                            {navImage !== 'sell2uk' &&
                                <>
                                    <img src={navImage} style={{ padding: "3px 0" }} alt={altImage.current} />
                                </>
                            }
                        </Link>
                    </div>
                </div>

                <div className="c-header__nav">
                    <a href="https://www.in-tend.co.uk" className="in-tend" target="_blank" rel='noreferrer'>
                        <span className="in-tend__small">Powered by</span>
                        <span className="u-branded-heading u-no-wrap"><span className="u-branded-heading__i">i</span>n-tend</span>
                    </a>
                    {navMenu.length > 0 &&
                        <Navbar className="navbar-inverse navbar" expand="lg" light>
                            <NavbarToggler onClick={toggle} />
                            <Collapse isOpen={isOpen} navbar>
                                {isLoggedIn &&
                                    <NavMenuPrivate
                                        navMenu={navMenu}
                                        supplierID={supplierID}
                                        packagesEnabled={packagesEnabled}
                                    />
                                }
                                {!isLoggedIn &&
                                    <NavMenuPublic
                                        navMenu={navMenu}
                                        packagesEnabled={packagesEnabled}
                                    />
                                }
                            </Collapse>
                        </Navbar>
                    }
                </div>
            </div>
        </header>
    );
}

export default NavMenu;