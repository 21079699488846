import React, { useEffect, useState } from 'react';
import { faDownload } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, Card, Col, Row } from 'reactstrap';
import { getFileFromServer } from '../../helpers/controls';
import { downloadFile } from '../../helpers/general';

const ContractDetails = (props) => {
    let { secureID, isLoading, setIsLoading } = props;
    const [contract, setContract] = useState([]);

    useEffect(() => {
        if (secureID) {
            fetch(`./Contracts/GetContractDetails?secureID=${secureID}`, {
                method: 'GET',
            })
                .then(response => response.json())
                .then(data => {
                    setContract(data);
                    setIsLoading(false);
                })
                .catch(error => {
                    console.log('Error: ', error);
                })
        }
    }, [secureID, setIsLoading]);

    const handleDownload = (uncPath) => {
        if (uncPath && uncPath !== '') {
            getFileFromServer(uncPath).then(data => {
                downloadFile(uncPath, data);
            });
        }
    }

    return (
        <>
            {!isLoading && contract && contract.contractDetails &&
                <>
                    <dl className="dl-table table-contract-register-details">
                        {
                            contract.contractDetails.map((info, i) => {
                                return (
                                    <div style={{ lineHeight: "1.8em" }} key={i}>
                                        <dt>{info.label}</dt>
                                        <dd>{info.value}</dd>
                                    </div>
                                )
                            })
                        }
                    </dl>
                    <hr />
                    <Card color='primary' outline className='contractDetails_documents'>
                        <h5 style={{ marginLeft: '20px', marginTop: '20px' }}>
                            {'Contract Documents'}
                        </h5>

                        <dl className="dl-table">
                            {contract.contractDocuments.length > 0 &&
                                contract.contractDocuments.map((doc, i) => {
                                    return (
                                        <Row key={i}>
                                            <Col lg={2}>
                                                <Button id='downloadDocument' name='downloadDocument' color='primary' outline onClick={(() => handleDownload(doc.uncPath))}>
                                                    <FontAwesomeIcon icon={faDownload} style={{ marginRight: '20px' }} />Download
                                                </Button>
                                            </Col>
                                            <Col className='contractAttachmentLabel'>
                                                {doc.name}
                                            </Col>
                                        </Row>
                                    )
                                })
                            }
                            {contract.contractDocuments.length === 0 &&
                                <div>No Documents</div>
                            }
                        </dl>
                    </Card>
                </>
            }
            {(contract === null || contract.contractDocuments === null) &&
                <h3>No Contract Details Found</h3>
            }
        </>
    )
}

export default ContractDetails;