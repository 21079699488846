import React, { useEffect, useState } from "react";
import { authHeader } from "../../helpers/auth-header";
import { showCustomError } from "../../helpers/general";
import ContractFilters from "../Contracts/ContracFilters";
import ContractPageCheck from "../Contracts/ContractPageCheck";
import ContractSearchResults from "../Contracts/ContractSearchResults";
import Footer from "../layout/Footer";
import NavMenu from "../layout/NavMenu";
import SpinnerOverlay from '../layout/SpinnerOverlay';
import "bootstrap/dist/css/bootstrap.css";

require('datatables.net-bs4');

const moment = require('moment');

const ContractRegister = props => {
    let { isPageEnabled, systemName, user } = props;

    const [isSearching, setIsSearching] = useState(false);
    const [finalSearchParams, setFinalSearchParams] = useState({ searchObject: { systemName: '', includeArchived: 0 } });
    const [isExporting, setIsExporting] = useState(false);

    const exportData = () => {
        setIsExporting(true);

        fetch(`./Contracts/ExportContracts?filter=${JSON.stringify(finalSearchParams.searchObject)}`, {
            method: 'GET',
            headers: authHeader(false, true)
        })
            .then(response => response.blob())
            .then(data => {
                const a = document.createElement('a');
                a.download = `ContractExport_${new moment().format('DDMMYYYY_hhmm').toString()}.xlsx`;
                a.href = URL.createObjectURL(data);
                a.addEventListener('click', (e) => {
                    setTimeout(() => URL.revokeObjectURL(a.href), 30 * 1000);
                });
                a.click();

                setIsExporting(false);
            })
            .catch(err => {
                console.log(err);
                setIsExporting(false);
                showCustomError('Something went wrong...', 'Oops', 'error');
            });
    }

    useEffect(() => {
        setFinalSearchParams({
            searchObject: {
                includeArchived: 0,
                title: '',
                reference: '',
                organisation: 0,
                customer: '',
                dateExpiryFrom: '',
                dateExpiryTo: '',
                systemName: systemName
            }
        })
    }, [systemName]);

    return (
        <>
            <NavMenu />

            <ContractFilters
                setFinalSearchParams={setFinalSearchParams}
                exportData={exportData}
                systemName={systemName}
                user={user}
                setIsSearching={setIsSearching}
            />

            <ContractSearchResults
                finalSearchParams={finalSearchParams}
                isPageEnabled={isPageEnabled}
                systemName={systemName}
                isSearching={isSearching}
            />

            <Footer />

            {isExporting &&
                <SpinnerOverlay />
            }
        </>
    )
}


//Create the child component above, and use in the generic Contract Page Check (which checks if the page is enabled or not)
const Contracts = () => {
    return (
        <ContractPageCheck
            ContractPage={ContractRegister}
        />
    )
}

export default Contracts;