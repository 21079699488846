import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { Card, CardBody } from 'reactstrap';
import { getContactID, getSupplierID, pageEnabled } from '../../helpers/general';
import DataTable from '../controls/DataTable';
import InvoiceModal from './Invoice_Modal';

require('datatables.net-bs4');

const Invoices = () => {
    const [user, setUser] = useState({ supplierID: -1, contactID: -1 });
    const [finalSearchString, setFinalSearchString] = useState('');
    const [secureID, setSecureID] = useState('');
    const [isLoading, setIsLoading] = useState(true);
    const [showModal, setShowModal] = useState(false);
    const [isPageEnabled, setIsPageEnabled] = useState(false);

    const toggle = () => {
        // On toggle, if the secure id is populated, clear out to reset the state variable (causes re-click to not load the same invoice)
        if (secureID !== '') {
            setSecureID('');
        }
        setShowModal(!showModal);
    }

    useEffect(() => {
        pageEnabled('Nav_Invoices', '/404').then(enabled => { setIsPageEnabled(enabled); });

        setUser(prev => ({
            ...prev,
            supplierID: getSupplierID(),
            contactID: getContactID()
        }));

        return () => {
            setUser({ supplierID: -1, contactID: -1 });
        }
    }, []);

    useEffect(() => {
        // statusID is enum from CvExpiringTenders
        setFinalSearchString(`tendererID=${user.supplierID}`)
    }, [user.supplierID]);

    function handleClick(row, data) {
        setIsLoading(true)
        setSecureID(data.secureID);
        toggle();
    }

    if (isPageEnabled === true) {
        return (
            <>

                <div className="padding-l-25 padding-r-20 card main-content__inner">

                    <Card body>

                        <CardBody>
                            <h3 className="c-broken-underline u-component-spacing">
                                Company Invoices
                            </h3>
                            <div className="row u-component-spacing">
                                <div className="col-md u-component-spacing--2x">
                                    <DataTable
                                        id="Invoices"
                                        columns={columns}
                                        sorting={true}
                                        paging={true}
                                        info={false}
                                        defaultOrder={[4, "desc"]}
                                        url="./Suppliers/GetCompanyInvoices"
                                        params={finalSearchString}
                                        isSearching={true}
                                        pagingType='full'
                                        onClick={handleClick}
                                        scrollCollapse={true}
                                    />
                                </div>
                            </div>
                        </CardBody>
                    </Card>
                </div>

                <InvoiceModal
                    id={'invoice_modal'}
                    secureID={secureID}
                    setSecureID={setSecureID}
                    isOpen={showModal}
                    toggle={toggle}
                    isLoading={isLoading}
                    setIsLoading={setIsLoading}
                    supplierID={user.supplierID}
                    contactID={user.contactID}
                />
            </>
        )
    } else {
        return null;
    }
}

const columns = [
    { name: "uniqueID", data: "uniqueID", visible: false },
    { name: "secureID", data: "secureID", visible: false },
    {
        name: "sell2PaymentID",
        header: "Invoice #",
        data: "sell2PaymentID"
    },
    {
        name: "purchasedBy",
        header: "Purchased By",
        data: "purchasedBy"
    },
    {
        name: "dateAdded",
        header: "Purchase Date",
        data: "dateAdded",
        render: (data) => {
            return moment(data).format('DD/MM/YYYY');
        }
        ,
        width: "30%"
    },
    {
        name: "paymentAmountCharged",
        header: "Amount",
        data: "paymentAmountCharged",
        render: (data) => {
            return `&pound;${data ? data.toLocaleString('en', { minimumFractionDigits: 2, maximumFractionDigits: 2 }) : (0).toLocaleString('en', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}`;
        }
    }
];

export default Invoices;