import { useContext, useEffect } from "react";
import { authHeader } from "../../helpers/auth-header";
import { getApplicationType, getVirtualDirectory, removeCookie, setSessionVal } from "../../helpers/general";
import { history } from '../../helpers/history';
import MenuContext from "../layout/MenuContext";

const Logout = () => {
    const { setNavMenu } = useContext(MenuContext);

    useEffect(() => {
        removeCookie(`token-${getVirtualDirectory()}`);
        setSessionVal('TendersPageNumber', 1); // Reset the page number for Tenders when you log out (to reset if the user viewed private tenders)

        fetch(`./NavMenu/GetNavBar?supplierID=-1`, {
            method: 'GET',
            headers: authHeader(false, true)
        })
            .then(response => response.json())
            .then(nav => {
                setNavMenu(nav);
                history.push('/dashboard');
            });
        history.push('/');
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return null;
}

export default Logout;