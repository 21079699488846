import "bootstrap/dist/css/bootstrap.css";
import React, { useEffect, useState } from "react";
import { authHeader } from "../../helpers/auth-header";
import { getContactID, getSupplierID, handleConfirm, pageEnabled } from "../../helpers/general";
import { history } from '../../helpers/history';
import Packages from "./Packages";

const Upgrade = () => {
    const [user, setUser] = useState({ supplierID: -1, contactID: -1 });
    const [isPageEnabled, setIsPageEnabled] = useState(false);

    useEffect(() => {
        pageEnabled('Nav_PricePlans', '/404').then(enabled => { setIsPageEnabled(enabled); });

        setUser(prev => ({
            ...prev,
            supplierID: getSupplierID(),
            contactID: getContactID()
        }));

        return () => {
            setUser({ supplierID: -1, contactID: -1 });
        }
    }, []);

    const handleUpgradePurchase = (packageID, packageValue) => {
        let data = [{ field: 'packageID', value: packageID }, { field: 'price', value: packageValue } ]

        fetch(`./Payment/StartPurchaseUpgrade?supplierID=${user.supplierID}&contactID=${user.contactID}&totalPrice=${packageValue}`, {
            method: 'POST',
            headers: authHeader(false, false),
            body: JSON.stringify(data)
        })
            .then(response => response.json())
            .then(data => {
                history.push(`/basket`);
                return true;
            })
            .catch(err => {
                console.log("Error");
                return false;
            });
    }

    const handleAddToBasket = (packageID, packageValue) => {
        handleConfirm('<p>Adding the Upgrade to your basket will remove all other basket items.</p><p style="margin-bottom:1em"><strong>Do you wish to continue to upgrade your account?</strong></p>',
            function () { return handleUpgradePurchase(packageID, packageValue); },
            function () { return false;},
            'Add Upgrade to Basket?',
            '40em',
            'YES - Continue to upgrade',
            'NO - Cancel the upgrade'
        )
    }

    if (isPageEnabled === true) {
        return (
            <Packages
                supplierID={user.supplierID}
                contactID={user.contactID}
                handleOnPackageSelect={handleAddToBasket}
                showAllPackages={false}
                buttonLabel='Upgrade'
            />
        )
    } else {
        return null;
    }
}

export default Upgrade;