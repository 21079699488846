import React, { useEffect, useState } from 'react';
import { useCallback } from 'react';
import { authHeader } from '../../helpers/auth-header';
import SpinnerOverlay from '../layout/SpinnerOverlay';

const JsonToHtml = (props) => {
    let { url, json, jsonPropertyPath } = props;

    const [data, setData] = useState(null);

    const fetchUrlData = useCallback(async () => {
        try {
            const response = await fetch(`./Data/GetJsonByUrl?url=${url}`, {
                method: 'GET',
                headers: authHeader(false, true, 'text/plain; charset=utf-8'),
            });
            const jsonData = await response.json();

            // Incase there is a sub-level of the JSON object we need to get, ie {sell2uk: [{...}]} or if we get the root
            const extractedData = jsonPropertyPath
                ? jsonPropertyPath.split('.').reduce((obj, key) => (obj ? obj[key] : null), jsonData)
                : jsonData;

            setData(extractedData); // Assuming the API response has this structure

        } catch (error) {
            console.error('Error fetching data:', error);
        }
    }, [jsonPropertyPath, url]);

    useEffect(() => {
        if (url || json) {
            if (url && url !== '') {
                fetchUrlData();
            } else {
                setData(json)
            }
        }
    }, [fetchUrlData, json, url]);

    const parseTextWithLink = (text) => {
        // Split the text into normal text and anchor link parts match [text](url)
        const textParts = text.split(/(\[[^\]]+\]\([^)]+\))/g);

        // Loop through all the different text parts
        return textParts.map((part, index) => {
            // Check if each part matches the regex
            const match = part.match(/\[([^\]]+)\]\(([^)]+)\)/);

            if (match) {
                // Split the matched text part into an array
                const displayText = match[1];
                const url = match[2];

                // Generate and return the Anchor Link
                return (
                    <a key={index} className="u-no-wrap c-text-link" style={{display: "inline-flex", fontSize: "inherit"}} target="_blank" rel="noreferrer" href={url}>
                        {displayText}
                    </a>
                );
            }

            // ... else return the the text
            return part;
        });
    };

    const renderElement = (element) => {
        switch (element.element) {
            case "h1":
                return <h1 className='c-broken-underline u-component-spacing'>{element.text}</h1>;

            case "h2":
                return <h2 className='u-component-spacing--2x'>{element.text}</h2>;

            case "p":
                return <p className='u-component-spacing' style={{ marginLeft: "3rem" }}>{parseTextWithLink(element.text)}</p>;

            case "table":
                return (
                    <table className={`table u-component-spacing--2x ${element.class}`}>
                        <tbody>
                            {element.rows.map((row, i) => (
                                <tr key={i} className={i % 2 === 0 ? "odd" : "even"}>
                                    {row.cells.map((cell, cellIndex) => {
                                        const CellTag = cell.element;
                                        return <CellTag key={cellIndex}>{cell.text}</CellTag>;
                                    })}
                                </tr>
                            ))}
                        </tbody>
                    </table>
                );
            default:
                return <div className='' >{parseTextWithLink(element.text)}</div>;
        }
    };

    if (data) {
        return (
            <>
                {data.map((item, index) => (
                    <React.Fragment key={index}>{renderElement(item)}</React.Fragment>))
                    }
            </>
        );
    } else {
        return (
            <SpinnerOverlay />
        );
    }
};

export default JsonToHtml;