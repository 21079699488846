import React from 'react';
import { Row, Col } from 'reactstrap';
import SubscriptionDetails from '../Suppliers/SubscriptionDetails';
import Translation from './Translation';


const QuantitySelector = (props) => {
    const { id, name, description, price, quantities, handleChange, packagePermitsAddons, maxQuantity } = props;
    let value = quantities[id] === undefined ? 0 : quantities[id];
    let subTotal = 0;

    const handleQuantityChange = add => {
        if (!add && value === 0) {
            return false;
        } 

        if (add) {
            // prevent exceeding max quantity
            if (maxQuantity && value >= maxQuantity) {
                return false;
            }

            value++;
            subTotal += price;
        } else {
            value--;
            subTotal -= price;
        }

        handleChange(id, value, subTotal);
    }

    const handleNumberCheck = (e) => {
        if (!/[0-9]/.test(e.key)) {
            e.preventDefault();
            return false;
        }
    }

    const handleManualChange = (e) => {
        if (e.target.value === '') {
            e.target.value = 0;
            value = 0;
            subTotal -= (quantities[id] === undefined ? 0 : quantities[id]) * price;
        } else {
            if (maxQuantity !== null && e.target.value > maxQuantity) {
                return false;
            } else {
                subTotal += (e.target.value - (quantities[id] === undefined ? 0 : quantities[id])) * price;
            }
        }

        handleChange(id, e.target.value, subTotal);
    }

    return (
        <Row>
            <Col md={packagePermitsAddons ? 10 : 9}>
                <div className="c-product-row__product-details">
                    <div className="c-product-row__product-name">
                        <strong>{name}</strong>

                        <p style={{ fontSize: '0.85rem' }} >{description}</p>
                    </div>

                    {packagePermitsAddons &&
                        <div className="c-product-row__price-details">
                            <div className="c-product-row__product-price">
                                <p>(&pound;{price.toLocaleString('en')} each)</p>
                            </div>

                            <div className="c-product-row__product-quantity">
                                <div className="c-quantity-selector">
                                    <button className="c-quantity-selector__button c-quantity-selector__button--subtract" onClick={() => handleQuantityChange(false)}>-</button>
                                    <input className="c-quantity-selector__input form-control" placeholder="0" type="number" min="0" step="1" value={value} onFocus={(e) => e.target.select()} onKeyPress={(e) => handleNumberCheck(e)} onChange={(e) => handleManualChange(e)} />
                                    <button className="c-quantity-selector__button c-quantity-selector__button--add" onClick={() => handleQuantityChange(true)}>+</button>
                                </div>
                            </div>
                        </div>
                    }
                </div>
            </Col>
            <Col md={packagePermitsAddons ? 2 : 3} style={{ fontSize: "1rem" }}>
                {packagePermitsAddons &&
                    <p className="c-product-row__subtotal">
                        <span className="c-product-row__subtotal-label">Subtotal: </span>&pound;{(price * value).toLocaleString('en')}
                    </p>
                }
                {!packagePermitsAddons &&
                    <div>
                        <Translation ID = 'PackageDoesNotSupportAddons' />
                    </div>
                }
            </Col>
        </Row>
    )
}

export default QuantitySelector;
