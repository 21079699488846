import React, { useEffect, useState } from 'react';
import { Col, Modal, ModalBody, ModalFooter, Row, Table } from 'reactstrap';
import { authHeader } from '../../helpers/auth-header';
import SpinnerOverlay from '../layout/SpinnerOverlay';
import intendLogoSvg from '../../images/logos/in-tend-logo-black.svg';
import jsPDF from 'jspdf';
import html2canvas from 'html2canvas';

const Invoice_Modal = (props) => {
    let { id, secureID, setSecureID, isOpen, toggle, isLoading, setIsLoading, supplierID, contactID } = props;
    const [invoice, setInvoice] = useState([]);
    const [intendLogo, setIntendLogo] = useState();

    useEffect(() => {
        if (secureID) {
            fetch(`./Payment/GetInvoiceDetails?secureID=${secureID}&supplierID=${supplierID}&contactID=${contactID}`, {
                method: 'GET',
                headers: authHeader()
            })
                .then(response => response.json())
                .then(data => {
                    setIntendLogo(intendLogoSvg);
                    setInvoice(data);
                    setIsLoading(false);
                })
                .catch(error => {
                    console.log('Error: ', error);
                })
        }
    }, [contactID, secureID, setIsLoading, supplierID]);

    const closeModal = () => {
        toggle();
    }

    const downloadPDF = () => {
        const input = document.getElementById('modalContent');

        html2canvas(input)
            .then((canvas) => {
                const imgData = canvas.toDataURL('image/png');
                const pdf = new jsPDF({
                    orientation: 'portrait'
                });

                const imgProps = pdf.getImageProperties(imgData);
                const pdfWidth = pdf.internal.pageSize.getWidth();
                const pdfHeight = (imgProps.height * pdfWidth) / imgProps.width;
                pdf.addImage(imgData, 'JPEG', 0, 0, pdfWidth, pdfHeight);

                pdf.save(`${invoice.invoiceNo}_invoice.pdf`);
            });
    }

    return (
        <Modal
            id={id}
            isOpen={isOpen}
            toggle={toggle}
            size="xl"
            centered
            unmountOnClose
        >
            <ModalBody>
                {!isLoading &&
                    <div className="modal-content">
                        <div className="modal-header c-invoice-modal-v2__header">
                            <div className="col-md-6">
                                <img src={intendLogo} width="150" alt="in-tend logo" />
                            </div>
                            <div className="col-md-6 text-right">
                                <strong className="u-no-wrap">Procurement Solutions</strong>
                            </div>
                        </div>

                        <div id='modalContent' className="modal-body">
                            <Row>
                                <Col md={12}>
                                    <h3 className="modal-title">
                                        Invoice
                                    </h3>
                                </Col>
                            </Row>
                            <Row>
                                <Col md={8}>
                                    <div className="c-invoice-modal-v2__address">
                                        <p>
                                            <strong>{invoice.customerDetails.customerName}</strong>
                                        </p>
                                        <p>{invoice.customerDetails.addressLine1}</p>
                                        <p>{invoice.customerDetails.addressLine2}</p>
                                        <p>{invoice.customerDetails.addressLine3}</p>
                                        <p>{invoice.customerDetails.addressLine4}</p>
                                        <p>{invoice.customerDetails.postcode}</p>
                                    </div>
                                </Col>
                                <Col md={4}>
                                    <div className="u-component-spacing">
                                        <dl className="c-invoice-modal-v2__definition-list">
                                            <dt>Date:</dt>
                                            <dd style={{ textAlign: 'right' }} >{invoice.invoiceDate}</dd>
                                            <dt>Invoice Number:</dt>
                                            <dd style={{ textAlign: 'right' }}>{invoice.invoiceNo}</dd>
                                        </dl>
                                    </div>
                                </Col>
                            </Row>

                            <Row>
                                <Col md={6}>
                                    <div>
                                        <dl className="c-invoice-modal-v2__definition-list">
                                            <dt>Contact:</dt>
                                            <dd>{invoice.purchasedBy}</dd>
                                            <dt>Telephone:</dt>
                                            <dd>{invoice.customerDetails.contactNumber}</dd>
                                            <dt>Email:</dt>
                                            <dd>{invoice.customerDetails.companyEmail}</dd>
                                        </dl>
                                    </div>
                                </Col>
                            </Row>

                            <hr />

                            <div id={`${id}Table`} className="c-responsive-table">
                                <div className="c-responsive-table__inner">
                                    <div className="c-responsive-table__header">
                                        <div id="th1" className="c-responsive-table__cell">
                                            <span className="c-responsive-table__th">Description</span>
                                        </div>
                                        <div id="th2" className="c-responsive-table__cell">
                                            <span className="c-responsive-table__th">Quantity</span>
                                        </div>
                                        <div id="th3" className="c-responsive-table__cell">
                                            <span className="c-responsive-table__th">Unit Price</span>
                                        </div>
                                        <div id="th4" className="c-responsive-table__cell">
                                            <span className="c-responsive-table__th">Value</span>
                                        </div>
                                        <div id="th5" className="c-responsive-table__cell">
                                            <span className="c-responsive-table__th">VAT</span>
                                        </div>
                                    </div>

                                    {invoice.invoiceItems.map((invoiceItem, i) => {
                                        return (
                                            <div key={i} className="c-responsive-table__row" style={{color: invoiceItem.isDiscount ? "red" : ""}}>
                                                <div className="c-responsive-table__cell" aria-describedby="th1" data-title="Description">
                                                    <span className="c-responsive-table__td">
                                                        <span dangerouslySetInnerHTML={{ __html: invoiceItem.packageService }} />{invoiceItem.allowanceUsed === true && <span style={{ color: "red" }}> *</span>}
                                                    </span>
                                                </div>
                                                <div className="c-responsive-table__cell" aria-describedby="th2" data-title="Quantity">
                                                    {invoiceItem.isDiscount === false && <span className="c-responsive-table__td">{invoiceItem.quantity}</span>}
                                                </div>
                                                <div className="c-responsive-table__cell" aria-describedby="th3" data-title="Unit Price">
                                                    {invoiceItem.isDiscount === false && <span className="c-responsive-table__td">&pound;{invoiceItem.pricePerPackageService.toLocaleString('en', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</span>}
                                                </div>
                                                <div className="c-responsive-table__cell" aria-describedby="th4" data-title="Value">
                                                    <span className="c-responsive-table__td">&pound;{invoiceItem.value.toLocaleString('en', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</span>
                                                </div>
                                                <div className="c-responsive-table__cell" aria-describedby="th5" data-title="VAT">
                                                    {invoiceItem.isDiscount === false && <span className="c-responsive-table__td">&pound;{invoiceItem.vatTotal.toLocaleString('en', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</span>}
                                                </div>
                                            </div>
                                        )
                                    })}
                                </div>
                                <div className="u-component-spacing"><small style={{ lineHeight: '1.4' }} ><span style={{ color: "red" }}>*</span> Package Allowance Used</small></div>
                            </div>

                            <hr className="u-component-spacing--3x" />

                            {invoice.packageExpiry &&
                                <p>Your purchased package will expire on {invoice.packageExpiry}</p>
                            }

                            <Row className="u-component-spacing--3x" style={{ borderTop: '2px solid #34cb00' }} />


                            <Row className="u-component-spacing">
                                <Col lg={9} md={12}>
                                    <p>
                                        <strong>BACS payments to:</strong>
                                    </p>
                                    <div>
                                        <dl className="c-invoice-modal-v2__definition-list u-component-spacing">
                                            <dt>Bank:</dt>
                                            <dd>Lloyds Bank</dd>
                                            <dt>Sort Code:</dt>
                                            <dd>30-97-51</dd>
                                            <dt style={{ whiteSpace: 'nowrap' }} >Account Number:</dt>
                                            <dd>00132203</dd>
                                            <dt>IBAN:</dt>
                                            <dd>GB20 LOYD 30975100132203</dd>
                                            <dt>BIC:</dt>
                                            <dd>LOYDGB21040</dd>
                                        </dl>
                                    </div>
                                </Col>
                                <Col lg={3} md={12}>
                                    <div className="u-component-spacing--3x">
                                        <dl className="c-invoice-modal-v2__definition-list">
                                            {invoice.invoiceItems.findIndex(x => x.isDiscount) >= 0 &&
                                                <>
                                                    <dt style={{ paddingLeft: '1rem', paddingBottom: '1rem', color:'#F00' }}>
                                                        <strong>Discount</strong>
                                                    </dt>
                                                <dd style={{ paddingLeft: '1rem', paddingBottom: '1rem', color: '#F00' }}>
                                                        -&pound;{invoice.discountTotal.toLocaleString('en', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}
                                                    </dd>
                                                </>
                                            }
                                            <dt style={{ paddingLeft: '1rem' }}>
                                                <strong>Net</strong>
                                            </dt>
                                            <dd style={{ paddingLeft: '1rem' }}>
                                                &pound;{invoice.subTotal.toLocaleString('en', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}
                                            </dd>
                                            <dt style={{ paddingLeft: '1rem', paddingTop: '1rem' }}>
                                                <strong>VAT</strong>
                                            </dt>
                                            <dd style={{ paddingLeft: '1rem', paddingTop: '1rem' }}>
                                                &pound;{invoice.totalTax.toLocaleString('en', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}
                                            </dd>
                                            <dt style={{ paddingLeft: '1rem', paddingTop: '1rem' }}>
                                                <strong>Gross</strong>
                                            </dt>
                                            <dd style={{ paddingLeft: '1rem', paddingTop: '1rem' }}>
                                                &pound;{invoice.priceCharged.toLocaleString('en', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}
                                            </dd>
                                        </dl>
                                    </div>
                                </Col>
                            </Row>
                            <Row>
                                <Col md={12}>
                                    <div className="u-component-spacing"><small style={{ lineHeight: '1.4' }} >In-tend Ltd Terms &amp; Conditions apply, which can be found at <a className="u-no-wrap" href="https://www.in-tend.co.uk/legal">www.in-tend.co.uk/legal</a></small></div>
                                </Col>
                            </Row>

                            <Row className="u-component-spacing--3x" style={{ borderTop: '2px solid #34cb00' }} />

                            <Row>
                                <Col md={12}>
                                    <div className="c-invoice-modal-v2__company-details">
                                        <p>In-tend Ltd, In-tend House, 74 High Street, Maltby, Rotherham, South Yorkshire, S66 7BN</p>
                                        <p>Tel: 0114 407 0056 &#9679; Email: <a href="mailto:accounts@in-tend.co.uk">accounts@in-tend.co.uk</a></p>
                                        <p className="u-component-spacing">In-tend Limited is a company registered in England and Wales.</p>
                                        <p>Company Registration Number: 5845701 &#9679; VAT Registration Number: GB 886 4167 79</p>
                                    </div>
                                </Col>
                            </Row>
                        </div>
                    </div>
                }
                {isLoading &&
                    <SpinnerOverlay />
                }
            </ModalBody>
            <ModalFooter>
                <Row style={{width: '100%'}}>
                    <Col md={6}>
                        <button type="button" id="download" className="btn btn-success btn-sm" onClick={() => downloadPDF()}>Download</button>
                    </Col>
                    <Col md={6} className='p-2 text-right'>
                        <button type="button" id="close" className="btn btn-danger btn-sm" onClick={() => closeModal()}>Close</button>
                    </Col>
                </Row>
            </ModalFooter>
        </Modal >
    )
}

export default Invoice_Modal;